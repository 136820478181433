
import AmericaStories1 from "../../../assets/images/ameios/europe_stories.webp";
import AmericaStories2 from "../../../assets/images/ameios/europe_stories_1.webp";
import AmericaStories3 from "../../../assets/images/ameios/europe_stories_2.webp";
import AmericaStories4 from "../../../assets/images/ameios/europe_stories_3.webp";
import AmericaStories5 from "../../../assets/images/ameios/europe_stories_4.webp";


export const EUROPE_STORIES = [{
    id: 1,
    src: AmericaStories1,
    title: "page.europe.stories.first",
    href: "https://europe.nissanstories.com/en/releases/nissan-skills-foundation-turning-automotive-engineering-into-childs-play"
}, {
    id: 2,
    src: AmericaStories2,
    title: "page.europe.stories.second",
    href: "https://europe.nissanstories.com/en/releases/nissan-employees-save-a-life-with-cpr"
}, {
    id: 3,
    src: AmericaStories3,
    title: "page.europe.stories.third",
    href: "https://europe.nissanstories.com/en/releases/from-brazil-to-paris-via-toulouse-ana-flavias-adventure"
}, {
    id: 4,
    src: AmericaStories4,
    title: "page.europe.stories.fourth",
    href: "https://www.nissan-global.com/EN/STORIES/RELEASES/freedom-to-move/"
}, {
    id: 5,
    src: AmericaStories5,
    title: "page.europe.stories.fifth",
    href: "https://europe.nissanstories.com/en/releases/plan-a-sustainable-central-european-adventure-with-nissans-electric-travel-guide"
}]