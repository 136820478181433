import { FC, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import Heading from "../../Common/Heading";


import { isMobile, isTablet } from "react-device-detect";
import EmployeeLovesDesktop from "./_desktop";
import EmployeeLovesMobile from "./_mobile";


import { AMERCIAS_KEY, EUROPE_KEY, ASEAN_KEY, NML_KEY, MIDDLE_EAST_KEY, AUSTRALIA_NEWZEALAND_KEY } from "../../../../utils/_active_regions";
import { EmployeeLoveItem, EmployeeLoveMainProps, EmployeeLoveMobileItem } from "./_interface";
import { ASEAN_EMPLOYEE_LOVES_STATIC, ASEAN_EMPLOYEE_LOVES_STATIC_MOBILE } from "../../../../Config/Static/EmployeesLove/asean-employees-love";
import { EUROPE_EMPLOYEE_LOVES_STATIC, EUROPE_EMPLOYEE_LOVES_STATIC_MOBILE } from "../../../../Config/Static/EmployeesLove/europe-employees-love";
import { EMPLOYEE_LOVES_STATIC, EMPLOYEE_LOVES_STATIC_MOBILE } from "../../../../Config/Static/EmployeesLove/americas-employees-love";
import { NML_EMPLOYEE_LOVES_STATIC, NML_EMPLOYEE_LOVES_STATIC_MOBILE } from "../../../../Config/Static/EmployeesLove/nml-employees-love";
import { ME_EMPLOYEE_LOVES_STATIC, ME_EMPLOYEE_LOVES_STATIC_MOBILE } from "../../../../Config/Static/EmployeesLove/middle-east-employees-love";
import { AUSTRALIA_NEWZEALAND_EMPLOYEE_LOVES_STATIC, AUSTRALIA_NEWZEALAND_EMPLOYEE_LOVES_STATIC_MOBILE } from "../../../../Config/Static/EmployeesLove/oceania-employees-love";

const ITEMS_BY_REGION = [{
    id: 1,
    region: AMERCIAS_KEY,
    items: EMPLOYEE_LOVES_STATIC,
    items_mob: EMPLOYEE_LOVES_STATIC_MOBILE
}, {
    id: 2,
    region: EUROPE_KEY,
    items: EUROPE_EMPLOYEE_LOVES_STATIC,
    items_mob: EUROPE_EMPLOYEE_LOVES_STATIC_MOBILE
}, {
    id: 3,
    region: ASEAN_KEY,
    items: ASEAN_EMPLOYEE_LOVES_STATIC,
    items_mob: ASEAN_EMPLOYEE_LOVES_STATIC_MOBILE
}, {
    id: 4,
    region: NML_KEY,
    items: NML_EMPLOYEE_LOVES_STATIC,
    items_mob: NML_EMPLOYEE_LOVES_STATIC_MOBILE
}, {
    id: 5,
    region: MIDDLE_EAST_KEY,
    items: ME_EMPLOYEE_LOVES_STATIC,
    items_mob: ME_EMPLOYEE_LOVES_STATIC_MOBILE
}, {
    id: 6,
    region: AUSTRALIA_NEWZEALAND_KEY,
    items: AUSTRALIA_NEWZEALAND_EMPLOYEE_LOVES_STATIC,
    items_mob: AUSTRALIA_NEWZEALAND_EMPLOYEE_LOVES_STATIC_MOBILE
}]


const EmployeeLoves: FC<EmployeeLoveMainProps> = ({ region }) => {
    const [items, setItems] = useState<EmployeeLoveItem[]>();
    const [mobileItems, setMobileItems] = useState<EmployeeLoveMobileItem[]>();
    useEffect(() => {
        let items = ITEMS_BY_REGION.find(x => x.region === region)?.items
        let items_mob = ITEMS_BY_REGION.find(x => x.region === region)?.items_mob
        setItems(items);
        setMobileItems(items_mob);
    }, [items, region])
    return (
        <section className="employees-love">
            <Row className="align-items-center mb-3">
                <Col lg={4}>
                    <Heading type="section-heading">
                        <FormattedMessage id="page.common.employee_loves" />
                    </Heading>
                </Col>
                <Col lg={8}>
                    <p>
                        <FormattedMessage id={`${region === AUSTRALIA_NEWZEALAND_KEY
                            ? "page.oceania.employee_loves_description"
                            : "page.common.employee_loves_description"
                            }`} />
                    </p>
                </Col>
            </Row>
            {isMobile && !isTablet ? (
                <>
                    {
                        mobileItems && mobileItems.length > 0 && <EmployeeLovesMobile items={mobileItems} />
                    }
                </>
            ) : (
                <>
                    {
                        items && items.length > 0 && <EmployeeLovesDesktop items={items} />
                    }
                </>
            )}
        </section>
    );
};
export default EmployeeLoves;
