import program_manufacturing from "../../../../assets/images/nml/program_manufacturing.webp";
import manufacutring_banner from "../../../../assets/images/nml/nml_manufacturing_banner.webp";
import { NML_PROGRAM } from "./_programs";
import { NML_PAGE, WORKDAY_EN_PATH, WORKDAY_JP_PATH } from "../../../../interfaces/Static/routing_paths";
const NML_ITEMS = NML_PROGRAM.filter(x => ["pm_early_careers", "pm_experienced_hires", "pm_temporary_staffs"].indexOf(x.slug) >= 0);

export const NML_MANUFACTURING_PAGES = {
    id: 'manufacturing',
    card_src: program_manufacturing,
    card_title: "page.discover.manufacturing",
    card_description: "page.nml.manufacturing_description",
    banner_src: manufacutring_banner,
    banner_title: "page.discover.manufacturing_mastery",
    banner_description: "page.nml.craft_assemble",
    slug: 'manufacturing',
    href: `${NML_PAGE}/manufacturing`,
    name: "page.discover.manufacturing",
    description: "page.nml.manufacturing_description",
    nmlItems: NML_ITEMS,
    work_day_link: `${WORKDAY_EN_PATH}locationCountry=8b705da2becf43cfaccc091da0988ab2`,
    work_day_link_jp: `${WORKDAY_JP_PATH}locationCountry=8b705da2becf43cfaccc091da0988ab2`
}