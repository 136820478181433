import { SUB_PAGES } from "../../../../interfaces/RegionalPage";

import { NML_MANUFACTURING_PAGES } from "./_manufacturing";
import { NML_EARLY_HIRES } from "./_early_careers";
import { NML_EXPERIENCED_HIRES } from "./_experienced_hires";
import { NML_DISABILITY } from "./_disability";

interface NML_PAGES extends SUB_PAGES{
    isTrainingDevelopmentRequired?: boolean;
    isCareerRequired?: boolean;
    isRecruitingRequired?: boolean;
    swapPageTitle?: string;
    work_day_link_jp?: string;
}

export const NML_SUB_PAGES: NML_PAGES[] = [
    NML_MANUFACTURING_PAGES,
    NML_EXPERIENCED_HIRES,
    NML_EARLY_HIRES,
    NML_DISABILITY
]