import { FC } from "react";
import { Row, Col } from "react-bootstrap";
import Heading from "../../Common/Heading";

import { isTablet, isMobile } from "react-device-detect";
import DiscoverJourneyMobile from "./_mobile";
import DiscoverJourneyDesktop from "./_desktop";
import { SUB_PAGES } from "../../../../interfaces/RegionalPage";
import { FormattedMessage } from "react-intl";

interface Props {
    heading: string;
    title: string;
    description: string
    sub_description?: string
    items: SUB_PAGES[]
}

const DiscoverJourney: FC<Props> = ({ heading, title, description, sub_description, items }) => {
    return (
        <section className="discover-journey">
            <Row className="justify-content-center align-items-center mb-3">
                <Col lg={5}>
                    <Heading type="detailed-sub-heading">
                        <FormattedMessage id={heading} />
                    </Heading>
                    <Heading type="section-heading">
                        <FormattedMessage id={title} />
                    </Heading>
                </Col>
                <Col lg={7}>
                    <p className="m-0">
                        <FormattedMessage id={description} />
                    </p>
                </Col>
            </Row>
            {
                isMobile && !isTablet ? <DiscoverJourneyMobile items={items} /> : <DiscoverJourneyDesktop items={items} />
            }
            {
                sub_description && (
                    <p className="m-0">
                        <FormattedMessage id={sub_description} />
                    </p>
                )
            }

        </section>
    );
};
export default DiscoverJourney;
