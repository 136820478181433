import { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";

import PeopleoneImage from "../../../assets/images/peopleone.webp";
import PeopletwoImage from "../../../assets/images/peopletwo.webp";
import PeoplethreeImage from "../../../assets/images/peoplethree.webp";
import SimpleImage from "../../Shared/Media/SimpleImage";
import { FormattedMessage } from "react-intl";

const peoples = [
  {
    id: 1,
    name: "page.meet_our_people.testimonial_name_one",
    designation: "page.meet_our_people.testimonial_name_one_designation",
    description: "page.meet_our_people.testimonial_name_one_description",
    src: PeopleoneImage,
    japanese: true,
  },
  {
    id: 2,
    name: "page.meet_our_people.testimonial_name_two",
    designation: "page.meet_our_people.testimonial_name_two_designation",
    description: "page.meet_our_people.testimonial_name_two_description",
    src: PeopletwoImage,
  },
  {
    id: 3,
    name: "page.meet_our_people.testimonial_name_three",
    designation: "page.meet_our_people.testimonial_name_three_designation",
    description: "page.meet_our_people.testimonial_name_three_description",
    src: PeoplethreeImage,
  },
];

const NissanPeoples: FC = () => {
  return (
    <div className="nissan-people-area">
      {peoples.map((key, index) => {
        return (
          <div key={index} className="py-3 people-list">
            <Container>
              <Row>
                <Col
                  md={4}
                  xs={12}
                  className={
                    index % 2 === 0
                      ? "people-img order-md-12 order-xs-1"
                      : "people-img order-md-1 order-xs-12"
                  }
                >
                  <SimpleImage className="py-3" src={key.src} />
                </Col>
                <Col
                  md={8}
                  xs={12}
                  className={
                    index % 2 === 0
                      ? "people-cont order-md-1 order-xs-1"
                      : "people-cont order-md-12 order-xs-12"
                  }
                >
                  <h2>
                    <FormattedMessage id={key.name} />
                  </h2>
                  <span><FormattedMessage id={key.designation} /></span>
                  <p>
                    <FormattedMessage id={key.description} />
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        );
      })}
    </div>
  );
};
export default NissanPeoples;
