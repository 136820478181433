import { FC } from "react";
import ImageTileDesktop from "./_desktop";
import ImageTileMobile from "./_mobile";

import { isMobile, isTablet } from "react-device-detect";
import { FormattedMessage } from "react-intl";
import { Anchor } from "../../../Common/Anchor";
import { ACTIVE_REGIONS } from "../../../../../utils/_active_regions";
import FloatingButton from "../../FloatingButton";
interface Props {
  region: string;
  title?: string;
  description?: string;
  images: string[]
  disableFloat?: boolean
}

const ImageTile: FC<Props> = ({ images, region, description, title, disableFloat }) => {
  const regionalPage = ACTIVE_REGIONS.find(x => x.region === region);
  return (
    <section className="banner americas image-tile-banner">
      {isMobile && !isTablet ? <ImageTileMobile images={images} /> : <ImageTileDesktop images={images} />}
      <div className="container">
        <div className="banner-caption">
          <h1>
            <FormattedMessage id={title || "page.regional.banner.title"} />
          </h1>
          <div className="sub-text">
            <FormattedMessage id={description || "page.regional.banner.description"} />
          </div>
          <Anchor href={`${regionalPage?.pathName}#discover-journey`}>
            <button className="btn btn-primary-outline">
              {regionalPage?.translation ? <FormattedMessage id="page.regional.banner.jp_explore" /> : `Explore ${regionalPage?.name}`}
            </button>
          </Anchor>
        </div>
      </div>
      {!disableFloat && <FloatingButton region={region} />}
    </section>
  );
};

export default ImageTile;
