import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { isMobile, isTablet } from "react-device-detect";
import { Row, Col, Container, Card } from "react-bootstrap";

import Heading from "../../Shared/Common/Heading";
import { Anchor } from "../../Shared/Common/Anchor";
import { CORPORATE_AREA_ITEMS } from "./_items";
import CorporateAreaCard from "./_card";
import CorporateAreaMobile from "./_mobile";
interface Props {
  isDetailPage?: boolean
}
const CardSection: FC<Props> = ({ isDetailPage }) => {
  return (
    <Container>
      <Row>
        <Col lg={12} md={12} className="pb-3">
          <Heading type="section-heading">
            <FormattedMessage id="page.common.nissan_corporate_careers_area" />
          </Heading>
          <p className="heading-det">
            <FormattedMessage id="page.common.nissan_corporate_careers_area.description" />
          </p>
        </Col>
      </Row>
      {isMobile && !isTablet && !isDetailPage ? (
        <CorporateAreaMobile />
      ) : (
        <Row>
          {CORPORATE_AREA_ITEMS.map((item) => {
            return (
              <Col lg={3} md={6} key={item.id}>
                {
                  isDetailPage ? (
                    <CorporateAreaCard {...item} isDetailPage={isDetailPage} />
                  ) : (
                    <Anchor href={item.href} external={true}>
                      <CorporateAreaCard {...item} isDetailPage={isDetailPage} />
                    </Anchor>
                  )
                }
              </Col>
            );
          })}
        </Row>
      )}
    </Container>
  );
};
export default CardSection;
