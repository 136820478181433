import "./Styles/index.scss";
import "react-multi-carousel/lib/styles.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "animate.css/animate.min.css";
import 'react-tabs-scrollable/dist/rts.css'
import Routes from "./Config/Route";

function App() {
  return <Routes />;
}

export default App;
