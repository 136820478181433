
import AfricaStories1 from "../../../assets/images/africa/africa_stories.webp";
import AfricaStories2 from "../../../assets/images/africa/africa_stories_2.webp";
import AfricaStories3 from "../../../assets/images/africa/africa_stories_3.webp";
import AfricaStories4 from "../../../assets/images/africa/africa_stories_4.webp";
import AfricaStories5 from "../../../assets/images/africa/africa_stories_5.webp";


export const AFRICAS_STORIES = [{
    id: 1,
    src: AfricaStories1,
    title: "page.africa.stories_1",
    href: "https://africa.nissanmotornews.com/en-ZA/releases/made-in-africa-for-africa"
}, {
    id: 2,
    src: AfricaStories2,
    title: "page.africa.stories_2",
    href: 'https://www.linkedin.com/feed/update/urn:li:activity:7099847782609346560/?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7099847782609346560%29'
}, {
    id: 3,
    src: AfricaStories3,
    title: "page.africa.stories_3",
    href: "https://africa.nissanmotornews.com/en-ZA/releases/florence-sempebwa-makada-managing-director-of-nissan-in-uganda-is-not-just-a-leader-she-walks-the-talk"
}, {
    id: 4,
    src: AfricaStories4,
    title: "page.africa.stories_4",
    href: "https://africa.nissanmotornews.com/en-ZA/releases/this-womensmonth2023-we-are-celebrating-the-women-who-drive-organisation"
}, {
    id: 5,
    src: AfricaStories5,
    title: "page.africa.stories_5",
    href: "https://www.linkedin.com/feed/update/urn:li:activity:7085195082802323456/?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7085195082802323456%29"
}]