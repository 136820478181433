import program_disability from "../../../../assets/images/nml/program_disability.webp";
import program_disability_banner from "../../../../assets/images/nml/nml_manufacturing_banner.webp";
import { WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";

export const NML_DISABILITY = {
    id: 'disability',
    card_src: program_disability,
    card_title: "page.common.disability",
    card_description: "page.discover.disability_description",
    banner_src: program_disability_banner,
    banner_title: "page.discover.early_careers",
    banner_description: "Embark on a Thrilling Journey",
    slug: 'disability',
    href: `https://www.nissanmotor.jobs/japan/HC/index.html?adobe_mc=MCMID%3D59688133824333524962110407638395434875%7CMCORGID%3D0BCEE1CE543D41F50A4C98A5%2540AdobeOrg%7CTS%3D1709073230`,
    name: "page.discover.early_careers",
    description: "Immerse yourself in the world of mobility. Expand the boundaries of possibility and chart your own path at Nissan through our internships, MBA, and campus connection programs & events.",
    categories: [],
    work_day_link: `${WORKDAY_EN_PATH}locationCountry=8b705da2becf43cfaccc091da0988ab2`,
    external: true
}