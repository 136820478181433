import { FormattedMessage } from "react-intl";
import RecruitmentBanner from "./_banner";
import { Container } from "react-bootstrap";

const RecruitementHiringScam = () => {
  return (
    <>
      <RecruitmentBanner />
      <div className="recuritment-hiring-scam">
        <Container>
          <div>
            <p>
              <FormattedMessage id="page.common.recuritment_description_1" />
            </p>
            <p>
              <FormattedMessage id="page.common.recuritment_description_2" />
            </p>
            <ol>
              <li>
                <FormattedMessage id="page.common.recuritment_list_1" values={{
                  website_link: (
                    <a href="https://www.nissanmotor.jobs/">
                      https://www.nissanmotor.jobs/
                    </a>
                  ),
                  b: (chunks) => <strong>{chunks}</strong>

                }} />
              </li>
              <li>
                <FormattedMessage id="page.common.recuritment_list_2" values={{
                  b: (chunks) => <strong>{chunks}</strong>
                }} />
              </li>
              <li>
                <FormattedMessage id="page.common.recuritment_list_3" values={{
                  b: (chunks) => <strong>{chunks}</strong>
                }} />
              </li>
            </ol>
            <p>
              <FormattedMessage id="page.common.recuritment_description_3" />
            </p>
          </div>
        </Container>
      </div>
    </>
  );
};
export default RecruitementHiringScam;
