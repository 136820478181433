import { FC } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Anchor } from "../Shared/Common/Anchor";
import Heading from "../Shared/Common/Heading";
import { SiteMapLinks } from "../../interfaces/Static";

const MenuItems: FC = () => {
  return (
    <>
      <div className="sitemap-area">
        <Container>
          <Row>
            {SiteMapLinks.map((item, index) => {
              return (
                <Col xs={12} sm={6} md={6} lg={4} key={index}>
                  <Heading type="detailed-sub-heading">
                    <FormattedMessage id={item.title} />
                  </Heading>
                  <ul>
                    {item.list.map((v, index) => {
                      return (
                        <li key={index}>
                          <Anchor
                            href={v.href}
                            href_jp={v.href_jp}
                            external={true}
                          >
                            <FormattedMessage id={v.title} />
                          </Anchor>
                        </li>
                      );
                    })}
                  </ul>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </>
  );
};
export default MenuItems;
