import pm_temporary_staffs from '../../../../assets/images/nml/pm_temporary_staffs.webp';
import pm_early_careers from '../../../../assets/images/nml/pm_early_careers.webp';
import pm_experienced_hires from '../../../../assets/images/nml/pm_experienced_hires.webp';


import pm_global_design_center from '../../../../assets/images/nml/pm_global_design_center.webp';
import pm_headquarter from '../../../../assets/images/nml/pm_headquarter.webp';
import pm_iwaki_plant from '../../../../assets/images/nml/pm_iwaki_plant.webp';
import pm_nissan_advanced_technology_center from '../../../../assets/images/nml/pm_nissan_advanced_technology_center.webp';
import pm_nissan_technical_center from '../../../../assets/images/nml/pm_nissan_technical_center.webp';
import pm_tochigi_plant from "../../../../assets/images/nml/pm_tochigi_plant.webp"
import pm_oppama_plant from '../../../../assets/images/nml/pm_oppama_plant.webp';
import pm_oppama_research from '../../../../assets/images/nml/pm_oppama_research.webp';
import pm_yokohama_plant from '../../../../assets/images/nml/pm_yokohama_plant.webp';
import pm_zama_operation_center from '../../../../assets/images/nml/pm_zama_operation_center.webp';
import pm_sagamihara_parts_center from '../../../../assets/images/nml/pm_sagamihara_parts_center.webp';
import pm_nissan_global_information_system_center from "../../../../assets/images/nml/pm_nissan_global_information_system_center.webp"
import { WORKDAY_EN_PATH, WORKDAY_JP_PATH } from '../../../../interfaces/Static/routing_paths';

export const NML_PROGRAM = [{
    id: 1,
    name: 'page.discover.early_careers',
    slug: 'pm_early_careers',
    description: 'page.nml.early_careers_description',
    translation: true,
    src: pm_early_careers,
    href: 'https://www.nissanmotor.jobs/japan/SNE/?adobe_mc=MCMID%3D59688133824333524962110407638395434875%7CMCORGID%3D0BCEE1CE543D41F50A4C98A5%2540AdobeOrg%7CTS%3D1709073230'
}, {
    id: 2,
    name: 'page.discover.experienced_hires',
    slug: 'pm_experienced_hires',
    description: 'page.nml.experienced_hires_description',
    translation: true,
    src: pm_experienced_hires,
    href: `${WORKDAY_EN_PATH}q=%E7%9B%B4%E6%8E%A5%E3%83%BB%E6%BA%96%E7%9B%B4%E5%93%A1%E6%8E%A1%E7%94%A8&locationCountry=8b705da2becf43cfaccc091da0988ab2`,
    href_jp: `${WORKDAY_JP_PATH}q=%E7%9B%B4%E6%8E%A5%E3%83%BB%E6%BA%96%E7%9B%B4%E5%93%A1%E6%8E%A1%E7%94%A8&locationCountry=8b705da2becf43cfaccc091da0988ab2`
}, {
    id: 3,
    name: 'page.discover.temporary_staffs',
    slug: 'pm_temporary_staffs',
    description: 'page.nml.temporary_staffs_description',
    translation: true,
    src: pm_temporary_staffs,
    href: 'https://www.nissanmotor.jobs/japan/FS/?adobe_mc=MCMID%3D59688133824333524962110407638395434875%7CMCORGID%3D0BCEE1CE543D41F50A4C98A5%2540AdobeOrg%7CTS%3D1709073230'
}, {
    id: 4,
    name: 'page.nml.location.headquarter',
    slug: 'pm_headquarter',
    description: 'page.nml.location.headquarter_description',
    translation: true,
    src: pm_headquarter,
    href: ''
}, {
    id: 5,
    name: 'page.nml.location.tsurumi_office',
    slug: 'pm_tsurumi_office',
    description: 'page.nml.location.tsurumi_office_description',
    translation: true,
    src: pm_headquarter,
    href: ''
}, {
    id: 6,
    name: 'page.nml.location.yokohama_plant',
    slug: 'pm_yokohama_plant',
    description: 'page.nml.location.yokohama_plant_description',
    translation: true,
    src: pm_yokohama_plant,
    href: ''
}, {
    id: 7,
    name: 'page.nml.location.nissan_advanced_technology_center',
    slug: 'pm_nissan_advanced_technology_center',
    description: 'page.nml.location.nissan_advanced_technology_center_description',
    translation: true,
    src: pm_nissan_advanced_technology_center,
    href: ''
}, {
    id: 8,
    name: 'page.nml.location.nissan_technical_center',
    slug: 'pm_nissan_technical_center',
    description: 'page.nml.location.nissan_technical_center_description',
    translation: true,
    src: pm_nissan_technical_center,
    href: ''
}, {
    id: 9,
    name: 'page.nml.location.global_design_center',
    slug: 'pm_global_design_center',
    description: 'page.nml.location.global_design_center_description',
    translation: true,
    src: pm_global_design_center,
    href: ''
}, {
    id: 10,
    name: 'page.nml.location.nissan_global_information_system_center',
    slug: 'pm_nissan_global_information_system_center',
    description: 'page.nml.location.nissan_global_information_system_center_description',
    translation: true,
    src: pm_nissan_global_information_system_center,
    href: ''
}, {
    id: 11,
    name: 'page.nml.location.tochigi_plant',
    slug: 'pm_tochigi_plant',
    description: 'page.nml.location.tochigi_plant_description',
    translation: true,
    src: pm_tochigi_plant,
    href: ''
}, {
    id: 12,
    name: 'page.nml.location.research_center_oppama',
    slug: 'pm_research_center_oppama',
    description: 'page.nml.location.research_center_oppama_description',
    translation: true,
    src: pm_oppama_research,
    href: ''
}, {
    id: 13,
    name: 'page.nml.location.oppama_plant',
    slug: 'pm_oppama_plant',
    description: 'page.nml.location.oppama_plant_description',
    translation: true,
    src: pm_oppama_plant,
    href: ''
}, {
    id: 14,
    name: 'page.nml.location.iwaki_plant',
    slug: 'pm_iwaki_plant',
    description: 'page.nml.location.iwaki_plant_description',
    translation: true,
    src: pm_iwaki_plant,
    href: ''
}, {
    id: 15,
    name: 'page.nml.location.zama_operation_center',
    slug: 'pm_zama_operation_center',
    description: 'page.nml.location.zama_operation_center_description',
    translation: true,
    src: pm_zama_operation_center,
    href: ''
}, {
    id: 16,
    name: 'page.nml.location.sagamihara_parts_center',
    slug: 'pm_sagamihara_parts_center',
    description: 'page.nml.location.sagamihara_parts_center_description',
    translation: true,
    src: pm_sagamihara_parts_center,
    href: ''
}]