import { FC } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { JS_PAGE_ITEM } from "../../../Config/Static/NmlJobSearch/_interface"
import { FormattedMessage } from "react-intl";
import { Anchor } from "../../Shared/Common/Anchor";

interface Props {
    item: JS_PAGE_ITEM;
}

const NmlLinkJobSearch: FC<Props> = ({ item }) => {
    const { name, links } = item;
    return (
        <>
            <Row className="job-search-card">
                <Col md={2}>
                    <h3><FormattedMessage id={name} /></h3>
                </Col>
                <Col md={10}>
                    {
                        links.map(link => {
                            return (
                                <Anchor external={true} href={link.href} href_jp={link.href_jp}>
                                    <FormattedMessage id={link.name} />
                                </Anchor>
                            )
                        })
                    }
                    {/* {
                        links && links.length > 2 && (
                            <p className="view-all">
                                <Anchor external={true} href="/">
                                    <FormattedMessage id="page.common.view_more" />
                                </Anchor>
                            </p>
                        )
                    } */}
                </Col>
            </Row>
        </>
    );
};
export default NmlLinkJobSearch;
