import { SUB_PAGES } from "../../../../interfaces/RegionalPage";
import { EUROPE_EARLY_HIRES } from "./_early_hires";
import { EUROPE_ENGINEERING_RESEARCH } from "./_engineering_research";
import { EUROPE_EXPERIENCED_HIRES } from "./_experienced_hires";
import { EUROPE_MANUFACTURING_PAGES } from "./_manufacturing";


export const EUROPE_SUB_PAGES: SUB_PAGES[] = [
    EUROPE_MANUFACTURING_PAGES,
    EUROPE_EXPERIENCED_HIRES,
    EUROPE_EARLY_HIRES,
    EUROPE_ENGINEERING_RESEARCH
]