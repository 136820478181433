
import ME_Stories1 from "../../../assets/images/middle_east/stories_1.webp";
import ME_Stories2 from "../../../assets/images/middle_east/stories_2.webp";
import ME_Stories3 from "../../../assets/images/middle_east/stories_3.webp";
import ME_Stories4 from "../../../assets/images/middle_east/stories_4.webp";
import ME_Stories5 from "../../../assets/images/middle_east/stories_5.webp";


export const MIDDLEEAST_STORIES = [{
    id: 1,
    src: ME_Stories1,
    title: "page.middle_east.stories.first",
    href: "https://me.nissanmotornews.com/en/releases/nissans-middle-east-headquarters-recognized-as-a-great-place-to-work"
}, {
    id: 2,
    src: ME_Stories2,
    title: "page.middle_east.stories.second",
    href: "https://me.nissanmotornews.com/en/releases/nissan-middle-east-sets-new-guinness-world-records-title-in-celebration-of-52nd-uae-national-day"
}, {
    id: 3,
    src: ME_Stories3,
    title: "page.middle_east.stories.fourth",
    href: "https://www.linkedin.com/posts/thierry-sabbagh-789a061b_nissansaudiarabia-activity-7146718626509746176-NA1t/?utm_source=share&utm_medium=member_desktop"
}, {
    id: 4,
    src: ME_Stories4,
    title: "page.middle_east.stories.third",
    href: "https://www.linkedin.com/posts/adib-takieddine-532a8112_nissansaudiarabia-lab26-activity-7134901264785522688-D16I/?utm_source=share&utm_medium=member_ios"
}, {
    id: 5,
    src: ME_Stories5,
    title: "page.middle_east.stories.fifth",
    href: "https://me.nissanmotornews.com/en/releases/he-sheikha-lubna-al-qasimi-delivers-inspirational-talk-to-nissan-employees-on-international-womens-day"
}]