import { FC } from "react";
import { Container } from "react-bootstrap";

import { EUROPE_SUB_PAGES } from "../../Config/Static/ProgramDetail/Europe";
import DiscoverJourney from "../Shared/RegionalPages/DiscoverJourney";
import DrivingTogether from "../Shared/RegionalPages/DrivingTogether";
import EmployeeLoves from "../Shared/RegionalPages/EmployeeLoves";
import Testimonial from "../Shared/RegionalPages/Testimonial";
import Stories from "../Shared/RegionalPages/Stories";
import { EUROPE_STORIES } from "../../Config/Static/Stories/europe-stories";

import { EUROPE_DRIVING_TOGETHER, EUROPE_TESTIMONIALS } from "./_static";
import { EUROPE_KEY } from "../../utils/_active_regions";

import banner_1 from "../../assets/images/ameios/banner_1.webp";
import banner_2 from "../../assets/images/ameios/banner_2.webp";
import banner_3 from "../../assets/images/ameios/banner_3.webp";
import banner_4 from "../../assets/images/ameios/banner_4.webp";
import banner_5 from "../../assets/images/ameios/banner_5.webp";
import banner_6 from "../../assets/images/ameios/banner_6.webp";
import ImageTile from "../Shared/Banner/RegionalBanner/ImageTile";
const images = [
    banner_1,
    banner_2,
    banner_3,
    banner_4,
    banner_5,
    banner_6
];

const Europe: FC = () => {
    return (
        <>
            <ImageTile images={images} region={EUROPE_KEY} />
            <Container>
                <div id="discover-journey">
                    <DiscoverJourney
                        heading="page.discover.your_journey"
                        title="page.discover.your_journey.title"
                        description="page.discover.your_journey.description"
                        items={EUROPE_SUB_PAGES} />
                </div>
                <DrivingTogether {...EUROPE_DRIVING_TOGETHER} />
                <EmployeeLoves region={EUROPE_KEY} />
                <Stories
                    title={"Europe's Stories"}
                    items={EUROPE_STORIES}
                    href="https://europe.nissanstories.com/en/"
                    href_jp="https://europe.nissanstories.com/en/"
                />
                <Testimonial items={EUROPE_TESTIMONIALS} />
            </Container>
        </>
    );
};
export default Europe;
