import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { Anchor } from "../../Shared/Common/Anchor";
import { CAREER_DETAILS_PAGE } from "../../../interfaces/Static/routing_paths";
import CardSection from "./_card_sections";

const CorporateArea: FC = () => {
  return (
    <div className="corporate-carrear-area" id="corporate-carrer-area">
      <CardSection />
      <div className="career-explore-area">
        <h2><FormattedMessage id="page.common.learn_more_about_carrer_areas" /></h2>
        <p><FormattedMessage id="page.common.program.are_you_ready" /></p>
        <Anchor href={CAREER_DETAILS_PAGE}>
          <button className="btn btn-primary">
            <FormattedMessage id="page.common.explore" />
          </button>
        </Anchor>
      </div>
    </div>
  );
};
export default CorporateArea;
