import { AUSTRALIA_NEWZEALAND_PAGE, WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";
import { AUSTRALIA_NEWZEALAND_PROGRAM } from "./_programs";
import financial_services from "../../../../assets/images/oceania/financial_services.webp";
import financial_services_banner from "../../../../assets/images/oceania/financial_services_banner.webp";
const ANZ_LOCATIONS = {
    id: 1,
    name: 'Australia & New Zealand',
    slug: 'melbourne-victoria-nma',
    items: AUSTRALIA_NEWZEALAND_PROGRAM.filter(x => ["melbourne-victoria"].indexOf(x.slug) >= 0)
}
export const FINANCIAL_SERVICES = {
    id: 'financial-services',
    card_src: financial_services,
    card_title: "page.oceania.financial_services",
    card_description: "page.oceania.financial_services_description",
    banner_src: financial_services_banner,
    banner_title: "page.oceania.financial_services",
    banner_description: "Your Finance Career Awaits",
    slug: 'financial-services',
    href: `${AUSTRALIA_NEWZEALAND_PAGE}/financial-services`,
    name: "page.oceania.financial_services",
    description: "Nissan Financial Services provides comprehensive financial solutions for both customers and dealerships, offering diverse career paths from customer service and compliance to risk management and sales. Join us and accelerate your growth in the dynamic automotive industry. Nissan Financial Services provides a competitive edge and a strong partnership with the Motor business. As both businesses grow, your opportunities for professional development and advancement grow too.",
    categories: [{
        id: 1,
        name: 'Who we are',
        slug: 'who_we_are',
        description: "At Nissan Financial Services, we are more than just a financial services company; we are a team dedicated to crafting personalised financial solutions for our customers and dealer partners in Australia and New Zealand. <br /> <br />When you join us, you will discover a world of possibilities within our dynamic organisation of over 200 employees. From engaging with customers directly in Customer Service to assessing credit risks in Retail and Commercial Credit, ensuring regulatory compliance, streamlining processes in Operations, or driving sales, your career path can lead you in exciting directions.<br /> <br /> We are a vital force behind Nissan's success, providing a competitive edge and a strong partnership with the Motor business. As both businesses grow, your opportunities for professional development and advancement grow too.",
        locations: [ANZ_LOCATIONS]
    }, {
        id: 2,
        name: 'Where you can start',
        slug: 'where_you_can',
        description: "Whether you thrive on connecting with customers or prefer working behind the scenes to keep things running smoothly, we have the perfect starting point for your career at Nissan Financial Services.<br /> <br /> If you're passionate about providing exceptional service and building relationships, our customer-facing roles offer the chance to make a real difference in people's lives.<br /> <br /> If you enjoy analysing data, problem-solving, and supporting critical operations, our operational and analytical roles are a great way to launch your career and gain valuable experience.",
        locations: [ANZ_LOCATIONS]
    }, {
        id: 3,
        name: 'Specialist Areas',
        slug: 'specialist_areas',
        description: "Being an integrated automotive financier means, we offer diverse roles that demand specialised expertise.<br /> <br /> If you're a relationship-builder with a passion for fleet sales, you could join our Fleet Sales Team, where you'll play a vital role in fostering strong connections between fleet clients, dealers, and our team.<br /> <br /> Alternatively, if you have a background in finance and a knack for customer service, you could bring your experience to our Sales or Credit Teams.",
        locations: [ANZ_LOCATIONS]
    }, {
        id: 4,
        name: 'Career Pathways',
        slug: 'career_pathways',
        description: "No matter where you begin your journey at Nissan Financial Services in Australia and New Zealand, your role will open doors to exciting pathways across our organisation.<br /> <br /> You could discover opportunities within other areas of Financial Services, move to the Motor business, or even explore possibilities within our Global Financial Services function.",
        locations: [ANZ_LOCATIONS]
    }],
    work_day_link: `https://www.livehire.com/careers/nissan/jobs`
}