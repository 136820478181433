import { Helmet } from "react-helmet-async";
import SiteMap from "../Components/Sitemap";

function SiteMapContainer() {
  return (
    <>
      <Helmet>
        <title>
          NISSAN MOTOR CORPORATION - GLOBAL CAREER WEBSITE - SITEMAP
        </title>
      </Helmet>
      <main className="sitemap-container">
        <SiteMap />
      </main>
    </>
  );
}

export default SiteMapContainer;
