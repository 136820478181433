import { FC } from "react";
import { Container } from "react-bootstrap";
import { useIntl } from "react-intl";

import CardHeader from "../../Shared/Common/Card/CardHeader";

const src = "https://www.youtube.com/embed/BNS6WOMs_5s";
const jp_src = "https://www.youtube.com/embed/DNLAbSx5ZTo";
const Purpose: FC = () => {
  const intl = useIntl();
  const { locale } = intl;

  return (
    <div className="purpose-area">
      <Container>
        <CardHeader
          title={"page.common.purpose_mission_and_dna"}
          href={"https://www.nissan-global.com/EN/COMPANY/VISION/"}
          href_jp={"https://www.nissan-global.com/JP/COMPANY/VISION/"}
          anchorText={"page.common.learn_more"}
          variant={"primary-outline"}
          mobileView={true}
          external={true}
        ></CardHeader>
        <div className="yt-video">
          <iframe
            className="responsive-iframe"
            width={"100%"}
            height={"100%"}
            src={`${locale === 'en' ? src : jp_src}/?rel=0`}
            title="Nissan’s commitment to enriching people’s lives"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Container>
    </div>
  );
};
export default Purpose;
