import { FC } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { LocationItemProps } from "../../../../../interfaces/RegionalPage";
import SimpleImage from "../../../Media/SimpleImage";
import parse from 'html-react-parser';
import { FormattedMessage } from "react-intl";
import { Anchor } from "../../Anchor";

interface Props {
    items: LocationItemProps[]
}

const NmlCard: FC<Props> = ({ items }) => {
    return (
        <Row>
            {
                items && items.length > 0 && items.map(item => {
                    return (
                        <Col lg={4} md={6} sm={12} key={item.id}>
                            <Anchor href={item.href} href_jp={
                                item.href_jp || item.href
                            } external={true}>
                                <Card>
                                    <SimpleImage className="card-img-top" src={item.src} />
                                    <div className="card-body">
                                        <div className="cont-test">
                                            <h5 className="card-title">
                                                <FormattedMessage id={item.name} />
                                            </h5>
                                            <p className="card-text"><FormattedMessage id={item.description} /></p>
                                        </div>
                                    </div>
                                </Card>
                            </Anchor>
                        </Col>
                    )
                })
            }
        </Row>

    );
};
export default NmlCard;