import { FC } from "react";
import { Row, Col } from "react-bootstrap";
import JourneyCard from "../../Common/Card/Journey";
import Heading from "../../Common/Heading";
import { EUROPE_SUB_PAGES } from "../../../../Config/Static/ProgramDetail/Europe";
import { AMERICAS_SUB_PAGES } from "../../../../Config/Static/ProgramDetail/Americas";
import { ASEAN_SUB_PAGES } from "../../../../Config/Static/ProgramDetail/Asean";
import { AMERCIAS_KEY, EUROPE_KEY, ASEAN_KEY, AFRICA_KEY, NML_KEY, MIDDLE_EAST_KEY, AUSTRALIA_NEWZEALAND_KEY } from "../../../../utils/_active_regions";
import { AFRICA_SUB_PAGE } from "../../../../Config/Static/ProgramDetail/Africa";
import { NML_SUB_PAGES } from "../../../../Config/Static/ProgramDetail/Nml";
import { MIDDLE_EAST_SUB_PAGES } from "../../../../Config/Static/ProgramDetail/MiddleEast";
import { FormattedMessage } from "react-intl";
import { AUSTRALIA_NEWZEALAND_PAGE } from "../../../../interfaces/Static/routing_paths";
import { AUSTRALIA_NEWZEALAND_SUB_PAGES } from "../../../../Config/Static/ProgramDetail/Oceania";
interface Props {
    pageId: string;
    region: string;
}

const ITEMS_BY_REGION = [{
    id: 1,
    region: AMERCIAS_KEY,
    items: AMERICAS_SUB_PAGES
}, {
    id: 2,
    region: EUROPE_KEY,
    items: EUROPE_SUB_PAGES
}, {
    id: 3,
    region: ASEAN_KEY,
    items: ASEAN_SUB_PAGES
}, {
    id: 4,
    region: AFRICA_KEY,
    items: AFRICA_SUB_PAGE
}, {
    id: 5,
    region: NML_KEY,
    items: NML_SUB_PAGES
}, {
    id: 6,
    region: MIDDLE_EAST_KEY,
    items: MIDDLE_EAST_SUB_PAGES
}, {
    id: 7,
    region: AUSTRALIA_NEWZEALAND_KEY,
    items: AUSTRALIA_NEWZEALAND_SUB_PAGES
}];


const ExploreOtherArea: FC<Props> = ({ pageId, region }) => {
    const items = ITEMS_BY_REGION.find(x => x.region === region)?.items;
    return (
        <section className="discover-journey">
            <Row className="justify-content-center align-items-center mb-3">
                <Col lg={12}>
                    <Heading type="section-heading">
                        <FormattedMessage id="page.common.explore_other_careers_area" />
                    </Heading>
                </Col>
            </Row>
            <Row>
                {
                    items && items.length > 0 && items.filter(x => x.slug !== pageId).map((item, index) => {
                        return (
                            <Col key={item.id} lg={3}>
                                <JourneyCard journey={item} />
                            </Col>
                        )
                    })
                }
            </Row>
        </section>
    );
};
export default ExploreOtherArea;
