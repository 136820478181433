import React, { FC } from "react";
// import HomeBanner from "../Shared/Banner/Home";

import BannerVideo from "../../assets/videos/nissan-home.mp4";
import { Diversity } from "./Diversity";
import JobsByRegion from "../Shared/Common/JobsByRegion";
import StoryBoard from "./StoryBoard";
import { NissanWay } from "./NissanWay";
import { Achievement } from "./Achievement";

import HomeBanner from "../Shared/Banner/Home";
import Testimonials from "../Shared/Common/Testimonials";
import CareerArea from "../Shared/Common/CareerArea";
import NissanAtGlance from "../Shared/Common/NissanAtGlance";

import { ABOUT_PAGE, MEET_OUR_PEOPLE_PAGE } from "../../interfaces/Static/routing_paths";
import GreatPlaceToWork from "./GreatPlaceToWork";

const banners = [
  {
    id: 1,
    src: BannerVideo,
  },
];

const headerItems = {
  title: "page.common.meet_our_people",
  href: MEET_OUR_PEOPLE_PAGE,
  anchorText: "page.common.view_more",
};

const Home: FC = () => {
  return (
    <>
      <HomeBanner banners={banners} />
      <Achievement />
      <GreatPlaceToWork />
      <NissanAtGlance
        external={false}
        name={"page.common.about_us"}
        href={ABOUT_PAGE}
      />
      <NissanWay />
      <CareerArea />
      <StoryBoard />
      <Diversity />
      <Testimonials {...headerItems} />
      <JobsByRegion />
    </>
  );
};
export default Home;
