
import employee_work_life_balance from "../../../assets/images/americas/employee_work_life_balance.webp";
import employee_multinational from "../../../assets/images/americas/employee_multinational.webp";
import employee_comprehensive from "../../../assets/images/americas/employee_comprehensive.webp";
import employee_extensive from "../../../assets/images/americas/employee_extensive.webp";
import employee_career_opportunities from "../../../assets/images/americas/employee_career_opportunities.webp";
import employee_thrilling from "../../../assets/images/americas/employee_thrilling.webp";


export const EUROPE_EMPLOYEE_LOVES_STATIC = [{
    id: 1,
    src: employee_work_life_balance,
    title: "page.europe_employees_love.work_life_balance",
    description: "page.europe_employees_love.work_life_balance_description"
}, {
    id: 2,
    src: employee_multinational,
    title: "page.europe_employees_love.multinational_colleagues",
    description: "page.europe_employees_love.multinational_colleagues_description"
}, {
    id: 3,
    src: employee_comprehensive,
    title: "page.europe_employees_love.comprehensive_benefits",
    description: "page.europe_employees_love.comprehensive_benefits_description"
}, {
    id: 4,
    src: employee_extensive,
    title: "page.europe_employees_love.extensive_internal",
    description: "page.europe_employees_love.extensive_internal_description"
}, {
    id: 5,
    src: employee_career_opportunities,
    title: "page.europe_employees_love.career_development",
    description: "page.europe_employees_love.career_development_description"
}, {
    id: 6,
    src: employee_thrilling,
    title: "page.europe_employees_love.thrilling_and_challenging",
    description: "page.europe_employees_love.thrilling_and_challenging_description"
}];

export const EUROPE_EMPLOYEE_LOVES_STATIC_MOBILE = [{
    id: 1,
    mobile_items: [{
        id: 1,
        src: employee_work_life_balance,
        title: "page.europe_employees_love.work_life_balance",
        description: "page.europe_employees_love.work_life_balance_description"
    }, {
        id: 2,
        src: employee_multinational,
        title: "page.europe_employees_love.multinational_colleagues",
        description: "page.europe_employees_love.multinational_colleagues_description"
    }]
},
{
    id: 2,
    mobile_items: [{
        id: 3,
        src: employee_comprehensive,
        title: "page.europe_employees_love.comprehensive_benefits",
        description: "page.europe_employees_love.comprehensive_benefits_description"
    }, {
        id: 4,
        src: employee_extensive,
        title: "page.europe_employees_love.extensive_internal",
        description: "page.europe_employees_love.extensive_internal_description"
    }]
},
{
    id: 3,
    mobile_items: [{
        id: 5,
        src: employee_career_opportunities,
        title: "page.europe_employees_love.career_development",
        description: "page.europe_employees_love.career_development_description"
    }, {
        id: 6,
        src: employee_thrilling,
        title: "page.europe_employees_love.thrilling_and_challenging",
        description: "page.europe_employees_love.thrilling_and_challenging_description"
    }]
}
];