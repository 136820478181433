import manufacturing from "../../../../assets/images/americas/discover_manufacturing.webp";
import Manufacture_banner from "../../../../assets/images/americas/manufacture_banner.webp";
import { WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";

import { AMERICAS_PROGRAMS } from "./_programs";

const northAmericaSlugs = [
    "smyrna-tn",
    "canton-ms",
    "decherd-tn"
];
const mexicoSlugs = [
    "aguascalientes",
    "cuernavaca"
];
const southAmericaSlugs = [
    "resende-rio-de-janeiro-brasil",
    "santa-isabel-cordoba-argentina"
];

const canadaSlugs = [
    "brampton-ontario",
    "kirkland-quebec"
];

const NORTH_AMERICA_PROGRAMS = AMERICAS_PROGRAMS.filter(x => northAmericaSlugs.indexOf(x.slug) >= 0);

const MEXICO_PROGRAMS = AMERICAS_PROGRAMS.filter(x => mexicoSlugs.indexOf(x.slug) >= 0);

const SOUTH_AMERICA_PROGRAMS = AMERICAS_PROGRAMS.filter(x => southAmericaSlugs.indexOf(x.slug) >= 0);

const CANADA_PROGRAMS = AMERICAS_PROGRAMS.filter(x => canadaSlugs.indexOf(x.slug) >= 0);

const NORTH_AMERICA_LOCATIONS = {
    id: 1,
    name: 'North America',
    slug: 'north-america',
    items: NORTH_AMERICA_PROGRAMS
}

const MEXICO_LOCATIONS = {
    id: 2,
    name: 'Mexico',
    slug: 'mexico',
    items: MEXICO_PROGRAMS
}

const SOUTH_AMERICA_LOCATIONS = {
    id: 3,
    name: 'South America',
    slug: 'south-america',
    items: SOUTH_AMERICA_PROGRAMS
}

const CANADA_LOCATIONS = {
    id: 4,
    name: 'Canada',
    slug: 'canada',
    items: CANADA_PROGRAMS
}

export const AMERICAS_MANUFACTURING_PAGES = {
    id: 'manufacturing',
    card_src: manufacturing,
    card_title: "page.discover.manufacturing",
    card_description: "page.discover.manufacturing_description",
    banner_src: Manufacture_banner,
    banner_title: "page.discover.manufacturing",
    banner_description: "Craft & Assemble Vehicles",
    slug: 'manufacturing',
    href: '/americas/manufacturing',
    name: "page.discover.manufacturing",
    description: "Step onto the production floor where teamwork, precision and craftsmanship meet. Contribute to the creation of your favorite vehicles through advanced manufacturing processes, automation, and quality control, ensuring every detail exceeds expectations.",
    locations: [
        NORTH_AMERICA_LOCATIONS,
        MEXICO_LOCATIONS,
        SOUTH_AMERICA_LOCATIONS,
        CANADA_LOCATIONS
    ],
    work_day_link: `${WORKDAY_EN_PATH}locationCountry=a30a87ed25634629aa6c3958aa2b91ea&locationCountry=e2adff9272454660ac4fdb56fc70bb51&locationCountry=bc33aa3152ec42d4995f4791a106ed09&locationCountry=e42ad5eac46d4cc9b367ceaef42577c5&locationCountry=1a29bb1357b240ab99a2fa755cc87c0e&locationCountry=53fe09ef12b9408682a1d2439823f2e0&locationCountry=0eb156ca580c4db786a7894bdaa77450`
}