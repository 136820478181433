import { FC } from "react";

import Innovation1 from "../../../../assets/images/innovation_1.webp";
import Innovation2 from "../../../../assets/images/innovation_2.webp";
import Innovation3 from "../../../../assets/images/innovation_3.webp";
import NewsCard from "../Card/NewsCard";

const news_card_props = {
  headerItems: {
    title: "page.common.innovation",
    href: "https://www.nissan-global.com/EN/INNOVATION/",
    href_jp: "https://www.nissan-global.com/JP/INNOVATION/",
    anchorText: "page.common.see_more",
    variant: "primary-outline",
    external: true,
    mobileView: true,
  },
  items: [
    {
      id: 1,
      name: "page.common.electrification",
      description: "page.innovation.description_1",
      src: Innovation1,
      href: "https://www.nissan-global.com/EN/INNOVATION/TECHNOLOGY/",
      href_jp: "https://www.nissan-global.com/JP/INNOVATION/TECHNOLOGY/"
    },
    {
      id: 2,
      name: "page.common.autonomous_driver_and_assistance_system",
      description: "page.innovation.description_2",
      src: Innovation2,
      href: "https://www.nissan-global.com/EN/INNOVATION/TECHNOLOGY/VEHICLE_INTELLIGENCE/",
      href_jp: "https://www.nissan-global.com/JP/INNOVATION/TECHNOLOGY/VEHICLE_INTELLIGENCE/",
    },
    {
      id: 3,
      name: "page.common.mobility_service_and_connected_technology",
      description: "page.innovation.description_3",
      src: Innovation3,
      href: "https://www.nissan-global.com/EN/INNOVATION/TECHNOLOGY/MOBILITYSERVICE_CONNECTED/",
      href_jp: "https://www.nissan-global.com/JP/INNOVATION/TECHNOLOGY/MOBILITYSERVICE_CONNECTED/",
    },
  ],
};
const Innovation: FC = () => {
  return (
    <div className="innovation-area">
      <NewsCard {...news_card_props} />
    </div>
  );
};
export default Innovation;
