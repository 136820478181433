import { FC, useState, useRef, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { Anchor } from "../../Common/Anchor";
import { ALLIANCE_WORKDAY_PATH } from "../../../../interfaces/Static";
import {  BASENAME_PAGE, NML_PAGE, WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";
import { ACTIVE_REGIONS } from "../../../../utils/_active_regions";

const AMERICA_WORKDAY_PATH = `${WORKDAY_EN_PATH}locationCountry=a30a87ed25634629aa6c3958aa2b91ea&locationCountry=e2adff9272454660ac4fdb56fc70bb51&locationCountry=bc33aa3152ec42d4995f4791a106ed09&locationCountry=e42ad5eac46d4cc9b367ceaef42577c5&locationCountry=1a29bb1357b240ab99a2fa755cc87c0e&locationCountry=53fe09ef12b9408682a1d2439823f2e0&locationCountry=0eb156ca580c4db786a7894bdaa77450`;

const items = [
  {
    id: 1,
    title: "page.common.japan",
    href: BASENAME_PAGE + NML_PAGE,
  },
  {
    id: 2,
    title: "page.common.americas",
    href: AMERICA_WORKDAY_PATH,
  },
  {
    id: 3,
    title: "page.common.oceania",
    href: "https://www.livehire.com/careers/nissan/jobs",
  },
  {
    id: 4,
    title: "page.common.more_regions",
    href: ALLIANCE_WORKDAY_PATH
  },
];
interface Props {
  region?: string;
}
const FloatingButton: FC<Props> = ({ region }) => {
  const [showRegion, setShowRegion] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as HTMLDivElement)) {
      setShowRegion(false);
    }
  };

  const handleScroll = () => {
    setShowRegion(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleApply = () => {
    if (!region) {
      setShowRegion(!showRegion)
    } else {
      const workdayPath = ACTIVE_REGIONS.find(x => x.region === region)?.workdayPath;
      window.open(workdayPath, '_blank')
    }
  }

  return (
    <div className={`float-btn`} ref={ref}>
      <div className={`${!showRegion ? "hide" : ""} float-menu`}>
        <ul>
          {items.map((item, index) => {
            return (
              <li key={index} onClick={() => setShowRegion(false)}>
                <Anchor external={true} href={item.href}>
                  <FormattedMessage id={item.title} />
                </Anchor>
              </li>
            );
          })}
          <li></li>
        </ul>
      </div>
      <button
        className="btn btn-primary"
        onClick={() => handleApply()}
      >
        <FormattedMessage id="page.common.apply" />
      </button>
    </div>
  );
};

export default FloatingButton;
