import { Helmet } from "react-helmet-async";
import MeetOurPeople from "../Components/MeetOurPeople";

function MeetOurPeopleContainer() {
  return (
    <>
      <Helmet>
        <title>
          NISSAN MOTOR CORPORATION - GLOBAL CAREER WEBSITE - MEET OUR PEOPLE
        </title>
      </Helmet>
      <main>
        <MeetOurPeople />
      </main>
    </>
  );
}

export default MeetOurPeopleContainer;
