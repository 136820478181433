import { FC } from "react";
import { Container } from "react-bootstrap";

import { ASEAN_KEY } from "../../utils/_active_regions";
import DrivingTogether from "../Shared/RegionalPages/DrivingTogether";
import DiscoverJourney from "../Shared/RegionalPages/DiscoverJourney";
import ImageTile from "../Shared/Banner/RegionalBanner/ImageTile";
import EmployeeLoves from "../Shared/RegionalPages/EmployeeLoves";
import Stories from "../Shared/RegionalPages/Stories";
import Testimonial from "../Shared/RegionalPages/Testimonial";

import banner_1 from "../../assets/images/asean/banner_1.webp";
import banner_2 from "../../assets/images/asean/banner_2.webp";
import banner_3 from "../../assets/images/asean/banner_3.webp";
import banner_4 from "../../assets/images/asean/banner_4.webp";
import banner_5 from "../../assets/images/asean/banner_5.webp";
import banner_6 from "../../assets/images/asean/banner_6.webp";

import { ASEANS_DRIVING_TOGETHER, ASEAN_JOURNEY, ASEAN_STORY, ASEAN_TESTIMONIALS } from "./_static";

const images = [
    banner_1,
    banner_2,
    banner_3,
    banner_4,
    banner_5,
    banner_6
];
const Asean: FC = () => {
    return (
        <>
            <ImageTile images={images} region={ASEAN_KEY} />
            <Container>
                <div id="discover-journey">
                    <DiscoverJourney  {...ASEAN_JOURNEY} />
                </div>
                <DrivingTogether {...ASEANS_DRIVING_TOGETHER} />
                <EmployeeLoves region={ASEAN_KEY} />
                <Stories {...ASEAN_STORY} />
            </Container>
            <Testimonial items={ASEAN_TESTIMONIALS} />
        </>
    );
};
export default Asean;
