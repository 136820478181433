import { FC } from "react";
import { Container, Row, Col } from "react-bootstrap";
import YoutubeCard from "./_youtubeCard";
import { FormattedMessage } from "react-intl";

const videosrc = "https://www.youtube.com/embed/NwKoroh42rM";

const items = [
  {
    id: 1,
    videosrc: "https://www.youtube.com/embed/X1vfG-NLICk",
    videosrcJp: "https://www.youtube.com/embed/OuxufSIdvdc",
    startFrom: 0
  },
  {
    id: 2,
    videosrc: videosrc,
    videosrcJp: videosrc,
    startFrom: 0
  },
];

const PeopleVideos: FC = () => {
  return (
    <div className="people-videos-area">
      <Container>
        <h2 className="mb-3">
          <FormattedMessage id="page.meetourpeople.peoplevideo.title" />
        </h2>
        <Row>
          <Col lg={12}>
            <YoutubeCard items={items} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PeopleVideos;
