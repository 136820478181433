import CareersArea from "../Components/CareersArea";

import { Helmet } from "react-helmet-async";

function CareersAreaContainer() {
  return (
    <>
      <Helmet>
        <title>
          NISSAN MOTOR CORPORATION - GLOBAL CAREER WEBSITE - CAREER AREAS -
          OVERVIEW
        </title>
      </Helmet>
      <main className="career-area-container">
        <CareersArea />
      </main>
    </>
  );
}

export default CareersAreaContainer;
