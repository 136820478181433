
import NmlStories1 from "../../../assets/images/nml/stories_1.webp";
import NmlStories2 from "../../../assets/images/nml/stories_2.webp";
import NmlStories3 from "../../../assets/images/nml/stories_3.webp";
import NmlStories4 from "../../../assets/images/nml/stories_4.webp";
import NmlStories5 from "../../../assets/images/nml/stories_5.webp";


export const NML_STORIES = [{
    id: 1,
    src: NmlStories1,
    title: "page.nml.stories_1",
    href: "https://www.nissan-global.com/EN/STORIES/RELEASES/the-creative-journey-of-design/",
    href_jp: "https://www.nissan-global.com/JP/STORIES/RELEASES/the-creative-journey-of-design/"
}, {
    id: 2,
    src: NmlStories2,
    title: "page.nml.stories_2",
    href: "https://www.nissan-global.com/EN/STORIES/RELEASES/nissan-e-4orce_perfect-drive-with-your-furry-friends/",
    href_jp: "https://www.nissan-global.com/JP/STORIES/RELEASES/nissan-e-4orce_perfect-drive-with-your-furry-friends/"
}, {
    id: 3,
    src: NmlStories3,
    title: "page.nml.stories_3",
    href: "https://www.nissan-global.com/EN/STORIES/RELEASES/aviators-try-out-nissans-propilot-assist-20/",
    href_jp: "https://www.nissan-global.com/JP/STORIES/RELEASES/aviators-try-out-nissans-propilot-assist-20/"
}, {
    id: 4,
    src: NmlStories4,
    title: "page.nml.stories_4",
    href: "https://www.nissan-global.com/EN/STORIES/RELEASES/nissan-works-to-power-v2x/",
    href_jp: "https://www.nissan-global.com/JP/STORIES/RELEASES/nissan-works-to-power-v2x/"
}, {
    id: 5,
    src: NmlStories5,
    title: "page.nml.stories_5",
    href: "https://www.nissan-global.com/EN/STORIES/RELEASES/start-your-formula-e-journey/",
    href_jp: "https://www.nissan-global.com/JP/STORIES/RELEASES/start-your-formula-e-journey/"
}]