
import AmericaStories1 from "../../../assets/images/americas/americas_stories.webp";
import AmericaStories2 from "../../../assets/images/americas/americas_stories_2.webp";
import AmericaStories3 from "../../../assets/images/americas/americas_stories_3.webp";
import AmericaStories4 from "../../../assets/images/americas/americas_stories_4.webp";
import AmericaStories5 from "../../../assets/images/americas/americas_stories_5.webp";


export const AMERICAS_STORIES = [{
    id: 1,
    src: AmericaStories1,
    title: "page.americas.stories.first",
    href: "https://usa.nissanstories.com/en-US/releases/inspiring-the-designers-of-the-future-today#:~:text=The%20design-challenge%20project%2C%20which,like%20in%20Nissan's%20100th%20year."
}, {
    id: 2,
    src: AmericaStories2,
    title: "page.americas.stories.second",
    href: 'https://usa.nissanstories.com/en-US/releases/technology-1-tension-0'
}, {
    id: 3,
    src: AmericaStories3,
    title: "page.americas.stories.third",
    href: "https://www.nissan-global.com/EN/STORIES/RELEASES/embracing-work-life-balance/"
}, {
    id: 4,
    src: AmericaStories4,
    title: "page.americas.stories.fourth",
    href: "https://usa.nissanstories.com/en-US/releases/nissan-heritage-collection-spotlight-datsun-fairlady-1200-roadster"
}, {
    id: 5,
    src: AmericaStories5,
    title: "page.americas.stories.fifth",
    href: "https://www.nissan-global.com/EN/STORIES/RELEASES/a-picture-is-worth-a-thousand-unforgettable-memories/"
}]