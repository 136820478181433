/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
 import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { ABOUT_PAGE, CAREER_PAGE, MEET_OUR_PEOPLE_PAGE } from "../../../interfaces/Static/routing_paths";
import { FIND_NLM_PAGES } from "../../../utils";
import { Anchor } from "../../Shared/Common/Anchor";


interface Props {
    language: string;
    setExpanded: (e: boolean) => void;
}


const MENU_ITEMS = [
    {
        id: 1,
        name: "page.common.about_us",
        link: ABOUT_PAGE,
        self: true,
    },
    {
        id: 2,
        name: "page.common.careers_area",
        link: CAREER_PAGE,
        self: true,
    },
    {
        id: 3,
        name: "page.common.meet_our_people",
        link: MEET_OUR_PEOPLE_PAGE,
        self: true,
    },
];

const NavMenu: FC<Props> = ({ language, setExpanded }) => {
    const location = useLocation()
    return (
        <ul
            className={`nav ${FIND_NLM_PAGES(location.pathname)  ? 'd-none' : ''}`} >
            {MENU_ITEMS.map((item, index) => {
                return (
                    <li
                        onClick={() => setExpanded(false)}
                        onKeyDown={() => setExpanded(false)}
                        className="nav-item"
                        key={item.id}
                    >
                        <Anchor href={item.link}>
                            <FormattedMessage id={item.name} />
                        </Anchor>
                    </li>
                );
            })}
        </ul>
    );
};
export default NavMenu;
