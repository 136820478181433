import Product from "../../../assets/images/careerarea/product.webp";
import Design from "../../../assets/images/careerarea/design.webp";
import ManufacturingSupplyChain from "../../../assets/images/careerarea/manufacturing-supply-chain.webp";
import ResearchDevelopment from "../../../assets/images/careerarea/research-development.webp";
import MarketingSales from "../../../assets/images/careerarea/marketing-sales.webp";
import Aftersales from "../../../assets/images/careerarea/aftersales.webp";
import Purchasing from "../../../assets/images/careerarea/purchasing.webp";
import QualityAssurance from "../../../assets/images/careerarea/quality-assurance.webp";
import Finance from "../../../assets/images/careerarea/finance.webp";
import InformationTechnology from "../../../assets/images/careerarea/information-technology.webp";
import Communication from "../../../assets/images/careerarea/communication.webp";
import Legal from "../../../assets/images/careerarea/legal.webp";
import HumanResources from "../../../assets/images/careerarea/human-resources.webp";

import DesignDetails from "../../../assets/images/careerarea/details/design.webp";
import FinanceDetails from "../../../assets/images/careerarea/details/finance.webp";
import InformationTechnologyDetails from "../../../assets/images/careerarea/details/information-technology.webp";
import CommunicationDetails from "../../../assets/images/careerarea/details/communication.webp";
import AftersalesDetails from "../../../assets/images/careerarea/details/aftersales.webp";
import HumanResourcesDetails from "../../../assets/images/careerarea/details/human-resources.webp";
import ManufacturingSupplyChainDetails from "../../../assets/images/careerarea/details/manufacturing-supply-chain.webp";
import MarketingSalesDetails from "../../../assets/images/careerarea/details/marketing-sales.webp";
import LegalDetails from "../../../assets/images/careerarea/details/legal.webp";

import ResearchDevelopmentDetails from "../../../assets/images/careerarea/details/research-development.png";
import PurchasingDetails from "../../../assets/images/careerarea/details/purchasing.png";
import QualityAssuranceDetails from "../../../assets/images/careerarea/details/quality-assurance.png";
import ProductDetails from "../../../assets/images/careerarea/details/product.png";

import { WORKDAY_EN_PATH } from "../../../interfaces/Static/routing_paths";

export const CORPORATE_AREA_ITEMS = [
  {
    id: 1,
    src: Product,
    src_detail: ProductDetails,
    title: "page.career_area.corporate.title_1",
    description: "page.career_area.corporate.description_1",
    href: `${WORKDAY_EN_PATH}jobFamilyGroup=cf37143cc8d1019bf7f163742ab0e317&jobFamilyGroup=cf37143cc8d101ddc6cc3b7b2ab0f317`,
  },
  {
    id: 99,
    src: Design,
    src_detail: DesignDetails,
    title: "page.common.design",
    description: "page.career_area.corporate.description_design",
    href: `${WORKDAY_EN_PATH}jobFamilyGroup=cf37143cc8d101566db222822ab0f617`
  },
  {
    id: 2,
    src: ResearchDevelopment,
    src_detail: ResearchDevelopmentDetails,
    title: "page.career_area.corporate.title_2",
    description: "page.career_area.corporate.description_2",
    href: `${WORKDAY_EN_PATH}jobFamilyGroup=cf37143cc8d10124391f46d52ab0c118`
  },
  {
    id: 3,
    src: ManufacturingSupplyChain,
    src_detail: ManufacturingSupplyChainDetails,
    title: "page.career_area.corporate.title_3",
    description: "page.career_area.corporate.description_3",
    href: `${WORKDAY_EN_PATH}jobFamilyGroup=cf37143cc8d101c528e2808a2ab0ff17&jobFamilyGroup=cf37143cc8d1018dd041cddd2ab0c718&jobFamilyGroup=cf37143cc8d10163e79d72982ab01318`
  },
  {
    id: 4,
    src: MarketingSales,
    src_detail: MarketingSalesDetails,
    title: "page.career_area.corporate.title_4",
    description: "page.career_area.corporate.description_4",
    href: `${WORKDAY_EN_PATH}jobFamilyGroup=cf37143cc8d1014e9653609e2ab01518&jobFamilyGroup=cf37143cc8d1015aaf026ea42ab01718`
  },
  {
    id: 5,
    src: Aftersales,
    src_detail: AftersalesDetails,
    title: "page.career_area.corporate.title_5",
    description: "page.career_area.corporate.description_5",
    href: `${WORKDAY_EN_PATH}jobFamilyGroup=cf37143cc8d1017c6ff3a6e32ab0ce18`
  },
  {
    id: 6,
    src: Purchasing,
    src_detail: PurchasingDetails,
    title: "page.career_area.corporate.title_6",
    description: "page.career_area.corporate.description_6",
    href: `${WORKDAY_EN_PATH}jobFamilyGroup=8147df9c34b20182d6151954a39f274c`
  },
  {
    id: 7,
    src: QualityAssurance,
    src_detail: QualityAssuranceDetails,
    title: "page.career_area.corporate.title_7",
    description: "page.career_area.corporate.description_7",
    href: `${WORKDAY_EN_PATH}jobFamilyGroup=cf37143cc8d101c9eb8595912ab00618`
  },
  {
    id: 8,
    src: Finance,
    src_detail: FinanceDetails,
    title: "page.career_area.corporate.title_8",
    description: "page.career_area.corporate.description_8",
    href: `${WORKDAY_EN_PATH}jobFamilyGroup=cf37143cc8d1015aaf026ea42ab01718&jobFamilyGroup=cf37143cc8d10176a965d2b12ab05c18`
  },
  {
    id: 9,
    src: InformationTechnology,
    src_detail: InformationTechnologyDetails,
    title: "page.career_area.corporate.title_9",
    description: "page.career_area.corporate.description_9",
    href: `${WORKDAY_EN_PATH}jobFamilyGroup=cf37143cc8d10131dc5bfca92ab04c18`
  },
  {
    id: 10,
    src: Communication,
    src_detail: CommunicationDetails,
    title: "page.career_area.corporate.title_10",
    description: "page.career_area.corporate.description_10",
    href: `${WORKDAY_EN_PATH}jobFamilyGroup=cf37143cc8d10125581e73c42ab07418`
  },
  {
    id: 11,
    src: Legal,
    src_detail: LegalDetails,
    title: "page.career_area.corporate.title_11",
    description: "page.career_area.corporate.description_11",
    href: `${WORKDAY_EN_PATH}jobFamilyGroup=cf37143cc8d101b83ab1896d2ab0df17&jobFamilyGroup=cf37143cc8d1011f79061abe2ab06d18&jobFamilyGroup=cf37143cc8d10194418f26cd2ab09c18`
  },
  {
    id: 12,
    src: HumanResources,
    src_detail: HumanResourcesDetails,
    title: "page.career_area.corporate.title_12",
    description: "page.career_area.corporate.description_12",
    href: `${WORKDAY_EN_PATH}jobFamilyGroup=cf37143cc8d1014ada1f17b82ab06418&jobFamilyGroup=cf37143cc8d1010d8d4b50ea2ab0d218`
  },
];