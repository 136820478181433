import { MIDDLE_EAST_SUB_PAGES } from "../../Config/Static/ProgramDetail/MiddleEast";
import DEI1 from "../../assets/images/middle_east/dei_1.webp";
import DEI2 from "../../assets/images/middle_east/dei_2.webp";
import DEI3 from "../../assets/images/middle_east/dei_3.webp";
import DEI4 from "../../assets/images/middle_east/dei_4.webp";
import DEI5 from "../../assets/images/middle_east/dei_5.webp";
import DEI6 from "../../assets/images/middle_east/dei_6.webp";
import DEI7 from "../../assets/images/middle_east/dei_7.webp";
import DEI8 from "../../assets/images/middle_east/dei_8.webp";

import DEI9 from "../../assets/images/middle_east/dei_9.webp";

import TestimonialsMichaelSonu from "../../assets/images/middle_east/Testimonials_MichaelSonu.webp";

export const ME_DRIVING_TOGETHER = {
    heading: 'page.common.driving_together',
    title: 'page.common.driving_together_title',
    description: 'page.middle_east.driving_together_description',
    href: 'https://me.nissanmotornews.com/en/releases/fostering-a-safe-space-for-everyone-nissan-amieo-africa-middle-east-india-europe-oceania-shares-its-first-diversity-equity-and-inclusion-dei-report',
    href_jp: 'https://me.nissanmotornews.com/en/releases/fostering-a-safe-space-for-everyone-nissan-amieo-africa-middle-east-india-europe-oceania-shares-its-first-diversity-equity-and-inclusion-dei-report',
    src: [DEI1, DEI2, DEI3, DEI4, DEI5, DEI6, DEI7, DEI8, DEI9],
    detailed_description: 'page.middle_east.driving_together_descirption_bottom'
}

export const MIDDLE_EAST_JOURNEY = {
    heading: "page.discover.your_journey",
    title: "page.discover.your_journey.title",
    description: "page.discover.your_journey.description",
    items: MIDDLE_EAST_SUB_PAGES,
    sub_description: "page.discover.your_journey_description_bottom"
};

export const MIDDLE_EAST_TESTIMONIALS = [{
    id: 1,
    userName: 'Michael Sonu',
    userDesignation: 'page.testimonial.middle_east_designation_1',
    removeUsername: true,
    description: 'page.testimonial.middle_east_description_1',
    src: TestimonialsMichaelSonu,
    href: "https://www.youtube.com/watch?v=r4wItIxcI1g"
}];