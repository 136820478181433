import { FC } from "react";

import { Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Heading from "../../Common/Heading";
import { AnchorButton } from "../../Common/Anchor/Button";

import StoriesMobile from "./_mobile";
import StoriesDesktop from "./_desktop";
import { isMobile, isTablet } from "react-device-detect";
import { StoriesI } from "../../../../interfaces/RegionalPage";



const AmericasStories: FC<StoriesI> = ({ title, href, href_jp, items }) => {
    return (
        <section className="america-stories">
            <Row className="mb-4">
                <Col className="d-flex align-items-center" lg={8} md={8} xs={6}>
                    <Heading type="section-heading">
                        <FormattedMessage id={title} />
                    </Heading>
                </Col>
                <Col
                    lg={4}
                    md={4}
                    xs={6}
                    className="d-flex justify-content-end align-items-center"
                >
                    <p className="m-0">
                        <AnchorButton
                            href={href}
                            href_jp={href_jp}
                            variant={"primary-outline"}
                            external={true}
                        >
                            <FormattedMessage id="page.common.view_more" />
                        </AnchorButton>
                    </p>
                </Col>
            </Row>
            {isMobile && !isTablet ? (
                <StoriesMobile stories={items} />
            ) : (
                <StoriesDesktop stories={items} />
            )}
        </section>
    );
};
export default AmericasStories;
