import { FC } from "react";
import SiteMapBanner from "./_banner";
import MenuItems from "./_menu";

const SiteMap: FC = () => {
  return (
    <>
      <SiteMapBanner />
      <MenuItems />
    </>
  );
};
export default SiteMap;
