import experienced_hires from "../../../../assets/images/middle_east/experienced_hires.webp";
import experienced_hires_banner from "../../../../assets/images/middle_east/experienced_hires_banner.webp"
import { MIDDLE_EAST_PAGE, WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";

import { MIDDLE_EAST_PROGRAMS } from "./_programs";

const UAE_LOCATIONS = {
    id: 1,
    name: 'United Arab Emirates',
    slug: 'uae',
    items: MIDDLE_EAST_PROGRAMS.filter(x => ["dubai_nmef"].indexOf(x.slug) >= 0)
}
const SAUDI_ARABIA_LOCATIONS = {
    id: 1,
    name: 'Saudi Arabia',
    slug: 'saudi_arabia',
    items: MIDDLE_EAST_PROGRAMS.filter(x => ["jeddah_nksa"].indexOf(x.slug) >= 0)
}

export const MIDDLE_EAST_EXPERIENCED_HIRES = {
    id: 'experienced-hires',
    card_src: experienced_hires,
    card_title: "page.discover.experienced_hires",
    card_description: "page.discover.experienced_hires_description",
    banner_src: experienced_hires_banner,
    banner_title: "page.discover.experienced_hires",
    banner_description: "Drive the Narrative",
    slug: 'experienced-hires',
    href: `${MIDDLE_EAST_PAGE}/experienced-hires`,
    name: "page.discover.experienced_hires",
    description: "Play a vital role across the organization in all areas of the company. Connect and inspire new colleagues, leverage your experience, strategic thinking and expand your areas of expertise.",
    locations: [
        UAE_LOCATIONS,
        SAUDI_ARABIA_LOCATIONS
    ],
    work_day_link: `${WORKDAY_EN_PATH}locationCountry=50423b5190ad49bb89e94cd58dfaad69&locationCountry=7b4fa1f369bd4604ba3692682fcbe345`
}