import melbourne_victoria from "../../../../assets/images/oceania/melbourne-victoria.webp";
import melbourne_victoria_nma from "../../../../assets/images/oceania/melbourne-victoria-nma.webp";
import dandenong_victoria from "../../../../assets/images/oceania/dandenong-victoria.webp";

export const AUSTRALIA_NEWZEALAND_PROGRAM = [{
    id: 1,
    name: 'Melbourne, Victoria (NFSA)',
    slug: 'melbourne-victoria',
    description: "Nissan's Head office  is situated in Mulgrave 30 minutes south-east from Melbourne's CBD.  This modern office provides all the facilities needed to enable you to thrive.  It also has an impressive show room for you to explore our product range and be excited about what we offer our Customers.",
    src: melbourne_victoria,
    href: '/'
}, {
    id: 2,
    name: 'Melbourne, Victoria (NMA)',
    slug: 'melbourne-victoria-nma',
    description: "Nissan's Head office  is situated in Mulgrave 30 minutes south-east from Melbourne's CBD.  This modern office provides all the facilities needed to enable you to thrive.  It also has an impressive show room for you to explore our product range and be excited about what we offer our Customers.",
    src: melbourne_victoria_nma,
    href: '/'
}, {
    id: 3,
    name: 'Dandenong, Victoria (NCAP)',
    slug: 'dandenong-victoria',
    description: "Located 40 minutes South of Melbourne in the Industrial heartland of Victorian manufacturing providng a range of specialist manufacturing roles across the production and planning cycle.",
    src: dandenong_victoria,
    href: '/'
}]