import { FC } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { isMobile, isTablet, isDesktop } from "react-device-detect";

import LinkarrowImage from "../../../../assets/images/link-arrow.png";
import Product from "../../../../assets/images/product.webp";
import ResearchDevelopment from "../../../../assets/images/research-development.webp";
import ManufacturingSupplyChain from "../../../../assets/images/manufacturing-supply-chain.webp";
import MarketingSales from "../../../../assets/images/marketing-sales.webp";
import Aftersales from "../../../../assets/images/aftersales.webp";
import Purchasing from "../../../../assets/images/purchasing.webp";

import SimpleImage from "../../Media/SimpleImage";
import Heading from "../Heading";
import { Anchor } from "../Anchor";
import { AnchorButton } from "../Anchor/Button";

import { CAREER_PAGE } from "../../../../interfaces/Static/routing_paths";

const items = [
  {
    id: 1,
    icon: Product,
    reactIntl: "page.career_area.corporate.title_1",
  },
  {
    id: 2,
    icon: ResearchDevelopment,
    reactIntl: "page.career_area.corporate.title_2",
  },
  {
    id: 3,
    icon: ManufacturingSupplyChain,
    reactIntl: "page.career_area.corporate.title_3",
  },
  {
    id: 4,
    icon: MarketingSales,
    reactIntl: "page.career_area.corporate.title_4",
  },
  {
    id: 5,
    icon: Aftersales,
    reactIntl: "page.career_area.corporate.title_5",
  },
  {
    id: 6,
    icon: Purchasing,
    reactIntl: "page.career_area.corporate.title_6",
  },
];

const CareerArea: FC = () => {
  const itemLength = isMobile && !isTablet ? 4 : 6;
  return (
    <div className="career-area">
      <Container>
        <Row>
          <Col lg={6} md={12} className="pb-3">
            <Heading type="section-heading">
              <FormattedMessage id="page.common.nissan_corporate_careers_area" />
            </Heading>
            <p>
              <FormattedMessage id="page.common.nissan_corporate_careers_area.description" />
            </p>

            {(isTablet || isDesktop) && (
              <Anchor href={`${CAREER_PAGE}#corporate-carrer-area`}>
                <FormattedMessage id="page.common.explore" />{" "}
                <SimpleImage src={LinkarrowImage} />
              </Anchor>
            )}
          </Col>
          {items.slice(0, itemLength).map((item, index) => {
            return (
              <Col lg={3} md={6} sm={6} xs={12} key={index}>
                <Card>
                  <SimpleImage src={item.icon} />
                  <div className="card-details">
                    <div className="card-title">
                      <h5>
                        <FormattedMessage id={item.reactIntl} />
                      </h5>
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
          {isMobile && !isTablet && (
            <Col lg={3} md={6} sm={6} xs={12} className="text-center">
              <AnchorButton
                variant={"primary-outline"}
                href={`${CAREER_PAGE}#corporate-carrer-area`}
                external={false}
              >
                <FormattedMessage id="page.common.see_more" />
              </AnchorButton>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default CareerArea;
