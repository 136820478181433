import { ENGINEERING_LINKS } from "./_engineering";
import { JS_PAGE_ITEMS } from "./_interface";
import job_search_engineering_banner from "../../../assets/images/nml/job_search_engineering_banner.webp";
import job_search_business_banner from "../../../assets/images/nml/job_search_business_banner.webp";
import { BUSINESS_LINKS } from "./_business";

const ENGINEERING_KEY = 'engineering';
const BUSINESS_KEY = 'business';

export const NML_SEARCHES: JS_PAGE_ITEMS[] = [{
    id: 1,
    name: "page.common.engineering",
    banner_src: job_search_engineering_banner,
    banner_title: "page.common.job_search",
    banner_description: "page.common.search_by_category",
    key: ENGINEERING_KEY,
    note: "page.job_search.note",
    items: ENGINEERING_LINKS,
    breadCrumbTitle: "page.nml.ri.jobsearch_engineering"
},{
    id: 2,
    name: "page.common.business",
    banner_src: job_search_business_banner,
    banner_title: "page.common.job_search",
    banner_description: "page.common.search_by_category",
    key: BUSINESS_KEY,
    note: "page.job_search.note",
    items: BUSINESS_LINKS,
    breadCrumbTitle: "page.nml.ri.jobsearch_business"
}]