import { FC } from "react";

import Environmental from "../../../../assets/images/environmental.webp";
import Social from "../../../../assets/images/social.webp";
import Governance from "../../../../assets/images/governance.webp";
import NewsCard from "../Card/NewsCard";

const news_card_props = {
  headerItems: {
    title: "page.common.sustainability",
    href: "https://www.nissan-global.com/EN/SUSTAINABILITY/",
    href_jp: "https://www.nissan-global.com/JP/SUSTAINABILITY/",
    anchorText: "page.common.see_more",
    variant: "primary-outline",
    external: true,
    mobileView: true,
  },
  items: [
    {
      id: 1,
      name: "page.common.environmental",
      description: "page.sustainability.description_1",
      src: Environmental,
      href: "https://www.nissan-global.com/EN/SUSTAINABILITY/ENVIRONMENT/",
      href_jp: "https://www.nissan-global.com/JP/SUSTAINABILITY/ENVIRONMENT/",
    },
    {
      id: 2,
      name: "page.common.social",
      description: "page.sustainability.description_2",
      src: Social,
      href: "https://www.nissan-global.com/EN/SUSTAINABILITY/SOCIAL/",
      href_jp: "https://www.nissan-global.com/JP/SUSTAINABILITY/SOCIAL/"
    },
    {
      id: 3,
      name: "page.common.governance",
      description: "page.sustainability.description_3",
      src: Governance,
      href: "https://www.nissan-global.com/EN/SUSTAINABILITY/GOVERNANCE/",
      href_jp: "https://www.nissan-global.com/JP/SUSTAINABILITY/GOVERNANCE/"
    },
  ],
};
const Sustainability: FC = () => {
  return (
    <div className="sustainability-area">
      <NewsCard {...news_card_props} />
    </div>
  );
};
export default Sustainability;
