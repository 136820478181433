import { FC } from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import JourneyCard from "../../Common/Card/Journey";
import { SUB_PAGES } from "../../../../interfaces/RegionalPage";

interface Props {
    items: SUB_PAGES[]
}

const DiscoverJourneyMobile: FC<Props> = ({ items }) => {
    return (
        <Splide
            options={{
                arrows: false,
                pagination: false,
                width: "100%",
                perPage: 1,
                type: "loop",
                rewind: false,
                autoplay: true,
                pauseOnHover: false,
                resetProgress: false,
                gap: "-35px",
                interval: 5000
            }}
        >

            {
                items.length > 0 && items.map((item) => {
                    return (
                        <SplideSlide key={item.id}>
                            <JourneyCard journey={item} isMobile={true} />
                        </SplideSlide>
                    )
                })
            }
        </Splide>
    );
};
export default DiscoverJourneyMobile;
