import manufacturing from "../../../../assets/images/africa/programs_manufacturing.webp"
import programs_manufacturing_banner from "../../../../assets/images/africa/programs_manufacturing_banner.webp"
import { WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";
import { AFRICA_PROGRAMS } from "./_programs";

const South_AFRICA_LOCATIONS = {
    id: 1,
    name: 'South Africa',
    slug: 'south-africa',
    items: AFRICA_PROGRAMS.filter(x => ["rosslyn"].indexOf(x.slug) >= 0)
}

const EGYPT_LOCATIONS = {
    id: 1,
    name: 'Egypt',
    slug: 'egypt',
    items: AFRICA_PROGRAMS.filter(x => ["giza"].indexOf(x.slug) >= 0)
}

export const AFRICA_MANUFACTURING_PAGES = {
    id: 'manufacturing',
    card_src: manufacturing,
    card_title: "page.discover.manufacturing",
    card_description: "page.discover.africa.manufacturing_description",
    banner_src: programs_manufacturing_banner,
    banner_title: "page.discover.manufacturing",
    banner_description: "Craft & Assemble Vehicles",
    slug: 'manufacturing',
    href: '/africa/manufacturing',
    name: "page.discover.manufacturing",
    description: "Nissan Africa has a strong industrial footprint in Africa with two fully owned factories in South Africa & Egypt as well as two DKD assembly plants in Ghana & Nigeria.",
    locations: [
        South_AFRICA_LOCATIONS,
        EGYPT_LOCATIONS
    ],
    work_day_link: `${WORKDAY_EN_PATH}locationCountry=d865e83093ad42319653b08e61f7db49&locationCountry=4db3c26940b944919b83612cc1193eed`
}