import { FC } from "react";
import { Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { AnchorButton } from "../../Anchor/Button";
import CardTitle from "../CardTitle";

interface Props {
  title: string;
  href?: string;
  href_jp?: string;
  anchorText?: string;
  children?: React.ReactNode;
  variant?: string;
  external?: boolean | false;
  mobileView?: boolean | false;
}
const CardHeader: FC<Props> = ({
  title,
  href,
  href_jp,
  anchorText,
  variant,
  external,
  mobileView,
}) => {
  return (
    <Row className="mb-3 d-flex align-items-center">
      <Col lg={8} md={8} xs={7}>
        <CardTitle>
          <FormattedMessage id={title} />
        </CardTitle>
      </Col>
      {href && (
        <Col
          lg={4}
          md={4}
          xs={5}
          className="d-flex justify-content-end align-items-center"
        >
          <AnchorButton
            mobileView={mobileView}
            href={href}
            href_jp={href_jp}
            variant={variant}
            external={external}
          >
            <FormattedMessage id={anchorText} />
          </AnchorButton>
        </Col>
      )}
    </Row>
  );
};
export default CardHeader;
