import { FC, useEffect, useState } from 'react';
import { Tabs, Tab, TabScreen } from 'react-tabs-scrollable'
import { LocationProps } from '../../../../interfaces/RegionalPage';
import PaneArea from './_pane_area';
import { FormattedMessage } from 'react-intl';

interface Props {
    activeKey?: string;
    translation?: boolean;
    slug: string;
    locations: LocationProps[]
}

const PillsTab: FC<Props> = ({ slug, translation, locations, activeKey }) => {
    const [activeTab, setActiveTab] = useState<any>(0);
    const onTabClick = (e: any, index: any) => {
        setActiveTab(index)
    }
    useEffect(() => {
        setActiveTab(0)
    }, [activeKey]);
    return (
        <>
            <Tabs activeTab={activeTab} onTabClick={onTabClick}>
                {
                    locations.length > 0 && locations.map((location, index) => {
                        return <Tab key={index}>{translation ? <FormattedMessage id={location.name} /> : location.name}</Tab>
                    })
                }
            </Tabs>

            {
                locations.length > 0 && locations.map((location, index) => {
                    return <TabScreen
                        key={index}
                        activeTab={activeTab}
                        index={index}
                        className='tab-content'
                    >
                        <PaneArea location={location} />
                    </TabScreen>
                })
            }
        </>
    );
}

export default PillsTab;