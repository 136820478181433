import { FC } from "react";
import { Container, Carousel } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import StoryHome1 from "../../../assets/images/story_home_1.webp";
import StoryHome2 from "../../../assets/images/story_home_2.webp";
import StoryHome3 from "../../../assets/images/story_home_3.webp";


import StoryHome1Mobile from "../../../assets/images/story_home_1_mobile.webp";
import StoryHome2Mobile from "../../../assets/images/story_home_2_mobile.webp";
import StoryHome3Mobile from "../../../assets/images/story_home_3_mobile.webp";

import { Anchor } from "../../Shared/Common/Anchor";

import SimpleImage from "../../Shared/Media/SimpleImage";
import { isMobile, isTablet } from "react-device-detect";
const items = [
  {
    id: 1,
    src: StoryHome1,
    mobile_src: StoryHome1Mobile,
    description: "page.nissan_story.title_1",
    href: "https://www.nissan-global.com/EN/STORIES/RELEASES/commitment-to-quality-propels-infiniti-qx80-production-team-in-kyushu/",
    href_jp:
      "https://www.nissan-global.com/JP/STORIES/RELEASES/commitment-to-quality-propels-infiniti-qx80-production-team-in-kyushu/",
  },
  {
    id: 2,
    src: StoryHome2,
    mobile_src: StoryHome2Mobile,
    description: "page.nissan_story.title_2",
    href: "https://www.nissan-global.com/EN/COMPANY/LIFE_AT_NISSAN/LEADING_A_GENDER-DIVERSE_TEAM/",
    href_jp:
      "https://www.nissan-global.com/JP/COMPANY/LIFE_AT_NISSAN/LEADING_A_GENDER-DIVERSE_TEAM/",
  },
  {
    id: 3,
    src: StoryHome3,
    mobile_src: StoryHome3Mobile,
    description: "page.nissan_story.title_3",
    href: "https://www.nissan-global.com/EN/STORIES/RELEASES/a-smooth-road-to-recovery/",
    href_jp:
      "https://www.nissan-global.com/JP/STORIES/RELEASES/a-smooth-road-to-recovery/",
  },
];
const StoryBoard: FC = () => {
  return (
    <div className="slide-area">
      <Splide
        options={{
          arrows: false,
          pagination: true,
          width: "100%",
          type: "loop",
          perPage: 1,
          perMove: 1,
          rewind: true,
          autoplay: true,
          pauseOnHover: false,
          resetProgress: false,
          interval: 10000,
          classes: {
            pagination: "container custom-pagination",
          },
        }}
      >
        {items.map((item, index) => {
          return (
            <SplideSlide key={index}>
              <div className="slider-inner-container">
                <SimpleImage src={isMobile && !isTablet ? item.mobile_src : item.src} />
              </div>
              <Container>
                <Anchor href={item.href} href_jp={item.href_jp} external={true}>
                  <div className="caption">
                    <FormattedMessage id="page.common.nissan_stories" />
                    <h1>
                      <FormattedMessage id={item.description} />
                    </h1>
                  </div>
                </Anchor>
              </Container>
            </SplideSlide>
          );
        })}
      </Splide>
    </div>
  );
};
export default StoryBoard;
