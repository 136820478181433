import { SUB_PAGES } from "../../../../interfaces/RegionalPage";
import { AFRICA_CORPORATE_ROLES_PAGES } from "./_corporate_roles";
import { AFRICA_EARLY_CAREERS_PAGES } from "./_early_careers";
import { AFRICA_MANUFACTURING_PAGES } from "./_manufacturing";
 

export const AFRICA_SUB_PAGE: SUB_PAGES[] = [
    AFRICA_MANUFACTURING_PAGES,
    AFRICA_CORPORATE_ROLES_PAGES,
    AFRICA_EARLY_CAREERS_PAGES
]