import { FC } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Card, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";

interface Item {
  id: number;
  videosrc: string;
  videosrcJp: string;
  startFrom: number;
}
interface Props {
  items: Item[];
}
const YoutubeCard: FC<Props> = ({ items }) => {
  const intl = useIntl();
  const { locale } = intl;

  return (
    <Row>
      {/* <Splide
        options={{
          arrows: false,
          pagination: true,
          width: "100%",
          type: "loop",
          perPage: 2,
          perMove: 2,
          breakpoints: {
            1000: {
              perPage: 2,
            },
            600: {
              perPage: 1,
              perMove: 1,
            },
          },
          gap: "15px",
          rewind: false,
          autoplay: true,
          pauseOnHover: false,
          resetProgress: false,
          interval: 5000,
        }}
      > */}
      {items.map((item, index) => {
        return (
          <Col md={6} key={index}>
            <Card>
              <iframe
                title="Meet the Nissan Regional Business Services Team 1"
                className="responsive-iframe"
                width={"100%"}
                height={"300"}
                src={`${
                  locale === "en" ? item.videosrc : item.videosrcJp
                }/?rel=0&start=${item.startFrom}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Card>
          </Col>
        );
      })}
      {/* </Splide> */}
    </Row>
  );
};

export default YoutubeCard;
