import { AUSTRALIA_NEWZEALAND_SUB_PAGES } from "../../Config/Static/ProgramDetail/Oceania";
import DEI1 from "../../assets/images/oceania/dei_1.webp";
import DEI2 from "../../assets/images/oceania/dei_2.webp";
import DEI3 from "../../assets/images/oceania/dei_3.webp";
import DEI4 from "../../assets/images/oceania/dei_4.webp";
import DEI5 from "../../assets/images/oceania/dei_5.webp";
import DEI6 from "../../assets/images/oceania/dei_6.webp";

import testimonial_hill from "../../assets/images/oceania/testimonial_hill.webp";
import testimonial_balaji from "../../assets/images/oceania/testimonial_balaji.webp";
import testimonial_allanah from "../../assets/images/oceania/testimonial_allanah.webp";
import testimonial_christine from "../../assets/images/oceania/testimonial_christine.webp";
import testimonial_ben from "../../assets/images/oceania/testimonial_ben.webp";
import testimonial_denise from "../../assets/images/oceania/testimonial_denise.webp";

export const AUSTRALIA_NEWZEALAND_JOURNEY = {
    heading: "page.discover.your_journey",
    title: "page.oceania.discover.your_journey.title",
    description: "page.discover.your_journey.description",
    items: AUSTRALIA_NEWZEALAND_SUB_PAGES
};

export const DRIVING_TOGETHER = {
    heading: 'page.common.driving_together',
    title: 'page.oceania.dei.title',
    description: 'page.oceania.driving_together_description',
    href: 'https://australia.nissannews.com/en-AU/releases/fostering-a-safe-space-for-everyone-nissan-amieo-africa-middle-east-india-europe-oceania-shares-its-first-diversity-equity-and-inclusion-dei-report',
    href_jp: 'https://australia.nissannews.com/en-AU/releases/fostering-a-safe-space-for-everyone-nissan-amieo-africa-middle-east-india-europe-oceania-shares-its-first-diversity-equity-and-inclusion-dei-report',
    src: [DEI1,  DEI3, DEI4, DEI5, DEI6],
    detailed_description: 'page.oceania.driving_together_descirption_bottom'
}


export const AUSTRALIA_NEWZEALAND_TESTIMONIALS = [{
    id: 1,
    userName: 'Christine Santos',
    userDesignation: 'page.testimonial.oceania_designation_1',
    description: 'page.testimonial.oceania_description_1',
    src: testimonial_christine
},{
    id: 2,
    userName: 'Michael Hill',
    userDesignation: 'page.testimonial.oceania_designation_2',
    description: 'page.testimonial.oceania_description_2',
    src: testimonial_hill
},{
    id: 3,
    userName: 'Ben Warren',
    userDesignation: 'page.testimonial.oceania_designation_3',
    description: 'page.testimonial.oceania_description_3',
    src: testimonial_ben
},{
    id: 4,
    userName: 'Balaji Narayana',
    userDesignation: 'page.testimonial.oceania_designation_4',
    description: 'page.testimonial.oceania_description_4',
    src: testimonial_balaji
},{
    id: 5,
    userName: 'Alannah Weeks',
    userDesignation: 'page.testimonial.oceania_designation_5',
    description: 'page.testimonial.oceania_description_5',
    src: testimonial_allanah
},{
    id: 6,
    userName: 'Denise McKechnie',
    userDesignation: 'page.testimonial.oceania_designation_6',
    description: 'page.testimonial.oceania_description_6',
    src: testimonial_denise
}];