import { FC } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import CardTitle from "../../../Shared/Common/Card/CardTitle";
import { Anchor } from "../../../Shared/Common/Anchor";
import { JOBS_BY_REGION } from "../../../../interfaces/Static";

const items = JOBS_BY_REGION.slice(0, 4);

const second_items = JOBS_BY_REGION.slice(4, 7);

const asia_items = JOBS_BY_REGION.slice(7, 9);

const JobsByRegion: FC = () => {
  return (
    <div className="jobbyregion-area">
      <Container>
        <CardTitle>
          <FormattedMessage id={"page.common.jobs_by_region"} />
        </CardTitle>


        <Row>
          {asia_items.map((item, index) => {
            return (
              <Col key={index}  md={6}>
                <div className="region-items" key={index}>
                  <Anchor href={item.href} external={true}>
                    <div className="caption">
                      <FormattedMessage id={item.title} />
                    </div>
                  </Anchor>
                </div>
              </Col>
            );
          })}
        </Row>
        <Row>
          {items.map((item, index) => {
            return (
              <Col key={index} lg={3} md={6}>
                <div className="region-items">
                  <Anchor href={item.href} external={true}>
                    <div className="caption" key={index}>
                      <FormattedMessage id={item.title} />
                    </div>
                  </Anchor>
                </div>
              </Col>
            );
          })}
        </Row>

        <Row>
          {second_items.map((item, index) => {
            return (
              <Col key={index} lg={4} md={6}>
                <div className="region-items" key={index}>
                  <Anchor href={item.href} external={true}>
                    <div className="caption">
                      <FormattedMessage id={item.title} />
                    </div>
                  </Anchor>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default JobsByRegion;
