import { FC } from 'react';
import { LocationProps } from '../../../../interfaces/RegionalPage';
import SliderTabPane from './_slider_tab_pane';
import TabPane from './_tab_pane';

interface Props {
    location: LocationProps
}

const PaneArea: FC<Props> = ({ location }) => {
    return (
        <>
            {location.items.length > 0 && (
                location.items.length > 3 ? <SliderTabPane location={location} /> : <TabPane location={location} />
            )}

        </>
    );
}

export default PaneArea;