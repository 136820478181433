import { FC } from "react";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

interface Props {
  profile: string;
  empName: string;
  empCountry: string;
  detail: string;
  japanese: boolean;
}
const TestimonialsCard: FC<Props> = ({
  profile,
  empName,
  empCountry,
  detail,
  japanese,
}) => {
  return (
    <Card>
      <Card.Header>
        <Card.Img src={profile} className="rounded-circle" alt={empName} />
        <Card.Title>
          <h6>{empName}</h6>
          <span><FormattedMessage id={empCountry} /></span>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <p ><FormattedMessage id={detail} /></p>
      </Card.Body>
    </Card>
  );
};

export default TestimonialsCard;
