import About from "../Components/About";
import { Helmet } from "react-helmet-async";
function AboutContainer() {
  return (
    <>
      <Helmet>
        <title>
          NISSAN MOTOR CORPORATION - GLOBAL CAREER WEBSITE - ABOUT US - OVERVIEW
        </title>
      </Helmet>
      <main className="about-container">
        <About />
      </main>
    </>
  );
}

export default AboutContainer;
