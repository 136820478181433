import early_hires from "../../../../assets/images/asean/discover_early.webp";
import Early_Hire from "../../../../assets/images/asean/early_hires_banner.webp";
import { WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";
import { ASEAN_PROGRAMS } from "./_programs";

const thailand_slugs = [
    "bangkok-tha",
    "samut-prakan-tha-4"
];

const THAILAND_PROGRAMS = ASEAN_PROGRAMS.filter(x => thailand_slugs.indexOf(x.slug) >= 0);

const THAILAND_LOCATIONS = {
    id: 1,
    name: 'Thailand',
    slug: 'thailand',
    items: THAILAND_PROGRAMS
}

const THAILAND_SCG_LOCATIONS = {
    id: 1,
    name: 'Thailand',
    slug: 'thailand',
    items: ASEAN_PROGRAMS.filter(x => ["samut-prakan-tha"].indexOf(x.slug) >= 0)
}

const THAILAND_ENGINEERING_LOCATIONS = {
    id: 1,
    name: 'Thailand',
    slug: 'thailand',
    items: ASEAN_PROGRAMS.filter(x => ["samut-prakan-tha-3"].indexOf(x.slug) >= 0)
}

const PHILIPPINES_ENGINEERING_LOCATIONS = {
    id: 1,
    name: 'Philippines',
    slug: 'philippines',
    items: ASEAN_PROGRAMS.filter(x => ["manila-phi-3"].indexOf(x.slug) >= 0)
}

const philipines_slugs = [
    "manila-phi-4"
];

const PHILIPPINES_PROGRAMS = ASEAN_PROGRAMS.filter(x => philipines_slugs.indexOf(x.slug) >= 0);

const PHILIPINES_LOCATIONS = {
    id: 1,
    name: 'Philippines',
    slug: 'philippines',
    items: PHILIPPINES_PROGRAMS
}

const thailand_be_slugs = [
    "bangkok-tha-3"
];

const THAILAND_BE_PROGRAMS = ASEAN_PROGRAMS.filter(x => thailand_be_slugs.indexOf(x.slug) >= 0);

const THAILAND_BE_LOCATIONS = {
    id: 1,
    name: 'Thailand',
    slug: 'thailand',
    items: THAILAND_BE_PROGRAMS
}
const philipines_be_slugs = [
    "manila-phi-3"
];
const PHILIPPINES_BE_PROGRAMS = ASEAN_PROGRAMS.filter(x => philipines_be_slugs.indexOf(x.slug) >= 0);

const PHILIPINES_BE_LOCATIONS = {
    id: 1,
    name: 'Philippines',
    slug: 'philippines',
    items: PHILIPPINES_BE_PROGRAMS
}

const indonesia_be_slugs = [
    "jakarta-idn"
];
const INDONESIA_BE_PROGRAMS = ASEAN_PROGRAMS.filter(x => indonesia_be_slugs.indexOf(x.slug) >= 0);

const INDONESIA_BE_LOCATIONS = {
    id: 1,
    name: 'Indonesia',
    slug: 'ondonesia',
    items: INDONESIA_BE_PROGRAMS
}


const thailand_in_slugs = [
    "bangkok-tha-2"
];

const THAILAND_IN_PROGRAMS = ASEAN_PROGRAMS.filter(x => thailand_in_slugs.indexOf(x.slug) >= 0);

const THAILAND_IN_LOCATIONS = {
    id: 1,
    name: 'Thailand',
    slug: 'thailand',
    items: THAILAND_IN_PROGRAMS
}

export const ASEAN_EARLY_HIRES = {
    id: 'early-careers',
    card_src: early_hires,
    card_title: "page.discover.early_careers",
    card_description: "page.discover.asia.early_careers_description",
    banner_src: Early_Hire,
    banner_title: "page.discover.early_careers",
    banner_description: "Embark on a Thrilling Journey",
    slug: 'early-careers',
    href: '/asean/early-careers',
    name: "page.discover.early_careers",
    description: "Immerse yourself in the world of mobility. Expand the boundaries of possibility and chart your own path at Nissan through our internship opportunities, MBA graduate programs, campus connection events, and entry level positions.",
    categories: [{
        id: 1,
        name: 'Manufacturing & SCM',
        slug: '',
        description: "Step onto the production floor where teamwork, precision and craftsmanship meet. Contribute to the creation of your favorite vehicles through advanced manufacturing processes, automation, and quality control, ensuring every detail exceeds expectations.",
        locations: [
            THAILAND_SCG_LOCATIONS
        ]
    }, {
        id: 2,
        name: 'Engineering',
        slug: '',
        description: "Engineering function is the driving force behind innovation in automotive technologies. From designing to developing and innovating, we are at the forefront of shaping the future of transportation, working on cutting-edge projects to develop high quality of passenger and commercial car with driver-assist technologies, improve fuel efficiency, enhance safety features, and optimize production processes. Be part of a journey that creates a driving experience that is comfortable, enjoyable, and safe.",
        locations: [
            THAILAND_ENGINEERING_LOCATIONS,
            PHILIPPINES_ENGINEERING_LOCATIONS
        ]
    }, {
        id: 3,
        name: 'Marketing & Sales',
        slug: '',
        description: "Marketing & Sales function is responsible for creating strategies to create a positive experience, promote and sell Nissan vehicles and services.  We transcend traditional approaches by deeply understanding customer needs through in-depth market research. We architect marketing campaigns that not only promote and sell Nissan vehicles but also elevate brand visibility and redefine the entire driving experience, extending even into post-sale engagement",
        locations: [
            THAILAND_LOCATIONS,
            PHILIPINES_LOCATIONS
        ]
    }, {
        id: 4,
        name: 'Business Enablers',
        slug: '',
        description: "Business Enablers go beyond traditional support functions. They encompass dynamic departments like Human Resources, Finance & Accounting, IS/IT, Legal, Communications, Purchasing, and Administration. These pillars of strength are the backbone, ensuring seamless operations and providing the vital infrastructure for Nissan's core business activities. Join us in empowering excellence and driving the success of Nissan's vision.",
        locations: [
            THAILAND_BE_LOCATIONS,
            PHILIPINES_BE_LOCATIONS,
            INDONESIA_BE_LOCATIONS
        ]
    }, {
        id: 5,
        name: 'Internships',
        slug: '',
        description: "At Nissan, we offer an internship program opportunity for 3rd-4th year students who are passionate about automotive and technology.You will be learning from our experts and take control of your own project.",
        locations: [
            THAILAND_IN_LOCATIONS
        ]
    }],
    work_day_link: `${WORKDAY_EN_PATH}locationCountry=b31234dbcdda4da9ba8fa073c5944e36&locationCountry=e56f1daf83e04bacae794ba5c5593560&locationCountry=80938777cac5440fab50d729f9634969&locationCountry=873d0f604e3b458c990cb4d83a5c0f14`
}