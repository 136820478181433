import { Row, Col, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { RECRUITING_INFORMATION } from "../_static";
import RecruitingInformationCard from "../../Shared/Common/Card/NmlRecruitingInformation";

const RecruitingInformation = () => {
    return (
        <div className="recruiting-information">
            <h2>
                <FormattedMessage id={"page.common.recruiting_information"} />
            </h2>
            <p>
                <FormattedMessage id={"page.nml.recruting_notes"} />
            </p>
            <Row>
                {
                    RECRUITING_INFORMATION.map(info => {
                        return (
                            <Col lg key={info.id}>
                                <RecruitingInformationCard info={info} key={info.id} />
                            </Col>
                        )
                    })
                }
            </Row>
        </div>

    )
}

export default RecruitingInformation;