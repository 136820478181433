import { FC } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import CardTitle from "../../Shared/Common/Card/CardTitle";
import { AnchorButton } from "../../Shared/Common/Anchor/Button";
import StoriesDesktop from "./_desktop_card";
import { isMobile, isTablet } from "react-device-detect";
import StoriesMobile from "./_mobile_card";

const Stories: FC = () => {
  return (
    <div className="nissan-stories">
      <Container>
        <CardTitle>
          <FormattedMessage id={"page.common.nissan_stories"} />
        </CardTitle>
        <Row className="mt-3">
          {isMobile && !isTablet ? <StoriesMobile /> : <StoriesDesktop />}
        </Row>
        <Row className="mt-3">
          <Col className="d-flex nissan-stories-btn">
            <AnchorButton
              href="https://global.nissanstories.com/en"
              href_jp="https://global.nissanstories.com/ja-JP"
              variant={"primary"}
              external={true}
            >
              <FormattedMessage id="page.common.nissan_stories" />
            </AnchorButton>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Stories;
