
import employee_work_life_balance from "../../../assets/images/americas/employee_work_life_balance.webp";
import employee_multinational from "../../../assets/images/asean/employee_multinational.webp";
import employee_comprehensive from "../../../assets/images/nml/employee_comprehensive.webp";
import employee_extensive from "../../../assets/images/nml/external_mobility.webp";
import employee_career_opportunities from "../../../assets/images/nml/employee_career_opportunities.webp";
import employee_thrilling from "../../../assets/images/asean/employee_thrilling.webp";


export const NML_EMPLOYEE_LOVES_STATIC = [{
    id: 1,
    src: employee_work_life_balance,
    title: "page.nml.employee_love_title_1",
    description: "page.nml.employee_love_description_1"
}, {
    id: 2,
    src: employee_multinational,
    title: "page.nml.employee_love_title_2",
    description: "page.nml.employee_love_description_2"
}, {
    id: 3,
    src: employee_comprehensive,
    title: "page.nml.employee_love_title_3",
    description: "page.nml.employee_love_description_3"
}, {
    id: 4,
    src: employee_extensive,
    title: "page.nml.employee_love_title_4",
    description: "page.nml.employee_love_description_4"
}, {
    id: 5,
    src: employee_career_opportunities,
    title: "page.nml.employee_love_title_5",
    description: "page.nml.employee_love_description_5"
}, {
    id: 6,
    src: employee_thrilling,
    title: "page.nml.employee_love_title_6",
    description: "page.nml.employee_love_description_6"
}];

export const NML_EMPLOYEE_LOVES_STATIC_MOBILE = [{
    id: 1,
    mobile_items: [{
        id: 1,
        src: employee_work_life_balance,
        title: "page.nml.employee_love_title_1",
        description: "page.nml.employee_love_description_1"
    }, {
        id: 2,
        src: employee_multinational,
        title: "page.nml.employee_love_title_2",
        description: "page.nml.employee_love_description_2"
    }]
},
{
    id: 2,
    mobile_items: [{
        id: 3,
        src: employee_comprehensive,
        title: "page.nml.employee_love_title_3",
        description: "page.nml.employee_love_description_3"
    }, {
        id: 4,
        src: employee_extensive,
        title: "page.nml.employee_love_title_4",
        description: "page.nml.employee_love_description_4"
    }]
},
{
    id: 3,
    mobile_items: [{
        id: 5,
        src: employee_career_opportunities,
        title: "page.nml.employee_love_title_5",
        description: "page.nml.employee_love_description_5"
    }, {
        id: 6,
        src: employee_thrilling,
        title: "page.nml.employee_love_title_6",
        description: "page.nml.employee_love_description_6"
    }]
}
];