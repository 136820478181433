import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { FC } from "react";
import SimpleImage from "../../../Media/SimpleImage";
import { Anchor } from "../../Anchor";
import ExploreIcon from "../../../../../assets/images/nml/explore_icon.svg";

interface InfoProps {
    id: number;
    src: string;
    name: string;
    description: string;
    href: string;
    href_jp: string;
    external: boolean;
}
interface Props {
    info: InfoProps
}
const RecruitingInformationCard: FC<Props> = ({ info }) => {
    return (
        <Card key={info.id}>
            <SimpleImage className="card-img-top" src={info.src} />
            <div className="card-body px-0">
                <div className="cont-test">
                    <h5 className="card-title">
                        <FormattedMessage id={info.name} />
                    </h5>
                    <p className="card-text">
                        <FormattedMessage id={info.description} />
                    </p>
                    <Anchor href={info.href} href_jp={info.href_jp} external={info.external}>
                        <button className="btn btn-primary-outline">
                            <FormattedMessage id="page.common.explore" />
                            <SimpleImage src={ExploreIcon} /> 
                        </button>
                    </Anchor>
                </div>
            </div>
        </Card>

    )
}

export default RecruitingInformationCard;