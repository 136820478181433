import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname, hash } = useLocation();
  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    if (!hash) {
      document.documentElement.scrollTo({
        top: 0,
        left: 0
      });
    }
  }, [pathname, hash]);

  return null;
}
