import { FC } from "react";
import { Row, Col, Container } from "react-bootstrap";
import moment from "moment";
import Heading from "../Heading";
import { FormattedMessage } from "react-intl";
import { AnchorButton } from "../Anchor/Button";
const since_in_years = Math.floor(
  moment(new Date()).diff(moment("12/26/1933", "MM/DD/YYYY"), "years", true)
);
interface Props {
  external?: boolean | false;
  href: string;
  name: string;
  href_jp?: string;
}
const NissanAtGlance: FC<Props> = ({ external, href, name, href_jp }) => {
  return (
    <>
      <div className="about-area">
        <Container>
          <Row>
            <Col lg={12}>
              <Heading type="detailed-sub-heading">
                <FormattedMessage id={"page.common.nissan_at_a_glance"} />
              </Heading>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={3} md={6} sm={6} xs={12}>
              <span>
                <FormattedMessage id={"page.nissan_glance.headquarters"} />
              </span>
              <h6>
                <FormattedMessage id={"page.common.headquarters_location"} />
              </h6>
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <span>
                <FormattedMessage id={"page.common.since"} />
              </span>
              <h6>
                <FormattedMessage id="page.common.since_date" />
                {/* ( {since_in_years} <FormattedMessage id="page.common.years" />) */}
              </h6>
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <span>
                <FormattedMessage
                  id={"page.nissan_glance.employees_worldwide"}
                />
              </span>
              <h6>
                <FormattedMessage id="page.common.as_of_mar" />
              </h6>
            </Col>
            <Col lg={3} md={6} sm={6} xs={12} className="action-area">
              <AnchorButton href={href} href_jp={href_jp} external={external}>
                <FormattedMessage id={name} />
              </AnchorButton>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default NissanAtGlance;
