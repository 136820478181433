import experienced_hires from "../../../../assets/images/ameios/discover_experience.webp";
import experienced_hires_banner from "../../../../assets/images/ameios/experience_hires.webp"
import { WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";
import { EUROPE_PROGRAM } from "./_programs";

const nordicsSlugs = [
    'sweden-stockholm',
    'finland-espoo',
    'denmark-copenhagen',
    'norway-hvalstad',
]

const franceSlugs = [
    'france-montigny-le-bretonneux',
    'france-montigny-le-bretonneux-west',
]

const italySlugs = [
    'italy-capena',
]

const hungarySlugs = [
    'hungary-budapest',
]

const ukSlugs = [
    'cranfield',
    'paddington-london',
    'maple-cross-london-uk',
    'sunderland-uk',
    'paddington-london-ncx',
]

const ukraineSlugs = [
    'ukraine-kiev',
]

const germanySlugs = [
    'wesseling'
]

const spainSlugs = [
    'spain-barcelona',
]
const istanbulSlugs = [
    'istanbul',
]
const NORDICS_EUROPE_PROGRAMS = EUROPE_PROGRAM.filter(x => nordicsSlugs.indexOf(x.slug) >= 0);
const FRANCE_EUROPE_PROGRAMS = EUROPE_PROGRAM.filter(x => franceSlugs.indexOf(x.slug) >= 0);
const ITALY_EUROPE_PROGRAMS = EUROPE_PROGRAM.filter(x => italySlugs.indexOf(x.slug) >= 0);
const HUNGARY_EUROPE_PROGRAMS = EUROPE_PROGRAM.filter(x => hungarySlugs.indexOf(x.slug) >= 0);
const UK_EUROPE_PROGRAMS = EUROPE_PROGRAM.filter(x => ukSlugs.indexOf(x.slug) >= 0);
const UKRAINE_EUROPE_PROGRAMS = EUROPE_PROGRAM.filter(x => ukraineSlugs.indexOf(x.slug) >= 0);
const GERMARY_EUROPE_PROGRAMS = EUROPE_PROGRAM.filter(x => germanySlugs.indexOf(x.slug) >= 0);
const SPAIN_EUROPE_PROGRAMS = EUROPE_PROGRAM.filter(x => spainSlugs.indexOf(x.slug) >= 0);
const TURKEY_EUROPE_PROGRAMS = EUROPE_PROGRAM.filter(x => istanbulSlugs.indexOf(x.slug) >= 0);

const NORDICS_EUROPE_LOCATIONS = {
    id: 1,
    name: 'Nordics',
    slug: 'nordics',
    items: NORDICS_EUROPE_PROGRAMS
}
const FRANCE_EUROPE_LOCATIONS = {
    id: 2,
    name: 'France',
    slug: 'france',
    items: FRANCE_EUROPE_PROGRAMS
}
const ITALY_EUROPE_LOCATIONS = {
    id: 3,
    name: 'Italy',
    slug: 'italy',
    items: ITALY_EUROPE_PROGRAMS
}
const HUNGARY_EUROPE_LOCATIONS = {
    id: 4,
    name: 'Hungary',
    slug: 'hungary',
    items: HUNGARY_EUROPE_PROGRAMS
}

const UK_EUROPE_LOCATIONS = {
    id: 5,
    name: 'UK',
    slug: 'uk',
    items: UK_EUROPE_PROGRAMS
}

const UKRAINE_EUROPE_LOCATIONS = {
    id: 6,
    name: 'Ukraine',
    slug: 'ukraine',
    items: UKRAINE_EUROPE_PROGRAMS
}

const GERMARY_EUROPE_LOCATIONS = {
    id: 7,
    name: 'Germany',
    slug: 'germany',
    items: GERMARY_EUROPE_PROGRAMS
}

const SPAIN_EUROPE_LOCATIONS = {
    id: 8,
    name: 'Spain',
    slug: 'spain',
    items: SPAIN_EUROPE_PROGRAMS
}

const TURKEY_EUROPE_LOCATIONS = {
    id: 9,
    name: 'Turkey',
    slug: 'turkey',
    items: TURKEY_EUROPE_PROGRAMS
}

export const EUROPE_EXPERIENCED_HIRES = {
    id: 'experienced-hires',
    card_src: experienced_hires,
    card_title: "page.discover.experienced_hires",
    card_description: "page.discover.experienced_hires_description",
    banner_src: experienced_hires_banner,
    banner_title: "page.discover.experienced_hires",
    banner_description: "Drive the Narrative",
    slug: 'experienced-hires',
    href: '/europe/experienced-hires',
    name: "page.discover.experienced_hires",
    description: "Play a vital role across the organization in all areas of the company. Connect and inspire new colleagues, leverage your experience, strategic thinking and expand your areas of expertise.",
    locations: [
        FRANCE_EUROPE_LOCATIONS,
        GERMARY_EUROPE_LOCATIONS,
        HUNGARY_EUROPE_LOCATIONS,
        ITALY_EUROPE_LOCATIONS,
        NORDICS_EUROPE_LOCATIONS,
        SPAIN_EUROPE_LOCATIONS,
        UK_EUROPE_LOCATIONS,
        UKRAINE_EUROPE_LOCATIONS,
        TURKEY_EUROPE_LOCATIONS
    ],
    work_day_link: `${WORKDAY_EN_PATH}locationCountry=a04ea128f43a42e59b1e6a19e8f0b374&locationCountry=49ab063f422741e2aef271de00efeac8&locationCountry=038b0482bfea403abb61c9bcc3d7eb60&locationCountry=0afb2fa656da42e8bfb6d47bd24a26fa&locationCountry=54c5b6971ffb4bf0b116fe7651ec789a&locationCountry=dcc5b7608d8644b3a93716604e78e995&locationCountry=29247e57dbaf46fb855b224e03170bc7&locationCountry=bd34c524a6a04ae6915f5d96fa086199&locationCountry=9db257f5937e4421b2fac64eec6832f8&locationCountry=9696868b09c64d52a62ee13b052383cc&locationCountry=131d5ac7e3ee4d7b962bdc96e498e412&locationCountry=8cd04a563fd94da7b06857a79faaf815&locationCountry=a4051ef996ac40778d4c79e3f2dedfd2&locationCountry=1c026f3b1b8640d8bdfcb95466663e4d&locationCountry=8a0328effd25491fb8e6a08801f08e94&locationCountry=d07f8ca8625e4345b98a91d0558b872a&locationCountry=2e8c5034deb045d49315417c347472ca&locationCountry=6a800a4736884df5826858d435650f45&locationCountry=c2e3bac5bbbb47b29dfc6e8b56a1586e`
}