import program_experienced_hire from "../../../../assets/images/nml/program_experienced_hire.webp";
import experienced_hires_banner from "../../../../assets/images/nml/experienced_hire_banner.webp";

import { NML_PROGRAM } from "./_programs";
import { BASENAME_PAGE, NML_PAGE, WORKDAY_EN_PATH, WORKDAY_JP_PATH } from "../../../../interfaces/Static/routing_paths";

const GET_NML_PROGRAM = (items: string[]) => {
    return NML_PROGRAM.filter(x => items.indexOf(x.slug) >= 0)
}

const YOKOHAMA_LOCATIONS = {
    id: 1,
    name: 'page.common.location.yokohama',
    slug: 'atsugi',
    items: GET_NML_PROGRAM(["pm_headquarter", "pm_yokohama_plant"])
}

const ATSUGI_LOCATIONS = {
    id: 2,
    name: 'page.common.location.atsugi',
    slug: 'atsugi',
    items: GET_NML_PROGRAM(["pm_nissan_advanced_technology_center", "pm_nissan_technical_center", "pm_global_design_center", "pm_nissan_global_information_system_center"])
}


const TOCHIGI_LOCATIONS = {
    id: 3,
    name: 'page.common.location.tochigi',
    slug: 'tochigi',
    items: GET_NML_PROGRAM(["pm_tochigi_plant"])
}

const YOKOSUKA_LOCATIONS = {
    id: 4,
    name: 'page.common.location.yokosuka',
    slug: 'yokosuka',
    items: GET_NML_PROGRAM(["pm_research_center_oppama", "pm_oppama_plant"])
}

const IWAKI_LOCATIONS = {
    id: 5,
    name: 'page.common.location.iwaki',
    slug: 'iwaki',
    items: GET_NML_PROGRAM(["pm_iwaki_plant"])
}

const ZAMA_LOCATIONS = {
    id: 6,
    name: 'page.common.location.zama',
    slug: 'zama',
    items: GET_NML_PROGRAM(["pm_zama_operation_center"])
}

const SAGAMIHARA_LOCATIONS = {
    id: 7,
    name: 'page.common.location.sagamihara',
    slug: 'sagamihara',
    items: GET_NML_PROGRAM(["pm_sagamihara_parts_center"])
}


const EXPERIENCED_LOCATIONS = [
    YOKOHAMA_LOCATIONS,
    ATSUGI_LOCATIONS,
    TOCHIGI_LOCATIONS,
    YOKOSUKA_LOCATIONS,
    IWAKI_LOCATIONS,
    ZAMA_LOCATIONS,
    SAGAMIHARA_LOCATIONS
]

export const NML_EXPERIENCED_HIRES = {
    id: 'experienced-hires',
    card_src: program_experienced_hire,
    card_title: "page.discover.experienced_hires",
    card_description: "page.nml.experienced_hires_description",
    banner_src: experienced_hires_banner,
    banner_title: "page.discover.experienced_hires",
    banner_description: "page.nml.driver_narrative",
    slug: 'experienced-hires',
    href: `${BASENAME_PAGE + NML_PAGE}/experienced-hires`,
    href_jp: `https://www.nissanmotor.jobs/japan/MC/`,
    external: true,
    name: "page.discover.experienced_hires",
    swapPageTitle: "page.common.locations",
    description: "",
    locations: EXPERIENCED_LOCATIONS,
    work_day_link: `${WORKDAY_EN_PATH}locationCountry=8b705da2becf43cfaccc091da0988ab2`,
    work_day_link_jp: `${WORKDAY_JP_PATH}locationCountry=8b705da2becf43cfaccc091da0988ab2`,
    isTrainingDevelopmentRequired: true,
    isCareerRequired: true,
    isRecruitingRequired: true
}