import smyrna_tn from '../../../../assets/images/americas/smyrna-tn.webp';
import canton_ms from '../../../../assets/images/americas/canton-ms.webp';
import decherd_tn from '../../../../assets/images/americas/decherd-tn.webp';
import farmington_hills_mi from '../../../../assets/images/americas/farmington-hills-mi.webp'
import silicon_valley_ca from '../../../../assets/images/americas/silicon-valley-ca.webp'
import arizona_test_center_stanfield from '../../../../assets/images/americas/arizona-test-center-stanfield.webp'
import franklin_tn from '../../../../assets/images/americas/franklin-tn.webp'
import dallas_tx from '../../../../assets/images/americas/dallas-tx.webp'
import irving_tx from '../../../../assets/images/americas/irving-tx.webp'
import san_diego_ca from '../../../../assets/images/americas/san-diego-ca.webp'
import aguascalientes from '../../../../assets/images/americas/aguascalientes.webp'
import cuernavaca from '../../../../assets/images/americas/cuernavaca.webp'
import san_pedro_totoltepec from '../../../../assets/images/americas/san-pedro-totoltepec.webp'
import resende_rio_de_janeiro_brasil from '../../../../assets/images/americas/resende-rio-de-janeiro-brasil.webp'
import santa_isabel_cordoba_argentina from '../../../../assets/images/americas/santa-isabel-cordoba-argentina.webp';

import kirkland_quebec from '../../../../assets/images/americas/kirkland-quebec.webp';
import brampton_ontario from '../../../../assets/images/americas/brampton-ontario.webp';
import mississauga_ontario from '../../../../assets/images/americas/mississauga-ontario.webp';

export const AMERICAS_PROGRAMS = [{
    id: 1,
    name: 'Smyrna, TN',
    slug: 'smyrna-tn',
    description: 'Located 30 minutes south of the music city, Smyrna, TN is a thriving city with small town charm and a warming sense of community. Recently named one of the best places to live for families and one of the best places to retire, it offers a diverse population with top-rated public schools. Smyrna has the hustle and bustle of strong economic growth, but still offers beautiful green spaces and outdoor activities with its close proximity to Percy Priest Lake.  It is definitely a community that offers a wealth of opportunities.',
    src: smyrna_tn,
    href: '/'
}, {
    id: 2,
    name: 'Canton, MS',
    slug: 'canton-ms',
    description: "Canton, MS is a charming town that offers a true southern culinary scene, and its residents take pride in their history and heritage.  Not far is also Madison, MS that offers a variety of shops and historical character. The nearby Natchez Trace Parkway allows residents to explore the natural beauty of the south with great outdoor activities. The area blends a sense of community with a slower pace of life anyone can enjoy with access to an International Airport and a three hour drive to the beach.",
    src: canton_ms,
    href: '/'
}, {
    id: 3,
    name: 'Decherd, TN',
    slug: 'decherd-tn',
    description: "Decherd offers a peaceful, small-town atmosphere while being conveniently situated near larger cities like Chattanooga and Nashville. It is a hidden gem for those seeking a relaxed pace of life with access to the natural wonders of Tennessee.",
    src: decherd_tn,
    href: '/'
}, {
    id: 4,
    name: 'Farmington Hills, MI',
    slug: 'farmington-hills-mi',
    description: "At the core of Farmington Hills' automotive prominence lies the Nissan Technical Center. Here, cutting-edge technologies are developed and tested, shaping the future of transportation. Being in close proximity to this hub of innovation means you can be part of groundbreaking projects and advancements in automotive engineering. Farmington Hills is a city that celebrates its automotive heritage while propelling itself into a future defined by groundbreaking technology. Join the ranks of automotive visionaries and enthusiasts in Farmington Hills and be part of the driving force shaping the future of transportation.",
    src: farmington_hills_mi,
    href: '/'
}, {
    id: 5,
    name: 'Silicon Valley, CA',
    slug: 'silicon-valley-ca',
    description: "Nestled at the heart of Silicon Valley, is the Nissan Advanced Technology Center.  Silicon Valley is a thriving hub of innovation, technology, and limitless possibilities,  It's not just a city; it's a state of mind, a community of dreamers, and a destination where innovation and opportunity converge.",
    src: silicon_valley_ca,
    href: '/'
}, {
    id: 6,
    name: 'Arizona Test Center, Stanfield',
    slug: 'arizona-test-center-stanfield',
    description: "Stanfield, Arizona, is a hidden gem, offering the best of small-town living with easy access to Phoenix and Tucson. With its rich history, friendly community, and stunning desert landscapes, it's a unique destination worth exploring. Stanfield's rich agricultural heritage and access to the stunning Sonoran Desert also provide unique experiences and opportunities you won't find elsewhere.",
    src: arizona_test_center_stanfield,
    href: '/'
}, {
    id: 7,
    name: 'Franklin, TN',
    slug: 'franklin-tn',
    description: "Situated a short drive from Nashville, you'll find the ideal blend of city life and Southern culture at our North America Headquarters in Franklin, Tennessee. Enjoy the energy of Nashville's entertainment scene, while savoring the rich history and hospitality of this charming suburb, all within easy reach of the office.",
    src: franklin_tn,
    href: '/'
}, {
    id: 8,
    name: 'Dallas, TX',
    slug: 'dallas-tx',
    description: "Just north of city center and accessible from all parts of the Dallas-Fort Worth region is Addison, an area is known as a great place for residents in Texas seeking an urban feel. The area is filled with restaurants and shops, making it a convenient place to work and live.",
    src: dallas_tx,
    href: '/'
}, {
    id: 9,
    name: 'Irving, TX',
    slug: 'irving-tx',
    description: "Located on the northwestern corner of the Dallas area known as an industrial hub and home to many Fortune 500 companies, Universities as well as the Las Colinas Entertainment District, Irving is a place where you'll find unique things to do on the daily. Nissan's PDC (Parts Distribution Center) is located in the heart of it all..",
    src: irving_tx,
    href: '/'
}, {
    id: 10,
    name: 'San Diego, CA',
    slug: 'san-diego-ca',
    description: "The San Diego area is home to Nissan's Design Center. Here you'll find our team working on product design concepts all driving towards the mission of enriching other's lives. The area boasts a Mediterranean climate with an abundance of outdoor, beach and cultural arts activities and a diverse culture.",
    src: san_diego_ca,
    href: '/'
},{
    id: 11,
    name: 'Aguascalientes',
    slug: 'aguascalientes',
    description: "The Aguascalientes region includes three manufacturing facilities with over 14,000 Nissan employees manufacturing vehicles exported to more than 80 countries such as Canada, the United States, Brazil, Colombia, Chile and Saudi Arabia, among others.",
    src: aguascalientes,
    href: '/'
}, {
    id: 12,
    name: 'Cuernavaca',
    slug: 'cuernavaca',
    description: "Nissan's Cuernevaca plant, known as CIVAC, established the beginning of a longstanding tradition in innovation, research, commitment and the highest quality standards in vehicle manufacturing that continually contribute to the excellence the brand delivers. It was the first plant for Nissan established outside of Japan and has existed for over 55 years.",
    src: cuernavaca,
    href: '/'
},
{
    id:13,
    name:'San Pedro Totoltepec',
    slug:'san-pedro-totoltepec',
    description:"Toluca serves as the vibrant hub for our regional technical design center, where visionary projects for the area are born. Dive into a work environment with fresh ideas and relentless innovation. Additionally, this locale plays a pivotal role in regional procurement and post-sales endeavors, offering an exciting array of opportunities to thrive and grow professionally.",
    src:san_pedro_totoltepec,
    href:'/'
},
{
    id: 14,
    name: 'Resende / Rio de Janeiro, Brazil',
    slug: 'resende-rio-de-janeiro-brasil',
    description: "Nissan's Resende Industrial Complex, in Rio de Janeiro State in Brazil, manufactures engines and vehicles for export across multiple regions. The facility houses a comprehensive production process with its own body shop, paint shop, plastic injection molding shop, assembly shop, and quality inspection area – in addition to the powertrain plant and test track.",
    src: resende_rio_de_janeiro_brasil,
    href: '/'
}, {
    id: 15,
    name: 'Santa Isabel (Cordoba), Argentina',
    slug: 'santa-isabel-cordoba-argentina',
    description: "Nissan has continued expanding its presence in South America, as a manufacturer and exporter. The Santa Isabel Cordoba facility is part of the Renault industrial complex in Cordoba with manufacturing through Alliance partners Renault and Nissan.",
    src: santa_isabel_cordoba_argentina,
    href: '/'
}, {
    id: 16,
    name: 'Brampton, Ontario',
    slug: 'brampton-ontario',
    description: "Brampton stands out as a city with a vibrant cultural mosaic, nurturing a close-knit community and fostering a strong spirit of inclusivity. This dynamic backdrop is where our Ontario PDC (Parts Distribution Center) is situated, serving as a pivotal hub that bolsters our presence in supporting dealerships and clients across Canada. Our presence here reflects our commitment to diversity and excellence in every aspect of our operations.",
    src: brampton_ontario,
    href: '/'
}, {
    id: 17,
    name: 'Kirkland, Quebec',
    slug: 'kirkland-quebec',
    description: "Located within the vibrant city of Kirkland, Quebec, our Parts Distribution Center (PDC) flourishes in the heart of French Canada's rich culture and innovation. With a prime location in this dynamic Quebecois city, our Kirkland PDC not only ensures efficient service but also proudly reflects our commitment to being an integral part of the thriving community, delivering top-tier service to clients across the eastern region.",
    src: kirkland_quebec,
    href: '/'
}, {
    id: 18,
    name: 'Mississauga, Ontario',
    slug: 'mississauga-ontario',
    description: 'Mississauga, a diverse city in Ontario, Canada, harmoniously combines urban vibrancy with natural beauty. Its bustling districts, scenic waterfront, and vibrant cultural scene make it a unique and inviting destination. The Nissan office stands as a symbol of innovation and style. With a sleek, glass-walled building featuring images of our dynamic SUVs, it embodies the spirit of "Thrill Lives Here."',
    src: mississauga_ontario,
    href: '/'
}
]