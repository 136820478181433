import experienced_hires from "../../../../assets/images/americas/discover_experience.webp";
import { AMERICAS_PROGRAMS } from "./_programs";
import Experience_Hire from "../../../../assets/images/americas/experiences_hire.webp";
import { WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";


const northAmericaSlugs = [
    "franklin-tn",
    "dallas-tx",
    "smyrna-tn",
    "decherd-tn",
    "canton-ms",
];
const mexicoSlugs = [
    "aguascalientes",
    "cuernavaca",
    "san-pedro-totoltepec"
];;
const southAmericaSlugs = [
    "resende-rio-de-janeiro-brasil",
    "santa-isabel-cordoba-argentina"
];

const canadaSlugs = [
    "brampton-ontario",
    "kirkland-quebec",
    "mississauga-ontario"
];

const NORTH_AMERICA_PROGRAMS = AMERICAS_PROGRAMS.filter(x => northAmericaSlugs.indexOf(x.slug) >= 0);

const MEXICO_PROGRAMS = AMERICAS_PROGRAMS.filter(x => mexicoSlugs.indexOf(x.slug) >= 0);

const SOUTH_AMERICA_PROGRAMS = AMERICAS_PROGRAMS.filter(x => southAmericaSlugs.indexOf(x.slug) >= 0);

const CANADA_PROGRAMS = AMERICAS_PROGRAMS.filter(x => canadaSlugs.indexOf(x.slug) >= 0);

const NORTH_AMERICA_LOCATIONS = {
    id: 1,
    name: 'North America',
    slug: 'north-america',
    items: NORTH_AMERICA_PROGRAMS
}

const MEXICO_LOCATIONS = {
    id: 2,
    name: 'Mexico',
    slug: 'mexico',
    items: MEXICO_PROGRAMS
}

const SOUTH_AMERICA_LOCATIONS = {
    id: 3,
    name: 'South America',
    slug: 'south-america',
    items: SOUTH_AMERICA_PROGRAMS
}

const CANADA_LOCATIONS = {
    id: 4,
    name: 'Canada',
    slug: 'canada',
    items: CANADA_PROGRAMS
}

export const AMERICAS_EXPERIENCED_HIRES = {
    id: 'experienced-hires',
    card_src: experienced_hires,
    card_title: "page.discover.experienced_hires",
    card_description: "page.discover.experienced_hires_description",
    banner_src:Experience_Hire,
    banner_title: "page.discover.experienced_hires",
    banner_description: "Drive the Narrative",
    slug: 'experienced-hires',
    href: '/americas/experienced-hires',
    name: "page.discover.experienced_hires",
    description: "Play a vital role across the organization in all areas of the company. Connect and inspire new colleagues, leverage your experience, strategic thinking and expand your areas of expertise.",
    locations: [
        NORTH_AMERICA_LOCATIONS,
        MEXICO_LOCATIONS,
        SOUTH_AMERICA_LOCATIONS,
        CANADA_LOCATIONS
    ],
    work_day_link: `${WORKDAY_EN_PATH}locationCountry=a30a87ed25634629aa6c3958aa2b91ea&locationCountry=e2adff9272454660ac4fdb56fc70bb51&locationCountry=bc33aa3152ec42d4995f4791a106ed09&locationCountry=e42ad5eac46d4cc9b367ceaef42577c5&locationCountry=1a29bb1357b240ab99a2fa755cc87c0e&locationCountry=53fe09ef12b9408682a1d2439823f2e0&locationCountry=0eb156ca580c4db786a7894bdaa77450`
}