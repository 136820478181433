import { FC } from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import SimpleImage from "../../Shared/Media/SimpleImage";

import GreatPlaceToWorkImage from "../../../assets/images/nissan-gptw-logo.svg";
import GreatPlaceToWorkUsa from "../../../assets/images/gptw_usa.webp";
import GreatPlaceToWorkPeru from "../../../assets/images/gptw_peru.webp";
import GreatPlaceToWorkArgentina from "../../../assets/images/gptw_arg.webp";
import GreatPlaceToWorkItaly from "../../../assets/images/gptw_italia.webp";
import GreatPlaceToWorkUae from "../../../assets/images/gptw_uae.webp";
import GreatPlaceToWorkChile from "../../../assets/images/gptw_chile.webp";
import GreatPlaceToWorkBrazil from "../../../assets/images/gptw_brasil.webp";
import GreatPlaceToWorkKsa from "../../../assets/images/gptw_ksa.webp";

const badges = [{
    id: 1,
    src: GreatPlaceToWorkArgentina
}, {
    id: 2,
    src: GreatPlaceToWorkBrazil
}, {
    id: 3,
    src: GreatPlaceToWorkChile
}, {
    id: 4,
    src: GreatPlaceToWorkItaly
}, {
    id: 5,
    src: GreatPlaceToWorkPeru
}, {
    id: 6,
    src: GreatPlaceToWorkUae
}, {
    id: 7,
    src: GreatPlaceToWorkUsa
}, {
    id: 8,
    src: GreatPlaceToWorkKsa
}]

const GreatPlaceToWork: FC = () => {
    return (
        <div className="great-place-to-work">
            <div className="container p-0">
                <div className="row no-gutters">
                    <div className="col-12 col-md-6">
                        <div className="nissan-logo-sec">
                            <div className="nissan-logo-img">
                                <SimpleImage src={GreatPlaceToWorkImage} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 certificate-slide">
                        <Splide
                            options={{
                                arrows: false,
                                pagination: false,
                                width: "100%",
                                perPage: 1,
                                perMove: 1,
                                rewind: true,
                                autoplay: true,
                                pauseOnHover: false,
                                resetProgress: false,
                                interval: 6000
                            }}
                            aria-labelledby={"GreatPlaceToWorkImage-sections"}
                        >
                            {
                                badges.map(badge => {
                                    return (
                                        <SplideSlide key={badge.id}>
                                            <SimpleImage src={badge.src} />
                                        </SplideSlide>
                                    )
                                })
                            }
                        </Splide>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default GreatPlaceToWork;
