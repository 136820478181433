import { FC } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { RECRUITMENT_SCAM_PAGE, SITEMAP_PAGE } from "../../../interfaces/Static/routing_paths";
import { Anchor } from "../../Shared/Common/Anchor";
const items = [
  {
    id: 2,
    link: "https://www.nissan-global.com/EN/",
    jpLink: "https://www.nissan-global.com/JP/",
    reactIntl: "page.common.nissan_worldwide",
    external: true,
  },
  {
    id: 3,
    link: SITEMAP_PAGE,
    jpLink: SITEMAP_PAGE,
    reactIntl: "page.common.sitemap",
    external: false,
  },
  {
    id: 4,
    link: "https://www.nissan-global.com/EN/ABOUT/",
    jpLink: "https://www.nissan-global.com/JP/ABOUT/",
    reactIntl: "page.common.terms_of_use",
    external: true,
  },
  {
    id: 5,
    link: "https://www.nissan-global.com/EN/PRIVACY/",
    jpLink: "https://www.nissan-global.com/JP/PRIVACY/",
    reactIntl: "page.common.privacy_policy",
    external: true,
  },
  {
    id: 5,
    link: "https://www.nissan-global.com/EN/COOKIES/",
    jpLink: "https://www.nissan-global.com/JP/COOKIES/",
    reactIntl: "page.common.handling_information",
    external: true,
  },
  {
    id: 6,
    link: RECRUITMENT_SCAM_PAGE,
    jpLink: RECRUITMENT_SCAM_PAGE,
    reactIntl: "page.common.recruitement_hiring",
    external: false,
  }
];
const FooterBottom: FC = () => {
  return (
    <Container>
      <Row>
        <Col xs={12} className="bottom-footer">
          {items.map((item, index) => {
            return (
              <Anchor
                external={item.external}
                key={index}
                href={item.link}
                href_jp={item.jpLink}
              >
                <FormattedMessage id={item.reactIntl} />
              </Anchor>
            );
          })}
        </Col>
      </Row>
    </Container>
  );
};

export default FooterBottom;
