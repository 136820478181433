import { FC } from "react";
import { Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const SiteMapBanner: FC = () => {
  return (
    <section className="banner sitemap">
      <Container>
        <div className="caption">
          <h1>
            <FormattedMessage id="page.sitemap.banner.title" />
          </h1>
        </div>
      </Container>
    </section>
  );
};
export default SiteMapBanner;
