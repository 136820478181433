import early_hires from "../../../../assets/images/americas/discover_early.webp";
import Early_Hire from "../../../../assets/images/americas/early_hires.webp";
import { WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";

import { AMERICAS_PROGRAMS } from "./_programs";

const northAmericaSlugs_Internship = [
    "franklin-tn",
    "smyrna-tn",
    "decherd-tn",
    "canton-ms",
    "farmington-hills-mi",
    "silicon-valley-ca",
    "san-diego-ca",
    "irving-tx",
];

const northAmericaSlugs_Mba_Internship = [
    "franklin-tn",
    "smyrna-tn",
    "canton-ms",
    "farmington-hills-mi",
];

const northAmericaSlugs_Rotational_Program = [
    "franklin-tn",
    "smyrna-tn",
    "canton-ms",
    "farmington-hills-mi",
];

const mexicoSlugs = [
    "aguascalientes",
    "cuernavaca",
    "san-pedro-totoltepec"
];;
const southAmericaSlugs = [
    "resende-rio-de-janeiro-brasil",
    "santa-isabel-cordoba-argentina"
];

const canada_rotational_slugs = [
    'mississauga-ontario',
    "brampton-ontario",
]

const NORTH_AMERICA_PROGRAMS_MBA_INTERNSHIPS = AMERICAS_PROGRAMS.filter(x => northAmericaSlugs_Mba_Internship.indexOf(x.slug) >= 0);

const NORTH_AMERICA_INTERNSHIP_PROGRAMS = AMERICAS_PROGRAMS.filter(x => northAmericaSlugs_Internship.indexOf(x.slug) >= 0);

const NORTH_AMERICA_ROTATIONAL_PROGRAMS = AMERICAS_PROGRAMS.filter(x => northAmericaSlugs_Rotational_Program.indexOf(x.slug) >= 0);

const CANADA_ROTATIONAL_PROGRAMS = AMERICAS_PROGRAMS.filter(x => canada_rotational_slugs.indexOf(x.slug) >= 0);

const MEXICO_PROGRAMS = AMERICAS_PROGRAMS.filter(x => mexicoSlugs.indexOf(x.slug) >= 0);

const SOUTH_AMERICA_PROGRAMS = AMERICAS_PROGRAMS.filter(x => southAmericaSlugs.indexOf(x.slug) >= 0);

const NORTH_AMERICA_LOCATIONS_INTERNSHIP = {
    id: 1,
    name: 'North America',
    slug: 'north-america',
    items: NORTH_AMERICA_INTERNSHIP_PROGRAMS
}

const NORTH_AMERICA_ROTATIONAL_INTERNSHIP = {
    id: 1,
    name: 'North America',
    slug: 'north-america',
    items: NORTH_AMERICA_ROTATIONAL_PROGRAMS
}

const NORTH_AMERICA_LOCATIONS = {
    id: 1,
    name: 'North America',
    slug: 'north-america',
    items: NORTH_AMERICA_PROGRAMS_MBA_INTERNSHIPS
}

const MEXICO_LOCATIONS = {
    id: 2,
    name: 'Mexico',
    slug: 'mexico',
    items: MEXICO_PROGRAMS
}

const SOUTH_AMERICA_LOCATIONS = {
    id: 3,
    name: 'South America',
    slug: 'south-america',
    items: SOUTH_AMERICA_PROGRAMS
}

const CANADA_LOCATIONS = {
    id: 5,
    name: 'Canada',
    slug: 'canada',
    items: CANADA_ROTATIONAL_PROGRAMS
}


export const AMERICAS_EARLY_HIRES = {
    id: 'early-careers',
    card_src: early_hires,
    card_title: "page.discover.early_careers",
    card_description: "page.discover.early_careers_description",
    banner_src:Early_Hire,
    banner_title: "page.discover.early_careers",
    banner_description: "Embark on a Thrilling Journey",
    slug: 'early-careers',
    href: '/americas/early-careers',
    name: "page.discover.early_careers",
    description: "Immerse yourself in the world of mobility. Expand the boundaries of possibility and chart your own path at Nissan through our internships, MBA, and campus connection programs & events.",
    categories: [{
        id: 1,
        name: 'Internships',
        slug: '',
        description: "Nissan offers many internship avenues accross the organization. Whether you are interested in how electric vehicles are made, looking to gain experience in supply chain, or want a peek into our robust marketing campaigns, there is something for everyone. Our interns enjoy competitive pay and benefits including the option for a company vehicle* during the duration of your internship. (*Pending passing company car requirements.)",
        locations: [
            NORTH_AMERICA_LOCATIONS_INTERNSHIP,
            MEXICO_LOCATIONS,
            SOUTH_AMERICA_LOCATIONS,
            CANADA_LOCATIONS
        ]
    }, {
        id: 2,
        name: 'MBA Rotational Program',
        slug: '',
        description: "Nissan's MBA Rotational Program is made up of a select group of outstanding graduates. This is a 3-year program aimed to prime executive level talent to support future Nissan growth. Associates are brought in at a Manager level and will participate in various areas of the business providing a strategic pathway for talent development in the organization.",
        locations: [
            NORTH_AMERICA_ROTATIONAL_INTERNSHIP,
            MEXICO_LOCATIONS,
            SOUTH_AMERICA_LOCATIONS,
            CANADA_LOCATIONS
        ]
    }, {
        id: 3,
        name: 'MBA Internship Program',
        slug: '',
        description: "The MBA Internship Program is designed for MBA candidates looking for specialized projects in key business areas of focus. These interns will also be highly considered for the MBA Graduate Rotational Program upon graduation.",
        locations: [
            NORTH_AMERICA_LOCATIONS,
            MEXICO_LOCATIONS,
            SOUTH_AMERICA_LOCATIONS
        ]
    }],
    work_day_link: `${WORKDAY_EN_PATH}locationCountry=a30a87ed25634629aa6c3958aa2b91ea&locationCountry=e2adff9272454660ac4fdb56fc70bb51&locationCountry=bc33aa3152ec42d4995f4791a106ed09&locationCountry=e42ad5eac46d4cc9b367ceaef42577c5&locationCountry=1a29bb1357b240ab99a2fa755cc87c0e&locationCountry=53fe09ef12b9408682a1d2439823f2e0&locationCountry=0eb156ca580c4db786a7894bdaa77450`
}