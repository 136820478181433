import { FC, useState, useEffect } from "react";
import { Row, Dropdown, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";


import CorporateAreaCard from "./_card";
import { CORPORATE_AREA_ITEMS } from "./_items";
import { Anchor } from "../../Shared/Common/Anchor";

interface Program {
    id: number;
    src: string;
    title: string;
    src_detail: string;
    description: string;
    href: string;
}
const CorporateAreaMobile: FC = () => {
    const [programId, setProgramId] = useState<number>(1);
    const [programs, setPrograms] = useState<Program>();

    const onSelect = (eventKey: any, event: Object) => {
        setProgramId(Number(eventKey));
    };

    useEffect(() => {
        if (programId)
            setPrograms(CORPORATE_AREA_ITEMS.find((item) => item.id === programId));
    }, [programId]);

    return (
        <Row>
            <Dropdown onSelect={onSelect}>
                <Dropdown.Toggle id="dropdown-basic">
                    {programs && <FormattedMessage id={programs.title} />}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {CORPORATE_AREA_ITEMS.map((item, index) => {
                        return (
                            <Dropdown.Item key={item.id} eventKey={item.id}>
                                <FormattedMessage id={item.title} />
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
            {programs && (
                <Col lg={4} sm={12}>
                    <Anchor href={programs.href} external={true}>
                        <CorporateAreaCard  {...programs} isDetailPage={false} />
                    </Anchor>
                </Col>
            )}
        </Row>
    );
};
export default CorporateAreaMobile;
