import manufacturing from "../../../../assets/images/ameios/discover_manufacturing.png";
import manufacutring_banner from "../../../../assets/images/ameios/manufacturing.webp";
import { WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";
import { EUROPE_PROGRAM } from "./_programs";

const netherlandSlug =[
    'amsterdam-netherlands',
]

const spainSlugs =[
    'cantabria-spain',
    'avila-spain',
    'el-prat-de-llobregat-spain',
]

const ukSlugs =[
    'sunderland-uk',
    'lutterworth-uk',
]

const NETHERLAND_EUROPE_PROGRAM =EUROPE_PROGRAM.filter(x=>netherlandSlug.indexOf(x.slug)>=0);

const SPAIN_EUROPE_PROGRAMS=EUROPE_PROGRAM.filter(x => spainSlugs.indexOf(x.slug) >= 0);

const UK_EUROPE_PROGRAMS=EUROPE_PROGRAM.filter(x => ukSlugs.indexOf(x.slug) >= 0);


const NETHERLAND_EUROPE_LOCATIONS={
    id:1,
    name:'Netherland',
    slug:'netherland',
    items:NETHERLAND_EUROPE_PROGRAM
}

const SPAIN_EUROPE_LOCATIONS={
    id:2,
    name:'Spain',
    slug:'spain',
    items:SPAIN_EUROPE_PROGRAMS
}

const UK_EUROPE_LOCATIONS={
    id:3,
    name:'UK',
    slug:'uk',
    items:UK_EUROPE_PROGRAMS
}

export const EUROPE_MANUFACTURING_PAGES = {
    id: 'manufacturing',
    card_src: manufacturing,
    card_title: "page.discover.manufacturing",
    card_description: "page.discover.manufacturing_description",
    banner_src:manufacutring_banner,
    banner_title: "page.discover.manufacturing_mastery",
    banner_description: "Craft & Assemble Vehicles",
    slug: 'manufacturing',
    href: '/europe/manufacturing',
    name: "page.discover.manufacturing",
    description: "Step onto the production floor where teamwork, precision and craftsmanship meet. Contribute to the creation of your favorite vehicles through advanced manufacturing processes, automation, and quality control, ensuring every detail exceeds expectations.",
    locations: [
        NETHERLAND_EUROPE_LOCATIONS,
        SPAIN_EUROPE_LOCATIONS,
        UK_EUROPE_LOCATIONS
    ],
    work_day_link: `${WORKDAY_EN_PATH}locationCountry=a04ea128f43a42e59b1e6a19e8f0b374&locationCountry=49ab063f422741e2aef271de00efeac8&locationCountry=038b0482bfea403abb61c9bcc3d7eb60&locationCountry=0afb2fa656da42e8bfb6d47bd24a26fa&locationCountry=54c5b6971ffb4bf0b116fe7651ec789a&locationCountry=dcc5b7608d8644b3a93716604e78e995&locationCountry=29247e57dbaf46fb855b224e03170bc7&locationCountry=bd34c524a6a04ae6915f5d96fa086199&locationCountry=9db257f5937e4421b2fac64eec6832f8&locationCountry=9696868b09c64d52a62ee13b052383cc&locationCountry=131d5ac7e3ee4d7b962bdc96e498e412&locationCountry=8cd04a563fd94da7b06857a79faaf815&locationCountry=a4051ef996ac40778d4c79e3f2dedfd2&locationCountry=1c026f3b1b8640d8bdfcb95466663e4d&locationCountry=8a0328effd25491fb8e6a08801f08e94&locationCountry=d07f8ca8625e4345b98a91d0558b872a&locationCountry=2e8c5034deb045d49315417c347472ca&locationCountry=6a800a4736884df5826858d435650f45&locationCountry=c2e3bac5bbbb47b29dfc6e8b56a1586e`
}