import { FC } from "react";
import { Col, Container, Row } from 'react-bootstrap';

import { NML_PAGE } from "../../../interfaces/Static/routing_paths";
import Heading from "../../Shared/Common/Heading";
import { Anchor } from "../../Shared/Common/Anchor";
import SimpleImage from "../../Shared/Media/SimpleImage";

import { FormattedMessage } from "react-intl";
import { NML_SEARCHES } from "../../../Config/Static/NmlJobSearch";
import NmlLinkJobSearch from "./_links";
import TimetoApply from "../../Shared/RegionalPages/TimetoApply";
import { AnchorButton } from "../../Shared/Common/Anchor/Button";
interface Props {
    pageId: string;
}

const NmlJobSearch: FC<Props> = ({ pageId }) => {
    const pageData = NML_SEARCHES.find(x => x.key === pageId);
    if (!pageData)
        return null;

    return (
        <>
            <section className="banner americas inner">
                <SimpleImage src={pageData.banner_src} />
                <div className="container">
                    <div className="banner-caption">
                        <h1>
                            <FormattedMessage id={pageData.banner_title} />
                        </h1>
                        <div className="sub-text">
                            <FormattedMessage id={pageData.banner_description} />
                        </div>
                    </div>
                </div>
            </section>
            <div className="job-research-area-container">
                <Container>
                    <div className=" job-research-area">
                        <Row className="justify-content-center align-items-center">
                            <Col lg={8}>

                                <Heading type="section-heading">
                                    <FormattedMessage id="page.common.join_our_talent_community" />
                                </Heading>
                                <p className="mt-1">
                                    <FormattedMessage id="page.common.join_our_talent_community_description" />
                                </p>
                            </Col>
                            <Col lg={4} className="d-flex justify-content-lg-end align-items-center">
                                <div>
                                    <AnchorButton
                                        href={
                                            "https://career-user.blm.co.jp/registration/nissan"
                                        }
                                        variant={"secondary"}
                                        ewhite={true}
                                        external={true}
                                    >
                                        <FormattedMessage id="page.common.talent_community" />
                                    </AnchorButton>
                                </div>
                            </Col>
                        </Row>



                    </div>

                </Container>
            </div>
            <Container className="mb-3 pb-3">
                <div className="breadcrumb-container">
                    <Anchor href={NML_PAGE}>
                        <FormattedMessage id={"page.common.home"} />
                    </Anchor>
                    <Anchor href={`${NML_PAGE}/experienced-hires`}>
                        <FormattedMessage id={"page.discover.experienced_hires"} />
                    </Anchor>
                    <Anchor href="#">
                        <FormattedMessage id={pageData.breadCrumbTitle} />
                    </Anchor>
                </div>
                <div className="research-area">
                    <div>
                        <Heading type="section-heading">
                            <FormattedMessage id={pageData.name} />
                        </Heading>
                        <p>
                            <FormattedMessage id={pageData.note} />
                        </p>
                    </div>
                </div>
                <div className="jobsearch-area">
                    {
                        pageData.items.map(item => {
                            return <NmlLinkJobSearch item={item} />
                        })
                    }
                </div>
            </Container>
            <TimetoApply />
        </>
    );
};
export default NmlJobSearch;
