import { WORKDAY_EN_PATH, WORKDAY_JP_PATH } from "../../../interfaces/Static/routing_paths";

const USA_JOB_LINK = `${WORKDAY_EN_PATH}locationCountry=8b705da2becf43cfaccc091da0988ab2&q=`;
const NML_JOB_LINK = `${WORKDAY_JP_PATH}locationCountry=8b705da2becf43cfaccc091da0988ab2&q=`;

export const BUSINESS_LINKS = [{
    id: 1,
    name: "page.common.product_planning",
    links: [{
        id: 1,
        name: "page.common.product_planning",
        href: `${USA_JOB_LINK}商品企画`,
        href_jp: `${NML_JOB_LINK}商品企画`,
    }]
}, {
    id: 2,
    name: "page.common.program_directors_office",
    links: [{
        id: 1,
        name: "page.common.connected_cars_automated_driving",
        href: `${USA_JOB_LINK}コネクティドカー＆自動運転`,
        href_jp: `${NML_JOB_LINK}コネクティドカー＆自動運転`,
    }, {
        id: 2,
        name: "page.common.vehicle_revenue_management",
        href: `${USA_JOB_LINK}車両収益マネジメント`,
        href_jp: `${NML_JOB_LINK}車両収益マネジメント`,
    }, {
        id: 3,
        name: "page.common.ev_program",
        href: `${USA_JOB_LINK}EVプログラム`,
        href_jp: `${NML_JOB_LINK}EVプログラム`,
    }]
}, {
    id: 3,
    name: "page.common.management_strategy",
    links: [{
        id: 1,
        name: "page.common.management_strategy",
        href: `${USA_JOB_LINK}経営戦略`,
        href_jp: `${NML_JOB_LINK}経営戦略`,
    }]
}, {
    id: 4,
    name: "page.common.market_intelligence",
    links: [{
        id: 1,
        name: "page.common.market_intelligence",
        href: `${USA_JOB_LINK}マーケット・インテリジェンス`,
        href_jp: `${NML_JOB_LINK}マーケット・インテリジェンス`,
    }]
}, {
    id: 5,
    name: "page.common.purchase",
    links: [{
        id: 1,
        name: "page.common.purchase",
        href: `${USA_JOB_LINK}購買`,
        href_jp: `${NML_JOB_LINK}購買`,
    }]
}, {
    id: 6,
    name: "page.common.production_and_supply_chain_management",
    links: [{
        id: 1,
        name: "page.common.production_control",
        href: `${USA_JOB_LINK}生産管理`,
        href_jp: `${NML_JOB_LINK}生産管理`,
    }, {
        id: 2,
        name: "page.common.supply_chain_management",
        href: `${USA_JOB_LINK}サプライチェーンマネジメント`,
        href_jp: `${NML_JOB_LINK}サプライチェーンマネジメント`,
    }]
}, {
    id: 7,
    name: "page.common.domestic_marketing_sales",
    links: [{
        id: 1,
        name: "page.common.strategy_planning",
        href: `${USA_JOB_LINK}戦略企画`,
        href_jp: `${NML_JOB_LINK}戦略企画`,
    }, {
        id: 2,
        name: "page.common.marketing",
        href: `${USA_JOB_LINK}マーケティング`,
        href_jp: `${NML_JOB_LINK}マーケティング`,
    }, {
        id: 3,
        name: "page.common.sales",
        href: `${USA_JOB_LINK}営業`,
        href_jp: `${NML_JOB_LINK}営業`,
    }, {
        id: 4,
        name: "page.common.network_strategy",
        href: `${USA_JOB_LINK}ネットワーク戦略`,
        href_jp: `${NML_JOB_LINK}ネットワーク戦略`,
    }, {
        id: 5,
        name: "page.common.fleet_business",
        href: `${USA_JOB_LINK}フリート事業`,
        href_jp: `${NML_JOB_LINK}フリート事業`,
    }, {
        id: 6,
        name: "page.common.light_commercial_vehicle",
        href: `${USA_JOB_LINK}LCV(商用車)`,
        href_jp: `${NML_JOB_LINK}LCV(商用車)`,
    }, {
        id: 7,
        name: "page.common.after_sales_service",
        href: `${USA_JOB_LINK}アフターセールス`,
        href_jp: `${NML_JOB_LINK}アフターセールス`,
    }, {
        id: 8,
        name: "page.common.digital_transformation",
        href: `${USA_JOB_LINK}デジタルトランスフォーメーション`,
        href_jp: `${NML_JOB_LINK}デジタルトランスフォーメーション`,
    }, {
        id: 9,
        name: "page.common.medieval_car",
        href: `${USA_JOB_LINK}中古車`,
        href_jp: `${NML_JOB_LINK}中古車`,
    }]
}, {
    id: 8,
    name: "page.common.global_marketing_sales",
    links: [{
        id: 1,
        name: "page.common.global_marketing_sales",
        href: `${USA_JOB_LINK}グローバルマーケティング&セールス`,
        href_jp: `${NML_JOB_LINK}グローバルマーケティング&セールス`,
    }, {
        id: 2,
        name: "page.common.global_distribution_strategy",
        href: `${USA_JOB_LINK}グローバルディストリビューション戦略`,
        href_jp: `${NML_JOB_LINK}グローバルディストリビューション戦略`,
    }, {
        id: 3,
        name: "page.common.connected_car_service_strategy",
        href: `${USA_JOB_LINK}コネクテッドカーサービス戦略`,
        href_jp: `${NML_JOB_LINK}コネクテッドカーサービス戦略`,
    }, {
        id: 4,
        name: "page.common.global_sales",
        href: `${USA_JOB_LINK}グローバルセールス`,
        href_jp: `${NML_JOB_LINK}グローバルセールス`,
    }, {
        id: 5,
        name: "page.common.customer_experience",
        href: `${USA_JOB_LINK}カスタマーエクスペリエンス`,
        href_jp: `${NML_JOB_LINK}カスタマーエクスペリエンス`,
    }, {
        id: 6,
        name: "page.common.brand_marketing",
        href: `${USA_JOB_LINK}ブランドマーケティング`,
        href_jp: `${NML_JOB_LINK}ブランドマーケティング`,
    }, {
        id: 7,
        name: "page.common.product_marketing",
        href: `${USA_JOB_LINK}プロダクトマーケティング`,
        href_jp: `${NML_JOB_LINK}プロダクトマーケティング`,
    }]
}, {
    id: 9,
    name: "page.common.infiniti",
    links: [{
        id: 1,
        name: "page.common.infiniti",
        href: `${USA_JOB_LINK}INFINITI`,
        href_jp: `${NML_JOB_LINK}INFINITI`,
    }]
}, {
    id: 10,
    name: "page.common.accounting_and_finance",
    links: [{
        id: 1,
        name: "page.common.investment_management_of_new_vehicle_equipment",
        href: `${USA_JOB_LINK}新車設備投資管理`,
        href_jp: `${NML_JOB_LINK}新車設備投資管理`,
    }, {
        id: 2,
        name: "page.common.alliance_costing",
        href: `${USA_JOB_LINK}グローバルコスティング`,
        href_jp: `${NML_JOB_LINK}グローバルコスティング`,
    }, {
        id: 3,
        name: "page.common.financial_accounting",
        href: `${USA_JOB_LINK}財務会計（制度）`,
        href_jp: `${NML_JOB_LINK}財務会計（制度）`,
    }, {
        id: 4,
        name: "page.common.managerial_accounting",
        href: `${USA_JOB_LINK}管理会計`,
        href_jp: `${NML_JOB_LINK}管理会計`,
    }, {
        id: 5,
        name: "page.common.financial_affairs",
        href: `${USA_JOB_LINK}財務`,
        href_jp: `${NML_JOB_LINK}財務`,
    }, {
        id: 6,
        name: "page.common.sales_finance",
        href: `${USA_JOB_LINK}販売金融`,
        href_jp: `${NML_JOB_LINK}販売金融`,
    }, {
        id: 7,
        name: "page.common.m_a",
        href: `${USA_JOB_LINK}M&A`,
        href_jp: `${NML_JOB_LINK}M&A`,
    }]
}, {
    id: 11,
    name: "page.common.human_resources",
    links: [{
        id: 1,
        name: "page.common.hrbp",
        href: `${USA_JOB_LINK}HRBP`,
        href_jp: `${NML_JOB_LINK}HRBP`,
    }, {
        id: 2,
        name: "page.common.digital_hr",
        href: `${USA_JOB_LINK}Digital HR`,
        href_jp: `${NML_JOB_LINK}Digital HR`,
    }, {
        id: 3,
        name: "page.common.global_hr",
        href: `${USA_JOB_LINK}グローバル人事`,
        href_jp: `${NML_JOB_LINK}グローバル人事`,
    }, {
        id: 4,
        name: "page.common.talent_management",
        href: `${USA_JOB_LINK}タレントマネジメント`,
        href_jp: `${NML_JOB_LINK}タレントマネジメント`,
    }, {
        id: 5,
        name: "page.common.diversity",
        href: `${USA_JOB_LINK}ダイバーシティ`,
        href_jp: `${NML_JOB_LINK}ダイバーシティ`,
    }, {
        id: 6,
        name: "page.common.recruiting",
        href: `${USA_JOB_LINK}採用`,
        href_jp: `${NML_JOB_LINK}採用`,
    }, {
        id: 7,
        name: "page.common.human_resource_development_and_training",
        href: `${USA_JOB_LINK}人財開発・研修`,
        href_jp: `${NML_JOB_LINK}人財開発・研修`,
    }, {
        id: 8,
        name: "page.common.labor_transfers_and_evaluations",
        href: `${USA_JOB_LINK}労務・異動・評価`,
        href_jp: `${NML_JOB_LINK}労務・異動・評価`,
    }, {
        id: 9,
        name: "page.common.plant_hr",
        href: `${USA_JOB_LINK}工場人事`,
        href_jp: `${NML_JOB_LINK}工場人事`,
    }, {
        id: 10,
        name: "page.common.salary_and_compensation_design",
        href: `${USA_JOB_LINK}給与・報酬設計`,
        href_jp: `${NML_JOB_LINK}給与・報酬設計`,
    }, {
        id: 11,
        name: "page.common.occupational_safety_and_health",
        href: `${USA_JOB_LINK}労働安全衛生`,
        href_jp: `${NML_JOB_LINK}労働安全衛生`,
    }, {
        id: 12,
        name: "page.common.executive_secretary",
        href: `${USA_JOB_LINK}役員秘書`,
        href_jp: `${NML_JOB_LINK}役員秘書`,
    }, {
        id: 13,
        name: "page.common.organizational_development",
        href: `${USA_JOB_LINK}組織開発`,
        href_jp: `${NML_JOB_LINK}組織開発`,
    }]
}, {
    id: 12,
    name: "page.common.corporate",
    links: [{
        id: 1,
        name: "page.common.internal_audit",
        href: `${USA_JOB_LINK}内部監査`,
        href_jp: `${NML_JOB_LINK}内部監査`,
    }, {
        id: 2,
        name: "page.common.legal_affairs",
        href: `${USA_JOB_LINK}法務`,
        href_jp: `${NML_JOB_LINK}法務`,
    }, {
        id: 3,
        name: "page.common.risk_and_compliance",
        href: `${USA_JOB_LINK}リスク＆コンプライアンス`,
        href_jp: `${NML_JOB_LINK}リスク＆コンプライアンス`,
    }, {
        id: 4,
        name: "page.common.intellectual_property",
        href: `${USA_JOB_LINK}知的財産`,
        href_jp: `${NML_JOB_LINK}知的財産`,
    }, {
        id: 5,
        name: "page.common.general_affairs_and_liaison",
        href: `${USA_JOB_LINK}総務・渉外`,
        href_jp: `${NML_JOB_LINK}総務・渉外`,
    }, {
        id: 6,
        name: "page.common.global_asset_management",
        href: `${USA_JOB_LINK}グローバル資産管理`,
        href_jp: `${NML_JOB_LINK}グローバル資産管理`,
    }, {
        id: 7,
        name: "page.common.ir",
        href: `${USA_JOB_LINK}IR`,
        href_jp: `${NML_JOB_LINK}IR`,
    }, {
        id: 8,
        name: "page.common.in_house_consultant",
        href: `${USA_JOB_LINK}社内コンサルタント`,
        href_jp: `${NML_JOB_LINK}社内コンサルタント`,
    }]
}, {
    id: 13,
    name: "page.common.communication",
    links: [{
        id: 1,
        name: "page.common.advanced_technology_communication_operations",
        href: `${USA_JOB_LINK}先進技術コミュニケーション＆オペレーション`,
        href_jp: `${NML_JOB_LINK}先進技術コミュニケーション＆オペレーション`,
    }, {
        id: 2,
        name: "page.common.corporate_communication",
        href: `${USA_JOB_LINK}コーポレートコミュニケーション`,
        href_jp: `${NML_JOB_LINK}コーポレートコミュニケーション`,
    }, {
        id: 3,
        name: "page.common.internal_communication",
        href: `${USA_JOB_LINK}インターナルコミュニケーション`,
        href_jp: `${NML_JOB_LINK}インターナルコミュニケーション`,
    }, {
        id: 4,
        name: "page.common.product_communication",
        href: `${USA_JOB_LINK}プロダクトコミュニケーション`,
        href_jp: `${NML_JOB_LINK}プロダクトコミュニケーション`,
    }, {
        id: 5,
        name: "page.common.communication_strategy",
        href: `${USA_JOB_LINK}コミュニケーション戦略`,
        href_jp: `${NML_JOB_LINK}コミュニケーション戦略`,
    }, {
        id: 6,
        name: "page.common.digital_communication",
        href: `${USA_JOB_LINK}デジタルコミュニケーション`,
        href_jp: `${NML_JOB_LINK}デジタルコミュニケーション`,
    }]
}, {
    id: 14,
    name: "page.common.global_after_sales",
    links: [{
        id: 1,
        name: "page.common.engineering",
        href: `${USA_JOB_LINK}エンジニアリング`,
        href_jp: `${NML_JOB_LINK}エンジニアリング`,
    }, {
        id: 2,
        name: "page.common.planning_strategy",
        href: `${USA_JOB_LINK}企画戦略`,
        href_jp: `${NML_JOB_LINK}企画戦略`,
    }, {
        id: 3,
        name: "page.common.network",
        href: `${USA_JOB_LINK}ネットワーク`,
        href_jp: `${NML_JOB_LINK}ネットワーク`,
    }, {
        id: 4,
        name: "page.common.conversion_accessory_planning_development",
        href: `${USA_JOB_LINK}コンバージョン＆アクセサリー企画開発`,
        href_jp: `${NML_JOB_LINK}コンバージョン＆アクセサリー企画開発`,
    }, {
        id: 5,
        name: "page.common.supply_chain_management",
        href: `${USA_JOB_LINK}サプライチェーンマネジメント`,
        href_jp: `${NML_JOB_LINK}サプライチェーンマネジメント`,
    }, {
        id: 6,
        name: "page.common.connected",
        href: `${USA_JOB_LINK}コネクテッド`,
        href_jp: `${NML_JOB_LINK}コネクテッド`,
    }]
}];