import { FC } from "react";
import { Row, Col } from "react-bootstrap";

import JourneyCard from "../../Common/Card/Journey";
import { SUB_PAGES } from "../../../../interfaces/RegionalPage";

interface Props {
    items: SUB_PAGES[]
}

const DiscoverJourneyDesktop: FC<Props> = ({ items }) => {
    return (
        <Row>
            {
                items.length > 0 && items.map((item) => {
                    return (
                        <Col lg={3} key={item.id}>
                            <JourneyCard journey={item} />
                        </Col>
                    )
                })
            }
        </Row>
    );
};
export default DiscoverJourneyDesktop;
