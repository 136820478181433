import { Helmet } from "react-helmet-async";
import { ErrorPage } from "../Components/ErrorPage";

function ErrorContainer() {
  return (
    <>
      <Helmet>
        <title>404 - NISSAN MOTOR CORPORATION - GLOBAL CAREER WEBSITE</title>
      </Helmet>
      <main className="error-container">
        <ErrorPage />
      </main>
    </>
  );
}

export default ErrorContainer;
