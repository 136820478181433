import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from "react";
import OceaniaSubPage from "../Components/Ocenaia/id";
import Oceania from "../Components/Ocenaia";
import { AUSTRALIA_NEWZEALAND_SUB_PAGES } from "../Config/Static/ProgramDetail/Oceania";

function OceaniaContainer() {
    const navigate = useNavigate();
    const { pageId } = useParams();
    useEffect(() => {
        if (pageId) {
            const isPresent = AUSTRALIA_NEWZEALAND_SUB_PAGES.find(x => x.slug === pageId);
            if (!isPresent)
                navigate("/404-found")
        }
    }, [pageId, navigate])

    return (
        <>
            <Helmet>
                <title>
                    NISSAN MOTOR CORPORATION - GLOBAL CAREER WEBSITE - AUSTRALIA & NEW ZEALAND
                </title>
            </Helmet>
            <main className="americas-container oceania-container">
                {!pageId ? <Oceania /> : <OceaniaSubPage pageId={pageId} />}
            </main>
        </>
    );
}

export default OceaniaContainer;
