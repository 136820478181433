import CareersAreaDetail from "../Components/CareersAreaDetail";

import { Helmet } from "react-helmet-async";

function CareersAreaDetailContainer() {
  return (
    <>
      <Helmet>
        <title>
          NISSAN MOTOR CORPORATION - GLOBAL CAREER WEBSITE - CAREER AREAS -
          OVERVIEW
        </title>
      </Helmet>
      <main className="career-area-container career-area-details-container">
        <CareersAreaDetail />
      </main>
    </>
  );
}

export default CareersAreaDetailContainer;
