import { FC } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Card } from "react-bootstrap";

import { TESTIMONIAL_MOBILE_ITEMS } from "./_items";
import TestimonialsCard from "./_card";
const TestimonialsMobile: FC = () => {
  return (
    <Splide
      options={{
        arrows: false,
        pagination: true,
        width: "100%",
        type: "loop",
        perPage: 1,
        perMove: 1,
        gap: "15px",
        rewind: false,
        autoplay: false,
        pauseOnHover: true,
        resetProgress: false,
        interval: 5000,
      }}
    >
      {TESTIMONIAL_MOBILE_ITEMS.map((mobile_item, index) => {
        return (
          <SplideSlide key={index}>
            {mobile_item.items.map((item, index) => {
              return <TestimonialsCard key={index} {...item} />;
            })}
          </SplideSlide>
        );
      })}
    </Splide>
  );
};

export default TestimonialsMobile;
