import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { LocationProps } from '../../../../interfaces/RegionalPage';
import RegionalLocation from '../Card/RegionalLocation';

interface Props {
    location: LocationProps
}

const TabPane: FC<Props> = ({ location }) => {
    return (
        <Row>
            {location.items.length > 0 && location.items.map(item => {
                return (
                    <Col lg={4} md={6} sm={12} key={item.id}>
                        <RegionalLocation id={item.id} location={item} />
                    </Col>
                )
            })}
        </Row>

    );
}

export default TabPane;