import { FC } from "react";
import { Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import { Banner } from "../../../../interfaces/Banner";
import FloatingButton from "../FloatingButton";

const AboutBanner: FC<Banner> = ({ banners }) => {
  return (
    <section className="banner about">
      {banners &&
        banners.length > 0 &&
        banners.map((item, index) => {
          return (
            <div key={index}>
              <Container>
                <div className="caption">
                  <h1>
                    <FormattedMessage id="page.about.banner.title" />
                  </h1>
                  <p>
                    <FormattedMessage id="page.about.banner.description" />
                  </p>
                </div>
              </Container>
            </div>
          );
        })}
        <FloatingButton />
    </section>
  );
};

export default AboutBanner;
