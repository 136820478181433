import React from "react";
import { Container } from "react-bootstrap";

export class ErrorPage extends React.Component {
  render() {
    return (
      <Container>
        <section>
          <div className="d-flex align-items-center justify-content-center">
            <h1>404</h1> <p>This page could not be found.</p>
          </div>
        </section>
      </Container>
    );
  }
}
