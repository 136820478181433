import { FC } from "react";
import { Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import { Banner } from "../../../../interfaces/Banner";
import FloatingButton from "../FloatingButton";

const CareerAreaBanner: FC<Banner> = ({ banners }) => {
  return (
    <section className="banner career">
      <Container>
        <div className="caption">
          <h1>
            <FormattedMessage id="page.career_area.banner.title" />
          </h1>
          <p>
            <FormattedMessage id="page.career_area.banner.description" />
          </p>
        </div>
      </Container>
      <FloatingButton />
    </section>
  );
};

export default CareerAreaBanner;
