import { FC } from "react";

import { Image } from "react-bootstrap";

interface Props {
  src: string;
  width?: string | number;
  height?: string | number;
  className?: string;
  alt?: string;
}

const SimpleImage: FC<Props> = ({ src, width, height, className, alt }) => {
  return (
    <Image
      src={src}
      alt={alt ? alt : ""}
      className={`img-fluid ${className ? className : ""}`}
    />
  );
};

export default SimpleImage;
