import { FC, useEffect, useState } from "react";

import SimpleImage from "../../../Media/SimpleImage";

interface Props {
    images: string[]
}

const ImageTileMobile: FC<Props> = ({ images }) => {

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [images.length]);


    return (
        <div className="banner-tiles-mobile">
            {images.map((image, index) => (
                <SimpleImage
                    key={index}
                    src={image}
                    alt={`Slide ${index}`}
                    className={index === currentIndex ? 'active' : ''}
                />
            ))}
        </div>
    );
};

export default ImageTileMobile;
