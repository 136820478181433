import { FC } from "react";
import { Anchor } from "../";
import { Button } from "react-bootstrap";
import SimpleImage from "../../../Media/SimpleImage";
import ExternalImage from "../../../../../assets/images/external-link.svg";
import ExternalWhiteImage from "../../../../../assets/images/external-link-white.svg";
import { isMobile } from "react-device-detect";
interface Props {
  href: string;
  href_jp?: string;
  children: React.ReactNode;
  variant?: string;
  external?: boolean;
  ewhite?: boolean;
  size?: "sm" | "lg";
  mobileView?: boolean | false;
}
export const AnchorButton: FC<Props> = ({
  href,
  href_jp,
  children,
  variant,
  external,
  ewhite,
  size,
  mobileView,
}) => {
  return (
    <Anchor href={href} href_jp={href_jp} external={external}>
      <Button variant={isMobile && mobileView ? "link" : variant} size={size}>
        {children}
        {external && (
          <SimpleImage
            src={ewhite ? ExternalWhiteImage : ExternalImage}
            className="px-2 vertical-align-initial"
          />
        )}
      </Button>
    </Anchor>
  );
};
