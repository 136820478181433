import early_hires from "../../../../assets/images/americas/discover_early.webp";
import Early_Hire from "../../../../assets/images/americas/early_hires.webp";
import { WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";
import { EUROPE_PROGRAM } from "./_programs";

const hungarySlugs_Graduate_Program = [
    'hungary-budapest',
]

const franceSlugs_Graduate_Program = [
    'france-montigny-le-bretonneux-west',
]

const ukSlugs_Graduate_Program = [
    'cranfield',
    'paddington-london',
    'maple-cross-london-uk',
    'sunderland-uk',
]

const finlandSlugs_Graduate_Program = [
    'finland-espoo',
]

const finlandSlugs_Trainee_Program = [
    'finland-espoo',
]

const hungarySlugs_Trainee_Program = [
    'hungary-budapest',
]

const franceSlugs_Trainee_Program = [
    'france-montigny-le-bretonneux-west',
]

const italySlugs_Trainee_Program = [
    'italy-capena',
]

const spainSlugs_Internship = [
    'spain-barcelona',
]

const ukSlugs_Industrial_Placements = [
    'sunderland-uk',
    'paddington-london',
    'cranfield',
    'maple-cross-london-uk',
]

const ukSlugs_Apprenticeships = [
    'sunderland-uk',
    'paddington-london',
    'cranfield',
    'maple-cross-london-uk',
]

const HUNGARY_EUROPE_PROGRAMS_GRADUATE_PROGRAM = EUROPE_PROGRAM.filter(x => hungarySlugs_Graduate_Program.indexOf(x.slug) >= 0);
const FRANCE_EUROPE_PROGRAMS_GRADUATE_PROGRAM = EUROPE_PROGRAM.filter(x => franceSlugs_Graduate_Program.indexOf(x.slug) >= 0);
const UK_EUROPE_PROGRAMS_GRADUATE_PROGRAM = EUROPE_PROGRAM.filter(x => ukSlugs_Graduate_Program.indexOf(x.slug) >= 0);
const FINLAND_EUROPE_PROGRAMS_GRADUATE_PROGRAM = EUROPE_PROGRAM.filter(x => finlandSlugs_Graduate_Program.indexOf(x.slug) >= 0);
const FINLAND_EUROPE_PROGRAMS_TRAINEE_PROGRAM = EUROPE_PROGRAM.filter(x => finlandSlugs_Trainee_Program.indexOf(x.slug) >= 0);
const HUNGARY_EUROPE_PROGRAMS_TRAINEE_PROGRAM = EUROPE_PROGRAM.filter(x => hungarySlugs_Trainee_Program.indexOf(x.slug) >= 0);
const FRANCE_EUROPE_PROGRAMS_TRAINEE_PROGRAM = EUROPE_PROGRAM.filter(x => franceSlugs_Trainee_Program.indexOf(x.slug) >= 0);
const ITALY_EUROPE_PROGRAMS_TRAINEE_PROGRAM = EUROPE_PROGRAM.filter(x => italySlugs_Trainee_Program.indexOf(x.slug) >= 0);
const SPAIN_EUROPE_PROGRAMS_INTERNSHIP = EUROPE_PROGRAM.filter(x => spainSlugs_Internship.indexOf(x.slug) >= 0);
const UK_EUROPE_PROGRAMS_INDUSTRIAL_PLACEMENT = EUROPE_PROGRAM.filter(x => ukSlugs_Industrial_Placements.indexOf(x.slug) >= 0);
const UK_EUROPE_PROGRAMS_APPRENTICESHIPS = EUROPE_PROGRAM.filter(x => ukSlugs_Apprenticeships.indexOf(x.slug) >= 0);


const HUNGARY_EUROPE_LOCATIONS_GRADUATE_PROGRAM = {
    id: 1,
    name: 'Hungary',
    slug: 'hungary',
    items: HUNGARY_EUROPE_PROGRAMS_GRADUATE_PROGRAM
}
const FRANCE_EUROPE_LOCATIONS_GRADUATE_PROGRAM = {
    id: 2,
    name: 'France',
    slug: 'france',
    items: FRANCE_EUROPE_PROGRAMS_GRADUATE_PROGRAM
}

const UK_EUROPE_LOCATIONS_GRADUATE_PROGRAM = {
    id: 3,
    name: 'UK',
    slug: 'uk',
    items: UK_EUROPE_PROGRAMS_GRADUATE_PROGRAM
}

const FINLAND_EUROPE_LOCATIONS_GRADUATE_PROGRAM = {
    id: 4,
    name: 'Finland',
    slug: 'finland',
    items: FINLAND_EUROPE_PROGRAMS_GRADUATE_PROGRAM
}

const FINLAND_EUROPE_LOCATIONS_TRAINEE_PROGRAM = {
    id: 5,
    name: 'Finland',
    slug: 'finland',
    items: FINLAND_EUROPE_PROGRAMS_TRAINEE_PROGRAM
}
const HUNGARY_EUROPE_LOCATIONS_TRAINEE_PROGRAM = {
    id: 6,
    name: 'Hungary',
    slug: 'hungary',
    items: HUNGARY_EUROPE_PROGRAMS_TRAINEE_PROGRAM
}

const FRANCE_EUROPE_LOCATIONS_TRAINEE_PROGRAM = {
    id: 7,
    name: 'France',
    slug: 'france',
    items: FRANCE_EUROPE_PROGRAMS_TRAINEE_PROGRAM
}

const ITALY_EUROPE_LOCATIONS_TRAINEE_PROGRAM = {
    id: 8,
    name: 'Italy',
    slug: 'italy',
    items: ITALY_EUROPE_PROGRAMS_TRAINEE_PROGRAM
}

const SPAIN_EUROPE_LOCATIONS_INTERNSHIP = {
    id: 9,
    name: 'Spain',
    slug: 'spain',
    items: SPAIN_EUROPE_PROGRAMS_INTERNSHIP
}

const UK_EUROPE_LOCATIONS_INDUSTRIAL_PLACEMENT = {
    id: 10,
    name: 'UK',
    slug: 'uk',
    items: UK_EUROPE_PROGRAMS_INDUSTRIAL_PLACEMENT
}

const UK_EUROPE_LOCATIONS_APPRENTICESHIPS = {
    id: 10,
    name: 'UK',
    slug: 'uk',
    items: UK_EUROPE_PROGRAMS_APPRENTICESHIPS
}

export const EUROPE_EARLY_HIRES = {
    id: 'early-careers',
    card_src: early_hires,
    card_title: "page.discover.early_careers",
    card_description: "page.discover.early_careers_description",
    banner_src: Early_Hire,
    banner_title: "page.discover.early_careers",
    banner_description: "Embark on a Thrilling Journey",
    slug: 'early-careers',
    href: '/europe/early-careers',
    name: "page.discover.early_careers",
    description: "Immerse yourself in the world of mobility. Expand the boundaries of possibility and chart your own path at Nissan through our internships, MBA, and campus connection programs & events.",
    categories: [{
        id: 1,
        name: 'Graduate Program',
        slug: '',
        description: "At Nissan, we have a long-established graduate scheme across our Africa Middle East India Europe & Oceania (AMIEO) region. During our 18 months or 2 year program, we will provide the breadth and depth of knowledge you need to thrive and develop into a true professional in your area of expertise in one of the world’s leading automotive brands. In our scheme, you get real responsibility from the start with years of dedicated professional training and support to set you up for success. Graduate life is invigorating and exciting at Nissan. Each location has its own character and routine, driven by the shared goal of ongoing innovation and improvement. You will find yourself part of a team working together to shape the future of automotive mobility. Nissan will help develop your leadership, teamwork, assertiveness, presentation, financial and problem-solving skills to enable you to become a leader of the future.",
        //disableTab: true,
        locations: [
            FINLAND_EUROPE_LOCATIONS_GRADUATE_PROGRAM,
            FRANCE_EUROPE_LOCATIONS_GRADUATE_PROGRAM,
            HUNGARY_EUROPE_LOCATIONS_GRADUATE_PROGRAM,
            UK_EUROPE_LOCATIONS_GRADUATE_PROGRAM,
        ]
    }, {
        id: 2,
        name: 'Industrial Placements',
        slug: '',
        description: "Nissan entities in the UK offer placement schemes across a wide variety of disciplines. Whether you are looking for a career focused in the automotive industry or would simply like to join a company that can offer you real ongoing opportunities across the world, we have places for dedicated, hard-working and creative minds. Placement life is invigorating and exciting at Nissan. Each location has its own character and routine, driven by the shared goal of ongoing innovation and improvement. You will find yourself part of a team working together to shape the future of automotive mobility, prove yourself and this could be the beginning of a successful career with Nissan. Nissan in the UK is split across four key localities; Sunderland, Maple Cross, Cranfield and Paddington. Each location has a specific area of focus, which influence the schemes available within them. For example, our Sunderland site is the giant of our UK manufacturing operations, Maple Cross is our Sales & Marketing hub, and Cranfield and Paddington are focused on research & development and new product development",
        //disableTab: true,
        locations: [
            UK_EUROPE_LOCATIONS_INDUSTRIAL_PLACEMENT,
        ]
    }, {
        id: 3,
        name: 'Apprenticeships',
        slug: '',
        description: "Nissan's apprenticeship schemes offer you the chance to kickstart your career with a real taste of what life is like working at a world-leading car manufacturer, while gaining qualifications from an Ofsted “Outstanding”-rated training provider Our apprenticeship schemes typically range from two to five years. They include both on and off-the-job training, combining theoretical and practical skills to prepare you for your role and future career with us. Each scheme has its own entry criteria, aligned to the skills required for the challenges of that particular scheme so it’s important you pick one that aligns with your passions and goals. All of our apprenticeship schemes offer full-time, paid employment, with some schemes also offering overtime and shift allowances.",
        //disableTab: true,
        locations: [
            UK_EUROPE_LOCATIONS_APPRENTICESHIPS
        ]
    }, {
        id: 4,
        name: 'Trainee',
        slug: '',
        description: "As a Trainee you have an Ongoing University or College Degree. Nissan is providing opportunity for you to continuously learn and develop your professional knowledge in a real work and international, multicultural environment.",
        // disableTab: true,
        locations: [
            FINLAND_EUROPE_LOCATIONS_TRAINEE_PROGRAM,
            FRANCE_EUROPE_LOCATIONS_TRAINEE_PROGRAM,
            HUNGARY_EUROPE_LOCATIONS_TRAINEE_PROGRAM,
            ITALY_EUROPE_LOCATIONS_TRAINEE_PROGRAM
        ]
    },
    {
        id: 5,
        name: 'Intern',
        slug: '',
        description: "Some locations at Nissan offer a 3-12 months Internship program where you will work on real projects that could range from Marketing Communication to Dealer Management to Corporate Planning.",
        // disableTab: true,
        locations: [
            SPAIN_EUROPE_LOCATIONS_INTERNSHIP,
        ]
    }
    ],
    work_day_link: `${WORKDAY_EN_PATH}locationCountry=a04ea128f43a42e59b1e6a19e8f0b374&locationCountry=49ab063f422741e2aef271de00efeac8&locationCountry=038b0482bfea403abb61c9bcc3d7eb60&locationCountry=0afb2fa656da42e8bfb6d47bd24a26fa&locationCountry=54c5b6971ffb4bf0b116fe7651ec789a&locationCountry=dcc5b7608d8644b3a93716604e78e995&locationCountry=29247e57dbaf46fb855b224e03170bc7&locationCountry=bd34c524a6a04ae6915f5d96fa086199&locationCountry=9db257f5937e4421b2fac64eec6832f8&locationCountry=9696868b09c64d52a62ee13b052383cc&locationCountry=131d5ac7e3ee4d7b962bdc96e498e412&locationCountry=8cd04a563fd94da7b06857a79faaf815&locationCountry=a4051ef996ac40778d4c79e3f2dedfd2&locationCountry=1c026f3b1b8640d8bdfcb95466663e4d&locationCountry=8a0328effd25491fb8e6a08801f08e94&locationCountry=d07f8ca8625e4345b98a91d0558b872a&locationCountry=2e8c5034deb045d49315417c347472ca&locationCountry=6a800a4736884df5826858d435650f45&locationCountry=c2e3bac5bbbb47b29dfc6e8b56a1586e`
}