import { NML_SUB_PAGES } from "../../Config/Static/ProgramDetail/Nml"
import { NML_STORIES } from "../../Config/Static/Stories/nml-stories"
import DEI1 from "../../assets/images/nml/dei_1.webp"
import DEI2 from "../../assets/images/nml/dei_2.webp"
import DEI4 from "../../assets/images/nml/dei_4.webp"
import DEI5 from "../../assets/images/nml/dei_5.webp"
import DEI6 from "../../assets/images/nml/dei_6.webp"
import TestimonialsOne from "../../assets/images/nml/testimonial_1.webp";
import TestimonialsTwo from "../../assets/images/nml/testimonial_2.webp";
import TestimonialsThree from "../../assets/images/nml/testimonial_3.webp";
import TestimonialsFour from "../../assets/images/nml/testimonial_4.webp";

import js_application from "../../assets/images/nml/js_application.webp";
import js_business from "../../assets/images/nml/js_business.webp";
import js_career_seminar from "../../assets/images/nml/js_career_seminar.webp";
import js_engineering from "../../assets/images/nml/js_engineering.webp";
import js_faq from "../../assets/images/nml/js_faq.webp";
import { NML_PAGE, WORKDAY_EN_PATH, WORKDAY_JP_PATH } from "../../interfaces/Static/routing_paths"


export const NML_JOURNEY = {
    heading: "page.discover.your_journey",
    title: "page.discover.your_journey.title",
    description: "page.discover.your_journey.description",
    items: NML_SUB_PAGES
}

export const NML_DRIVING_TOGETHER = {
    heading: 'page.common.driving_together',
    title: 'page.common.driving_together_title',
    description: 'page.common.driving_together_description',
    href: 'https://www.nissan-global.com/EN/SUSTAINABILITY/SOCIAL/DIVERSITY/',
    href_jp: 'https://www.nissan-global.com/JP/SUSTAINABILITY/SOCIAL/DIVERSITY/',
    src: [DEI1, DEI2, DEI4, DEI5, DEI6],
    detailed_description: "page.common.driving_together_description_bottom"
}

export const NML_STORY = {
    title: "page.stories.nml_title",
    items: NML_STORIES,
    href: "https://www.nissan-global.com/EN/STORIES/LIST/",
    href_jp: "https://www.nissan-global.com/JP/STORIES/LIST/",
}


export const NML_TESTIMONIALS = [{
    id: 1,
    userName: 'K.T',
    userDesignation: 'page.testimonial.nml_designation_1',
    description: "page.testimonial.nml_description_1",
    src: TestimonialsOne,
}, {
    id: 2,
    userName: 'I.A',
    userDesignation: 'page.testimonial.nml_designation_2',
    description: "page.testimonial.nml_description_2",
    src: TestimonialsTwo,
}, {
    id: 3,
    userName: 'I.K',
    userDesignation: 'page.testimonial.nml_designation_3',
    description: 'page.testimonial.nml_description_3',
    src: TestimonialsThree,
}, {
    id: 4,
    userName: 'M.Y',
    userDesignation: 'page.testimonial.nml_designation_4',
    description: 'page.testimonial.nml_description_4',
    src: TestimonialsFour,
}];

export const RECRUITING_INFORMATION = [{
    id: 1,
    name: "page.common.application",
    description: "page.nml.application_hint",
    src: js_application,
    href: "https://www.nissanmotor.jobs/japan/MC/recruit/application.html",
    href_jp: "https://www.nissanmotor.jobs/japan/MC/recruit/application.html",
    external: true
}, {
    id: 2,
    name: "page.nml.ri.jobsearch_engineering",
    description: "page.nml.ri.jobsearch_engineering_hint",
    src: js_engineering,
    href: `${NML_PAGE}/job-search/engineering`,
    href_jp: `${NML_PAGE}/job-search/engineering`,
    external: false
}, {
    id: 3,
    name: "page.nml.ri.jobsearch_business",
    description: "page.nml.ri.jobsearch_business_hint",
    src: js_business,
    href: `${NML_PAGE}/job-search/business`,
    href_jp: `${NML_PAGE}/job-search/business`,
    external: false
}, {
    id: 4,
    name: "page.common.career_seminar",
    description: "page.nml.career_seminar_hint",
    src: js_career_seminar,
    href: `${WORKDAY_EN_PATH}q=キャリアセミナー&locationCountry=8b705da2becf43cfaccc091da0988ab2`,
    href_jp: `${WORKDAY_JP_PATH}q=キャリアセミナー&locationCountry=8b705da2becf43cfaccc091da0988ab2`,
    external: true
}, {
    id: 5,
    name: "page.common.faq",
    description: "page.nml.faq_hint",
    src: js_faq,
    href: "https://www.nissanmotor.jobs/japan/MC/recruit/faqs.html",
    href_jp: "https://www.nissanmotor.jobs/japan/MC/recruit/faqs.html",
    external: true
}]