
import enjoy_your_cars from "../../../assets/images/oceania/enjoy_your_cars.webp";
import managing_your_life from "../../../assets/images/oceania/managing_your_life.webp";
import comprehenisve_benefits from "../../../assets/images/oceania/comprehenisve_benefits.webp";
import extensive_internal from "../../../assets/images/oceania/extensive_internal.webp";
import career_development from "../../../assets/images/oceania/career_development.webp";
import enjoying_life from "../../../assets/images/oceania/enjoying_life.webp";


export const AUSTRALIA_NEWZEALAND_EMPLOYEE_LOVES_STATIC = [{
    id: 1,
    src: enjoy_your_cars,
    title: "page.oceania.enjoy_your_cars",
    description: "page.oceania.enjoy_your_cars_description"
}, {
    id: 2,
    src: managing_your_life,
    title: "page.oceania.managing_your_life",
    description: "page.oceania.managing_your_life_description"
}, {
    id: 3,
    src: comprehenisve_benefits,
    title: "page.oceania.comprehenisve_benefits",
    description: "page.oceania.comprehenisve_benefits_description"
}, {
    id: 4,
    src: extensive_internal,
    title: "page.oceania.extensive_internal",
    description: "page.oceania.extensive_internal_description"
}, {
    id: 5,
    src: career_development,
    title: "page.oceania.career_development",
    description: "page.oceania.career_development_description"
}, {
    id: 6,
    src: enjoying_life,
    title: "page.oceania.enjoying_life",
    description: "page.oceania.enjoying_life_description"
}];

export const AUSTRALIA_NEWZEALAND_EMPLOYEE_LOVES_STATIC_MOBILE = [{
    id: 1,
    mobile_items: [{
        id: 1,
        src: enjoy_your_cars,
        title: "page.oceania.enjoy_your_cars",
        description: "page.oceania.enjoy_your_cars_description"
    }, {
        id: 2,
        src: managing_your_life,
        title: "page.oceania.managing_your_life",
        description: "page.oceania.managing_your_life_description"
    }]
},
{
    id: 2,
    mobile_items: [{
        id: 3,
        src: comprehenisve_benefits,
        title: "page.oceania.comprehenisve_benefits",
        description: "page.oceania.comprehenisve_benefits_description"
    }, {
        id: 4,
        src: extensive_internal,
        title: "page.oceania.extensive_internal",
        description: "page.oceania.extensive_internal_description"
    }]
},
{
    id: 3,
    mobile_items: [{
        id: 5,
        src: career_development,
        title: "page.oceania.career_development",
        description: "page.oceania.career_development_description"
    }, {
        id: 6,
        src: enjoying_life,
        title: "page.oceania.enjoying_life",
        description: "page.oceania.enjoying_life_description"
    }]
}]