import { FC } from "react";
import CareerAreaBanner from "../Shared/Banner/CareersArea";
import CareerAmbition from "./CareersAmbition";
import CorporateArea from "./CorporateArea";
import Program from "./Program";
import Card from "./_card";

const CareersArea: FC = () => {
  return (
    <>
      <CareerAreaBanner banners={[]} />
      <CorporateArea />
      <Program />
      <CareerAmbition />
      <Card />
    </>
  );
};
export default CareersArea;
