
import Stories1 from "../../../assets/images/oceania/stories_1.webp";
import Stories2 from "../../../assets/images/oceania/stories_2.webp";
import Stories3 from "../../../assets/images/oceania/stories_3.webp";
import Stories4 from "../../../assets/images/oceania/stories_4.webp";
import Stories5 from "../../../assets/images/oceania/stories_5.webp";


export const AUSTRALIA_NEWZEALAND_STORIES = [{
    id: 1,
    src: Stories1,
    title: "page.oceania.stories.first",
    href: "https://australia.nissannews.com/en-AU/releases/nissan-formula-e-team-survey-reveals-majority-of-young-people-excited-by-the-future-of-electric-mobility?selectedTabId=releases"
}, {
    id: 2,
    src: Stories2,
    title: "page.oceania.stories.second",
    href: "https://australia.nissannews.com/en-AU/releases/taking-performance-to-new-heights-nissan-z-nismo-unleashed-on-lake-mountain-with-the-iconic-flagship-now-available-for-order-through-nissan-showrooms?selectedTabId=releases"
}, {
    id: 3,
    src: Stories3,
    title: "page.oceania.stories.third",
    href: "https://australia.nissannews.com/en-AU/releases/nissan-drives-the-next-generation-of-women-forward-this-international-womens-day?selectedTabId=releases"
}, {
    id: 4,
    src: Stories4,
    title: "page.oceania.stories.fourth",
    href: "https://australia.nissannews.com/en-AU/releases/ready-to-get-dirty-full-nissan-x-trail-n-trek-pricing-and-specifications-revealed-as-aussies-invited-to-unleash-their-wild-side"
}, {
    id: 5,
    src: Stories5,
    title: "page.oceania.stories.fifth",
    href: "https://australia.nissannews.com/en-AU/releases/generation-next-new-wave-of-future-automotive-professionals-arrive-at-nissan-hq-as-part-of-award-winning-nissmap-apprenticeship-program"
}]