import { Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { AnchorButton } from "../../Shared/Common/Anchor/Button";
import SimpleImage from "../../Shared/Media/SimpleImage";
import nml_career from "../../../assets/images/nml/nml_career.webp"

const Career = () => {
    return (
        <div className="nml-career">
            <h2>
                <FormattedMessage id="page.common.career" />
            </h2>
            <Row className="career-container align-center mt-4">
                <Col xs={12} md={4}>
                    <SimpleImage src={nml_career}  />
                </Col>
                <Col xs={12} md={8} className="section-content">
                        <p>
                            <FormattedMessage id="page.nml.career.description" />
                        </p>
                        <div className="mb-5">

                            <FormattedMessage id="page.nml.career.description_2" />
                        </div>
                        <AnchorButton
                            href={
                                "https://www.nissanmotor.jobs/japan/MC/life-at-nissan/field.html"
                            }
                            href_jp={
                                "https://www.nissanmotor.jobs/japan/MC/life-at-nissan/field.html"
                            }
                            variant={"secondary"}
                            external={true}
                            ewhite={true}
                        >
                            <FormattedMessage id="page.common.view_more" />
                        </AnchorButton>
                </Col>
            </Row>
        </div>

    )
}

export default Career;