import { AFRICA_SUB_PAGE } from "../../Config/Static/ProgramDetail/Africa";
import { AFRICAS_STORIES } from "../../Config/Static/Stories/africa-stories";

import DEI1 from "../../assets/images/africa/dei_1.webp"
import DEI2 from "../../assets/images/africa/dei_2.webp"
import DEI3 from "../../assets/images/africa/dei_3.webp"
import DEI4 from "../../assets/images/africa/dei_4.webp"
import DEI5 from "../../assets/images/africa/dei_5.webp"
import DEI6 from "../../assets/images/africa/dei_6.webp"
import DEI7 from "../../assets/images/africa/dei_7.webp"
import DEI8 from "../../assets/images/africa/dei_8.webp"


import TestimonialsOne from "../../assets/images/africa/testimonials_1.webp";
import TestimonialsTwo from "../../assets/images/africa/testimonials_2.webp";
import TestimonialsThree from "../../assets/images/africa/testimonials_3.webp";
import TestimonialsFour from "../../assets/images/africa/testimonials_4.webp";
import TestimonialsFive from "../../assets/images/africa/testimonials_5.webp";

export const AFRICA_DRIVING_TOGETHER = {
    heading: 'page.common.driving_together',
    title: 'page.common.driving_together_title',
    description: 'page.africa.driving_together_description',
    href: 'https://africa.nissanmotornews.com/en-ZA/releases/fostering-a-safe-space-for-everyone-nissan-amieo-africa-middle-east-india-europe-oceania-shares-its-first-diversity-equity-and-inclusion-dei-report',
    href_jp: 'https://africa.nissanmotornews.com/en-ZA/releases/fostering-a-safe-space-for-everyone-nissan-amieo-africa-middle-east-india-europe-oceania-shares-its-first-diversity-equity-and-inclusion-dei-report',
    src: [DEI1, DEI4, DEI3, DEI5, DEI8, DEI2, DEI6, DEI7],
    detailed_description: 'page.africa.driving_together_descirption_bottom'
}

export const AFRICA_TESTIMONIALS = [{
    id: 1,
    userName: 'Safaa Gamal',
    userDesignation: 'page.testimonial.africa_designation_2',
    description: 'page.testimonial.africa_description_2',
    src: TestimonialsFive,
}, {
    id: 2,
    userName: 'Tshikuvhe Muimeleli',
    userDesignation: 'page.testimonial.africa_designation_1',
    description: "page.testimonial.africa_description_1",
    src: TestimonialsOne,
}, {
    id: 3,
    userName: 'Victoria Motiang',
    userDesignation: 'page.testimonial.africa_designation_3',
    description: 'page.testimonial.africa_description_3',
    src: TestimonialsThree,
}, {
    id: 4,
    userName: 'Moses Ndimande',
    userDesignation: 'page.testimonial.africa_designation_4',
    description: 'page.testimonial.africa_description_4',
    src: TestimonialsFour,
}, {
    id: 5,
    userName: 'Eman Ramadan',
    userDesignation: 'page.testimonial.africa_designation_5',
    description: 'page.testimonial.africa_description_5',
    src: TestimonialsTwo
}];

export const AFRICA_JOURNEY = {
    heading: "page.discover.your_journey",
    title: "page.discover.your_journey.title",
    description: "page.discover.your_journey.description",
    sub_description: "page.discover.your_journey_description_bottom",
    items: AFRICA_SUB_PAGE
}

export const AFRICA_STORY = {
    title: "page.stories.africa_title",
    items: AFRICAS_STORIES,
    href: "https://africa.nissanmotornews.com/en-ZA/",
    href_jp: "https://africa.nissanmotornews.com/en-ZA/",
}