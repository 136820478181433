import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import MiddleEast from "../Components/MiddleEast";
import MiddleEastSubPage from "../Components/MiddleEast/id";

import { MIDDLE_EAST_SUB_PAGES } from "../Config/Static/ProgramDetail/MiddleEast";

function MiddleEastContainer() {
    const navigate = useNavigate();
    const { pageId } = useParams();
    useEffect(() => {
        if (pageId) {
            const isPresent = MIDDLE_EAST_SUB_PAGES.find(x => x.slug === pageId);
            if (!isPresent)
                navigate("/404-found")
        }
    }, [pageId, navigate])
    return (
        <>
            <Helmet>
                <title>
                    NISSAN MOTOR CORPORATION - GLOBAL CAREER WEBSITE - MIDDLE EAST
                </title>
            </Helmet>
            <main className="americas-container middle-east-container">
                {!pageId ? <MiddleEast /> : <MiddleEastSubPage pageId={pageId} />}
            </main>
        </>
    );
}

export default MiddleEastContainer;
