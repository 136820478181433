import { FC } from "react";
import { Container } from "react-bootstrap";
import Heading from "../../Shared/Common/Heading";
import { AnchorButton } from "../../Shared/Common/Anchor/Button";
import { FormattedMessage } from "react-intl";
export const MeetOurLeader: FC = () => {
  return (
    <div className="meet-our-leader">
      <Container>
        <Heading type="section-heading">
          <FormattedMessage id="page.meet_our_leaders" />
        </Heading>
        <div className="row">
          <div className="col-lg-9">
            <div className="meet-our-leader-description">
              <div className="quote-rectangle"></div>
              <p>
                <FormattedMessage id="page.meet_our_leaders_description" />
                <AnchorButton
                  href={
                    "https://www.nissan-global.com/EN/COMPANY/PROFILE/EXECUTIVE/"
                  }
                  href_jp={
                    "https://www.nissan-global.com/JP/COMPANY/PROFILE/EXECUTIVE/"
                  }
                  variant={"primary"}
                  external={true}
                >
                  <FormattedMessage id="page.common.learn_more" />
                </AnchorButton>
              </p>
            </div>
          </div>
        </div>

      </Container >
    </div >
  );
};
