import { FC } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import LifeatnissanImage from "../../../assets/images/lifeatnissan.webp";
import { AnchorButton } from "../../Shared/Common/Anchor/Button";
import Heading from "../../Shared/Common/Heading";
import SimpleImage from "../../Shared/Media/SimpleImage";
export const LifeatNissan: FC = () => {
  return (
    <>
      <div className="lifeatnissan-area">
        <Container>
          <Row>
            <Col lg={6} className="lifeatnissan-cont">
              <Heading type="section-sub-heading">
                <FormattedMessage id="page.meetourpeople.lifeatnissan.heading" />
              </Heading>
              <p>
                <FormattedMessage id="page.meetourpeople.lifeatnissan.description" />
              </p>
              <div className="only-desktop">
                <AnchorButton
                  href={
                    "https://www.nissan-global.com/EN/COMPANY/LIFE_AT_NISSAN/"
                  }
                  href_jp={
                    "https://www.nissan-global.com/JP/COMPANY/LIFE_AT_NISSAN/"
                  }
                  variant={"secondary"}
                  external={true}
                  ewhite={true}
                >
                  <FormattedMessage id="page.common.life_at_nissan" />
                </AnchorButton>
              </div>
            </Col>
            <Col lg={6} className="d-flex justify-content-end flex-column">
              <SimpleImage src={LifeatnissanImage} />

              <div className="only-mobile my-3">
                <AnchorButton
                  href={
                    "https://www.nissan-global.com/EN/COMPANY/LIFE_AT_NISSAN/"
                  }
                  href_jp={
                    "https://www.nissan-global.com/JP/COMPANY/LIFE_AT_NISSAN/"
                  }
                  variant={"secondary"}
                  external={true}
                  ewhite={true}
                >
                  <FormattedMessage id="page.common.life_at_nissan" />
                </AnchorButton>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
