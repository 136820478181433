import { FC, useEffect, useState } from "react";
import { Carousel, Col, Row } from "react-bootstrap";
import SimpleImage from "../../../Media/SimpleImage";

interface Props {
    images: string[]
}

const shuffleIndex = ["2, 0, 1, 4, 5, 3", "3, 4, 1, 5, 0, 2", "5, 3, 0, 2, 1, 4", "2, 4, 1, 0, 5, 3"]


const ImageTileDesktop: FC<Props> = ({ images }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [shuffledImages, setShuffledImages] = useState<string[]>(images);
    const [showImage, setShowImage] = useState(false);

    const cacheImages = async (srcArray: string[]): Promise<void> => {
        setShowImage(false);
        const promises = srcArray.map((src) => {
            return new Promise<void>((resolve, reject) => {
                const img = new Image();
                img.src = src;
                img.onload = () => resolve();
                img.onerror = () => reject();
            });
        });
        await Promise.all(promises).then(x => {
            // console.log(x)
        }).catch(error => {
            // console.log(error)
        });
        setTimeout(() => {
            setShowImage(true);
        }, 100)
        setIsLoading(false);
    };

    useEffect(() => {
        // setShuffledImages(images);
        cacheImages(images);
        const interval = setInterval(() => {
            const shuffled = getImages();
            setShuffledImages(shuffled);
            cacheImages(shuffledImages);
            setCurrentIndex((prev => prev > shuffleIndex.length - 2 ? 0 : prev + 1))
        }, 5000);

        return () => clearInterval(interval);
    }, [currentIndex]);

    const getImages = () => {
        const imageByIndex = shuffleIndex[currentIndex];

        if (imageByIndex) {
            const arrayItems = imageByIndex.split(',');
            return arrayItems.map((x: any) => images[Number(x)]);

        } else {
            return images;
        }
    }

    return (
        <>
            {
                !isLoading && (
                    <>
                        <div className="banner-tiles-visible">
                            {
                                shuffledImages.map((x, index) => {
                                    return (
                                        <div key={index} className="banner-tiles">
                                            <SimpleImage src={shuffledImages[index]} className={`${showImage ? 'show' : 'hide'}`} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="banner-tiles-hidden">
                            {
                                images.map((x, index) => {
                                    return (
                                        <div key={index} className="banner-tiles">
                                            <SimpleImage src={x} />
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </>
                )
            }
        </>
    );
};

export default ImageTileDesktop;
