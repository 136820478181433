import Americas from "../Components/Americas";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from 'react-router-dom';
import { AMERICAS_SUB_PAGES } from "../Config/Static/ProgramDetail/Americas";
import { useEffect } from "react";
import AmericasSubPage from "../Components/Americas/[id]";

function AmericasContainer() {
  const navigate = useNavigate();
  const { pageId } = useParams();
  useEffect(() => {
    if (pageId) {
      const isPresent = AMERICAS_SUB_PAGES.find(x => x.slug === pageId);
      if (!isPresent)
        navigate("/404-found")
    }
  }, [pageId, navigate])


  return (
    <>
      <Helmet>
        <title>
          NISSAN MOTOR CORPORATION - GLOBAL CAREER WEBSITE - AMERICAS
        </title>
      </Helmet>
      <main className="americas-container">
        {!pageId ? <Americas /> : <AmericasSubPage pageId={pageId} />}
      </main>
    </>
  );
}

export default AmericasContainer;
