import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Nml from "../Components/Nml";
import NmlSubPage from "../Components/Nml/id";
import { NML_SUB_PAGES } from "../Config/Static/ProgramDetail/Nml";

function NmlContainer() {
    const navigate = useNavigate();
    const { pageId } = useParams();
    useEffect(() => {
        if (pageId) {
            const isPresent = NML_SUB_PAGES.find(x => x.slug === pageId);
            if (!isPresent)
                navigate("/404-found")
        }
    }, [pageId, navigate])
    return (
        <>
            <Helmet>
                <title>
                    NISSAN MOTOR CORPORATION - GLOBAL CAREER WEBSITE - JAPAN
                </title>
            </Helmet>
            <main className="americas-container nml-container">
                {!pageId ? <Nml /> : <NmlSubPage pageId={pageId} />}
            </main>
        </>
    );
}

export default NmlContainer;
