import discover_engineering from "../../../../assets/images/americas/discover_engineering.webp";
import { AMERICAS_PROGRAMS } from "./_programs";
import Engineering_Research from "../../../../assets/images/americas/engineering_research.webp";
import { WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";

const northAmericaSlugs_Engineering = [
    "smyrna-tn",
    "canton-ms",
    "decherd-tn",
    "farmington-hills-mi",
    "silicon-valley-ca"
];
const mexicoSlugs_Engineering = [
    "aguascalientes",
    "cuernavaca",
    "san-pedro-totoltepec"
];

const northAmericaSlugs = [
    "silicon-valley-ca",
    "arizona-test-center-stanfield",
    "farmington-hills-mi",
];
const mexicoSlugs = [
    "san-pedro-totoltepec"
];
const southAmericaSlugs = [
    "santa-isabel-cordoba-argentina"
];

const canadaSlugs = [
    "brampton-ontario",
    "kirkland-quebec",
    "mississauga-ontario"
];
const canada_r_d_Slugs = [
    "mississauga-ontario"
];
const NORTH_AMERICA_PROGRAMS_ENGINEERING = AMERICAS_PROGRAMS.filter(x => northAmericaSlugs_Engineering.indexOf(x.slug) >= 0);

const MEXICO_PROGRAMS_ENGINEERING = AMERICAS_PROGRAMS.filter(x => mexicoSlugs_Engineering.indexOf(x.slug) >= 0);

const CANADA_PROGRAMS_ENGINEERING = AMERICAS_PROGRAMS.filter(x => canadaSlugs.indexOf(x.slug) >= 0);

const CANADA_PROGRAMS_RESEARCH = AMERICAS_PROGRAMS.filter(x => canada_r_d_Slugs.indexOf(x.slug) >= 0);


const NORTH_AMERICA_PROGRAMS = AMERICAS_PROGRAMS.filter(x => northAmericaSlugs.indexOf(x.slug) >= 0);

const MEXICO_PROGRAMS = AMERICAS_PROGRAMS.filter(x => mexicoSlugs.indexOf(x.slug) >= 0);

const SOUTH_AMERICA_PROGRAMS = AMERICAS_PROGRAMS.filter(x => southAmericaSlugs.indexOf(x.slug) >= 0);

const NORTH_AMERICA_LOCATIONS_ENGINEERING = {
    id: 1,
    name: 'North America',
    slug: 'north-america',
    items: NORTH_AMERICA_PROGRAMS_ENGINEERING
}

const MEXICO_LOCATIONS_ENGINEERING = {
    id: 2,
    name: 'Mexico',
    slug: 'mexico',
    items: MEXICO_PROGRAMS_ENGINEERING
}


const NORTH_AMERICA_LOCATIONS = {
    id: 1,
    name: 'North America',
    slug: 'north-america',
    items: NORTH_AMERICA_PROGRAMS
}

const MEXICO_LOCATIONS = {
    id: 2,
    name: 'Mexico',
    slug: 'mexico',
    items: MEXICO_PROGRAMS
}

const SOUTH_AMERICA_LOCATIONS = {
    id: 3,
    name: 'South America',
    slug: 'south-america',
    items: SOUTH_AMERICA_PROGRAMS
}

const CANADA_RESEARCH_LOCATIONS = {
    id: 4,
    name: 'Canada',
    slug: 'canada',
    items: CANADA_PROGRAMS_RESEARCH
}

const CANADA_LOCATIONS = {
    id: 5,
    name: 'Canada',
    slug: 'canada',
    items: CANADA_PROGRAMS_ENGINEERING
}

export const AMERICAS_ENGINEERING_RESEARCH = {
    id: 'engineering-research',
    card_src: discover_engineering,
    card_title: "page.discover.engineering_research",
    card_description: "page.discover.engineering_research_description",
    banner_src: Engineering_Research,
    banner_title: "page.discover.engineering_research",
    banner_description: "Pioneer the Future",
    slug: 'engineering-research',
    href: '/americas/engineering-research',
    name: "page.discover.engineering_research",
    description: "Discover innovation and emerging mobility tech. Collaborate with brilliant minds, create groundbreaking solutions and revolutionize the automotive landscape.",
    categories: [{
        id: 1,
        name: 'Engineering',
        slug: '',
        description: "Engineers at Nissan are at the forefront of innovation, working on cutting-edge projects to develop electric and driver-assist technologies, improve fuel efficiency, enhance safety features, and optimize production processes. With a strong commitment to sustainability and technological advancement, Nissan's engineering teams play a pivotal role in shaping the future of transportation.",
        locations: [
            NORTH_AMERICA_LOCATIONS_ENGINEERING,
            MEXICO_LOCATIONS_ENGINEERING,
            CANADA_LOCATIONS
        ]
    }, {
        id: 2,
        name: 'Research & Design',
        slug: '',
        description: "Nissan's Research and Development (R&D) field is a dynamic hub of innovation and creativity, dedicated to pioneering advancements in automotive technology.  With a commitment to pushing the boundaries of automotive engineering, Nissan's R&D department is instrumental in driving the company's vision for sustainable and cutting-edge mobility solutions.",
        locations: [
            NORTH_AMERICA_LOCATIONS,
            MEXICO_LOCATIONS,
            SOUTH_AMERICA_LOCATIONS,
            CANADA_RESEARCH_LOCATIONS
        ]
    }],
    work_day_link: `${WORKDAY_EN_PATH}locationCountry=a30a87ed25634629aa6c3958aa2b91ea&locationCountry=e2adff9272454660ac4fdb56fc70bb51&locationCountry=bc33aa3152ec42d4995f4791a106ed09&locationCountry=e42ad5eac46d4cc9b367ceaef42577c5&locationCountry=1a29bb1357b240ab99a2fa755cc87c0e&locationCountry=53fe09ef12b9408682a1d2439823f2e0&locationCountry=0eb156ca580c4db786a7894bdaa77450`
}