import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from 'react-router-dom';
import Asean from "../Components/Asean";
import { ASEAN_SUB_PAGES } from "../Config/Static/ProgramDetail/Asean";
import { useEffect } from "react";
import AseanSubPage from "../Components/Asean/id";

function AseanContainer() {
    const navigate = useNavigate();
    const { pageId } = useParams();
    useEffect(() => {
        if (pageId) {
            const isPresent = ASEAN_SUB_PAGES.find(x => x.slug === pageId);
            if (!isPresent)
                navigate("/404-found")
        }
    }, [pageId, navigate])


    return (
        <>
            <Helmet>
                <title>
                    NISSAN MOTOR CORPORATION - GLOBAL CAREER WEBSITE - ASEAN
                </title>
            </Helmet>
            <main className="americas-container asean-container">
                {!pageId ? <Asean /> : <AseanSubPage pageId={pageId} />}
            </main>
        </>
    );
}

export default AseanContainer;
