import { FC } from "react";
import { Container } from "react-bootstrap";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { FormattedMessage } from "react-intl";

import { Anchor } from "../Anchor";
import CardTitle from "../Card/CardTitle";
import { GLOBAL_LOCATIONS } from "../../../../interfaces/Static";

const items = GLOBAL_LOCATIONS;

const GlobalLocation: FC = () => {
  return (
    <div className="global-locations">
      <Container>
        <CardTitle>
          <FormattedMessage id={"page.common.global_locations"} />
        </CardTitle>
        <div className="location-items">
          <Splide
            options={{
              arrows: true,
              pagination: false,
              width: "100%",
              height: "100%",
              type: "loop",
              perPage: 4,
              perMove: 1,
              breakpoints: {
                1000: {
                  perPage: 3,
                },
                800: {
                  perPage: 2,
                },
                600: {
                  perPage: 1,
                },
              },
              gap: 20,
              rewind: true,
              autoplay: true,
              pauseOnHover: false,
              resetProgress: false,
              interval: 5000,
            }}
          >
            {items.map((item, index) => {
              return (
                <SplideSlide key={index}>
                  <Anchor
                    href={item.href}
                    href_jp={item.href_jp}
                    external={true}
                  >
                    <div className="global-location-box">
                      <FormattedMessage id={item.title} />
                    </div>
                  </Anchor>
                </SplideSlide>
              );
            })}
          </Splide>
        </div>
      </Container>
    </div>
  );
};

export default GlobalLocation;
