import { FC } from "react";
import AmericasStoriesCard from "./_card";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { StoriesItem } from "../../../../interfaces/RegionalPage";

interface Props {
    stories: StoriesItem[]
}

const StoriesMobile: FC<Props> = ({ stories }) => {
    return (
        <Splide
            options={{
                arrows: false,
                pagination: true,
                width: "100%",
                type: "loop",
                perPage: 1,
                perMove: 1,
                rewind: false,
                autoplay: true,
                pauseOnHover: false,
                resetProgress: false,
                gap: "15px",
                interval: 5000,
                classes: {
                    pagination: "container custom-pagination",
                },
            }}
        >
            {
                stories.map(story => {
                    return (
                        <SplideSlide key={story.id}>
                            <AmericasStoriesCard {...story} />
                        </SplideSlide>
                    )
                })
            }

        </Splide>
    );
};
export default StoriesMobile;
