import { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import { AnchorButton } from "../../Shared/Common/Anchor/Button";

const CareerAmbition: FC = () =>{
  return (
    <section className="career-ambition">

      <Container>
        <Row>
          <Col lg={7} xs={12}>
          <div className="ambition-cont">
          <h2>
            <FormattedMessage id="page.career_area.ambition.title" />
          </h2>
          <p>
            <FormattedMessage id="page.career_area.ambition.description" />
          </p>
          <AnchorButton
              href="https://www.nissan-global.com/EN/COMPANY/PLAN/AMBITION2030/"
              href_jp="https://www.nissan-global.com/JP/COMPANY/PLAN/AMBITION2030/"
              variant={"primary"}
              external={true}
            >
              <FormattedMessage id="page.career_area.ambition.button"/>
            </AnchorButton>
        </div>
          </Col>
          {/* <Col lg={5} xs={12} className="d-flex align-items-center justify-content-center py-3">            
          <SimpleImage src={AmbitionImage} />
          </Col> */}
        </Row>
       
      </Container>
    </section>
  );
};

export default CareerAmbition;
