import { FC } from "react";
import { Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const RecruitmentBanner = () => {
  return (
    <section className="banner recuritment-scam">
      <div>
        <Container>
          <div className="caption">
            <h1>
              <FormattedMessage id="page.common.recruitement_hiring_banner_title" values={{
                br: (
                  <p>
                    <FormattedMessage
                      id="page.common.recruitement_hiring_banner_s_title"
                    />
                  </p>
                ),
              }} />
            </h1>
            {/* <p>
              <FormattedMessage id="page.common.recruitement_hiring_banner_s_title" />
            </p> */}
          </div>
        </Container>
      </div>

    </section>
  );
};
export default RecruitmentBanner;
