import { FC, useState, useContext, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Outlet, useLocation } from "react-router-dom";
import LocalizationWrapper, { Context } from "../../Config/LocalizationWrapper";

import ScrollToTop from "../../Config/ScrollToTop";

import Footer from "./Footer";
import Header from "./Header";

const Layout: FC = () => {
  const preferredLanguage = localStorage.getItem("locale");
  const [language, setLanguage] = useState(preferredLanguage || "en");
  const updateTheme = (value: string) => {
    setLanguage(value);
  };
  
  const { hash } = useLocation();
  useEffect(() => {
      if (hash) {
        const element = document.getElementById(hash.slice(1));
        const headerOffset = 70;
        if (element) {
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition =
            elementPosition + window.scrollY  - headerOffset;
  
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      }
    }, [hash]);
  return (
    <LocalizationWrapper>
      <HelmetProvider>
        <div className={language}>
          <Header updateTheme={updateTheme} />
          <ScrollToTop />
          <Outlet />
          <Footer />
        </div>
      </HelmetProvider>
    </LocalizationWrapper>
  );
};

export default Layout;
