import { FC, useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { LocationItemProps } from "../../../../../interfaces/RegionalPage";
import SimpleImage from "../../../Media/SimpleImage";
import parse from 'html-react-parser';
import { FormattedMessage } from "react-intl";

interface Props {
    id: number,
    location: LocationItemProps
}

const RegionalLocation: FC<Props> = ({ location }) => {
    // const heightRef = useRef<HTMLDivElement>(null);
    // let [height, setHeight] = useState<any>(null);
    // const handleResize = () => {
    //     setHeight(heightRef.current?.offsetHeight)
    // }
    // useEffect(() => {
    //     setHeight(heightRef.current?.offsetHeight)
    //     window.addEventListener("resize", handleResize)
    // }, []);
    return (
        <Card>
            <SimpleImage className="card-img-top" src={location.src} />
            <div className="card-body">
                <div className="cont-test">
                    <h5 className="card-title">{location.translation ? <FormattedMessage id={location.name} /> : location.name} </h5>
                    <p className="card-text">{location.translation ? <FormattedMessage id={location.description} values={{br: <br/>}} /> : parse(location.description)}</p>
                </div>
            </div>
        </Card>
    );
};
export default RegionalLocation;