import { FC } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import NissanwaylogoImage from "../../../assets/images/nissanway-logo.svg";
import NissanWay1 from "../../../assets/images/nissanway1.webp";
import NissanWay2 from "../../../assets/images/nissanway2.webp";
import NissanWay3 from "../../../assets/images/nissanway3.webp";
import NissanWay4 from "../../../assets/images/nissanway4.webp";
import NissanWay5 from "../../../assets/images/nissanway5.webp";
import SimpleImage from "../../Shared/Media/SimpleImage";
import { FormattedMessage } from "react-intl";

const sliderItems = [
  {
    id: 1,
    title: "page.nissan_way.title_1",
    description: "page.nissan_way.description_1",
    src: NissanWay1,
  },
  {
    id: 2,
    title: "page.nissan_way.title_2",
    description: "page.nissan_way.description_2",
    src: NissanWay2,
  },
  {
    id: 3,
    title: "page.nissan_way.title_3",
    description: "page.nissan_way.description_3",
    src: NissanWay3,
  },
  {
    id: 4,
    title: "page.nissan_way.title_4",
    description: "page.nissan_way.description_4",
    src: NissanWay4,
  },
  {
    id: 5,
    title: "page.nissan_way.title_5",
    description: "page.nissan_way.description_5",
    src: NissanWay5,
  },
];

export const NissanWay: FC = () => {
  return (
    <div className="nissanway-area">
      <Container fluid>
        <Row>
          <Col lg={5} md={12} sm={12} className="nissanway-logo only-desktop">
            <SimpleImage src={NissanwaylogoImage} />
          </Col>
          <Col lg={7} md={12} sm={12} className="p-0">
            <Splide
              options={{
                rewind: true,
                gap: "1rem",
                autoplay: true,
                arrows: false,
                pagination: true
              }}
            >
              {sliderItems.map((item, index) => {
                return (
                  <SplideSlide key={index}>
                    <div className="slider-img">
                      <SimpleImage src={item.src} />
                      <div className="slider-caption">
                        <div className="only-mobile">
                          <SimpleImage src={NissanwaylogoImage} />
                        </div>
                        <h3>
                          <FormattedMessage id={item.title} />
                        </h3>
                        <p>
                          <FormattedMessage id={item.description} />
                        </p>
                      </div>
                    </div>
                  </SplideSlide>
                );
              })}
            </Splide>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
