import { FC } from "react";

import { Heading as Props } from "../../../../interfaces/Common";

const Heading: FC<Props> = ({ type, children }) => {
  const tagFormation = () => {
    switch (type) {
      case "heading":
        return <h1>{children}</h1>;
      case "section-heading":
        return <h2>{children}</h2>;
      case "section-sub-heading":
        return <h3>{children}</h3>;
      case "detailed-sub-heading":
        return <h4>{children}</h4>;
    }
  };

  return <>{tagFormation()}</>;
};

export default Heading;
