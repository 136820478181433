
import AseanStories1 from "../../../assets/images/asean/asean_stories.webp";
import AseanStories2 from "../../../assets/images/asean/asean_stories_1.webp";
import AseanStories3 from "../../../assets/images/asean/asean_stories_2.webp";
import AseanStories4 from "../../../assets/images/asean/asean_stories_3.webp";
import AseanStories5 from "../../../assets/images/asean/asean_stories_4.webp";


export const ASEAN_STORIES = [{
    id: 1,
    src: AseanStories1,
    title: "page.asea.stories_1",
    href: "https://asean.nissannews.com/en/releases/nissan-terra-wins-best-value-ppv-award-from-taja"
}, {
    id: 2,
    src: AseanStories2,
    title: "page.asea.stories_2",
    href: 'https://asean.nissannews.com/en/releases/nissans-full-vehicle-lineup-inspires-future-mobility-at-thailand-international-motor-expo-2023'
}, {
    id: 3,
    src: AseanStories3,
    title: "page.asea.stories_3",
    href: "https://asean.nissannews.com/en/releases/nissans-full-vehicle-lineup-inspires-future-mobility-at-thailand-international-motor-expo-2023"
}, {
    id: 4,
    src: AseanStories4,
    title: "page.asea.stories_4",
    href: "https://asean.nissannews.com/en/releases/nissan-energizes-the-ev-ecosystem-at-the-11th-philippine-electric-vehicle-summit"
}, {
    id: 5,
    src: AseanStories5,
    title: "page.asea.stories_5",
    href: "https://asean.nissannews.com/en/releases/nissan-ph-ranks-7th-in-top-employers-of-the-year"
}]