import { FC } from "react";
import AboutBanner from "../Shared/Banner/About";

import NissanAtGlance from "../Shared/Common/NissanAtGlance";
import BannerImage from "../../assets/images/about-banner.webp";
import Sustainability from "../Shared/Common/Sustainability";
import Innovation from "../Shared/Common/Innovation";
import Stories from "./Stories";
import DrivingInnovation from "./DrivingInnovation";
import Purpose from "./Purpose";
import GlobalLocation from "../Shared/Common/GlobalLocation";

const banners = [
  {
    id: 1,
    src: BannerImage,
  },
];

const About: FC = () => {
  return (
    <>
      <AboutBanner banners={banners} />
      <NissanAtGlance
        external={true}
        name={"page.common.learn_more"}
        href="https://www.nissan-global.com/EN/COMPANY/PROFILE/"
        href_jp="https://www.nissan-global.com/JP/COMPANY/PROFILE/"
      />
      <DrivingInnovation />
      <Purpose />
      <Sustainability />
      <Innovation />
      <div id="nissan-stories">
        <Stories />
      </div>
      <GlobalLocation />
    </>
  );
};
export default About;
