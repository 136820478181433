import Testimonial1 from "../../../../assets/images/testimonial_1.webp";
import Testimonial2 from "../../../../assets/images/testimonial_2.webp";
import Testimonial3 from "../../../../assets/images/testimonial_3.webp";
import Testimonial4 from "../../../../assets/images/testimonial_4.webp";
import Testimonial5 from "../../../../assets/images/testimonial_5.webp";
import Testimonial6 from "../../../../assets/images/testimonial_6.webp";

export const TESTIMONIAL_ITEMS = [
  {
    id: 1,
    profile: Testimonial1,
    empName: "Teri",
    empCountry: "page.testimonials_for_teri_designation",
    detail: "page.testimonials_for_teri",
    japanese: false,
  },
  {
    id: 2,
    profile: Testimonial2,
    empName: "Moe Mekata",
    empCountry: "page.testimonials_for_moe_designation",
    detail: "page.testimonials_for_moe",
    japanese: false,
  },
  {
    id: 3,
    profile: Testimonial3,
    empName: "Clay",
    empCountry: "page.testimonials_for_clay_designation",
    detail: "page.testimonials_for_clay",
    japanese: false,
  },
  {
    id: 4,
    profile: Testimonial4,
    empName: "Fatinfahanah",
    empCountry: "page.testimonials_for_fatinfahanah_designation",
    detail: "page.testimonials_for_fatinfahanah",
    japanese: true,
  },
  {
    id: 5,
    profile: Testimonial5,
    empName: "Dan",
    empCountry: "page.testimonials_for_dan_designation",
    detail: "page.testimonials_for_dan",
    japanese: false,
  },
  {
    id: 6,
    profile: Testimonial6,
    empName: "Christie",
    empCountry: "page.testimonials_for_christie_designation",
    detail: "page.testimonials_for_christie",
    japanese: false,
  },
];

export const TESTIMONIAL_MOBILE_ITEMS = [
  {
    id: 1,
    items: TESTIMONIAL_ITEMS.slice(0, 2),
  },
  {
    id: 2,
    items: TESTIMONIAL_ITEMS.slice(2, 4),
  },
  {
    id: 3,
    items: TESTIMONIAL_ITEMS.slice(4, 6),
  },
];
