import { FC } from "react";
import { Container } from "react-bootstrap";
import DiscoverJourney from "../Shared/RegionalPages/DiscoverJourney";
import DrivingTogether from "../Shared/RegionalPages/DrivingTogether";
import Stories from "../Shared/RegionalPages/Stories";
import Testimonial from "../Shared/RegionalPages/Testimonial";

import { AFRICA_KEY } from "../../utils/_active_regions";
import { AFRICA_DRIVING_TOGETHER, AFRICA_JOURNEY, AFRICA_STORY, AFRICA_TESTIMONIALS } from "./_static";

import banner_1 from "../../assets/images/africa/banner_1.webp";
import banner_2 from "../../assets/images/africa/banner_2.webp";
import banner_3 from "../../assets/images/africa/banner_3.webp";
import banner_4 from "../../assets/images/africa/banner_4.webp";
import banner_5 from "../../assets/images/africa/banner_5.webp";
import banner_6 from "../../assets/images/africa/banner_6.webp";
import ImageTile from "../Shared/Banner/RegionalBanner/ImageTile";

const images = [
    banner_1,
    banner_2,
    banner_3,
    banner_4,
    banner_5,
    banner_6
];
const Africa: FC = () => {
    return (
        <>
            <ImageTile images={images} region={AFRICA_KEY} />
            <Container>
                <div id="discover-journey">
                    <DiscoverJourney  {...AFRICA_JOURNEY} />
                </div>
                <DrivingTogether {...AFRICA_DRIVING_TOGETHER} />
                <Stories {...AFRICA_STORY} />
            </Container>
            <Testimonial items={AFRICA_TESTIMONIALS} />
        </>
    );
};
export default Africa;
