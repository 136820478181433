import Europe from "../Components/Europe";
import { Helmet } from "react-helmet-async";
import { EUROPE_SUB_PAGES } from "../Config/Static/ProgramDetail/Europe";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import EuropeSubPage from "../Components/Europe/id";

function EuropeContainer() {
    const navigate = useNavigate();
    const { pageId } = useParams();
    useEffect(() => {
        if (pageId) {
            const isPresent = EUROPE_SUB_PAGES.find(x => x.slug === pageId);
            if (!isPresent)
                navigate("/404-found")
        }
    }, [pageId, navigate])
    return (
        <>
            <Helmet>
                <title>
                    NISSAN MOTOR CORPORATION - GLOBAL CAREER WEBSITE - EUROPE
                </title>
            </Helmet>
            <main className="americas-container europes-container">
                {!pageId ? <Europe /> : <EuropeSubPage pageId={pageId} />}
            </main>
        </>
    );
}

export default EuropeContainer;
