import { FC } from "react";

import { Row, Col } from "react-bootstrap";

import StoriesCard from "./_card";
import { StoriesItem } from "../../../../interfaces/RegionalPage";
interface Props {
    stories: StoriesItem[]
}
const StoriesDesktop: FC<Props> = ({stories}) => {
    return (
        <Row className="no-gutters america-stories-card">
            <Col lg={4}>
                <StoriesCard {...stories[0]} />
                <StoriesCard {...stories[1]} />
            </Col>
            <Col lg={4}>
                <StoriesCard {...stories[2]} />
            </Col>
            <Col lg={4}>
                <StoriesCard {...stories[3]} />
                <StoriesCard {...stories[4]} />
            </Col>
        </Row>
    );
};
export default StoriesDesktop;
