import { FC } from "react";
import { Container } from "react-bootstrap";

import DiscoverJourney from "../Shared/RegionalPages/DiscoverJourney";
import DrivingTogether from "../Shared/RegionalPages/DrivingTogether";
import EmployeeLoves from "../Shared/RegionalPages/EmployeeLoves";
import Stories from "../Shared/RegionalPages/Stories";

import { AUSTRALIA_NEWZEALAND_STORIES } from "../../Config/Static/Stories/oceania-stories";
import { AUSTRALIA_NEWZEALAND_KEY } from "../../utils/_active_regions";
import { DRIVING_TOGETHER, AUSTRALIA_NEWZEALAND_JOURNEY, AUSTRALIA_NEWZEALAND_TESTIMONIALS } from "./_static";

import banner_1 from "../../assets/images/oceania/banner_1.webp";
import banner_2 from "../../assets/images/oceania/banner_2.webp";
import banner_3 from "../../assets/images/oceania/banner_3.webp";
import banner_4 from "../../assets/images/oceania/banner_4.webp";
import banner_5 from "../../assets/images/oceania/banner_5.webp";
import banner_6 from "../../assets/images/oceania/banner_6.webp";
import ImageTile from "../Shared/Banner/RegionalBanner/ImageTile";
import Testimonial from "../Shared/RegionalPages/Testimonial";

const images = [
    banner_1,
    banner_2,
    banner_3,
    banner_4,
    banner_5,
    banner_6
];


const STORIES_PROPS = {
    title: "Stories from Australia & New Zealand",
    items: AUSTRALIA_NEWZEALAND_STORIES,
    href: "https://australia.nissannews.com/en-AU",
    href_jp: "https://australia.nissannews.com/en-AU/"
}

const Oceania: FC = () => {
    return (
        <>
            <ImageTile
                images={images}
                region={AUSTRALIA_NEWZEALAND_KEY}
                title={"Powering your future"}
                description={"Daring to do what others don’t"}
            />
            <Container>
                <div id="discover-journey">
                    <DiscoverJourney  {...AUSTRALIA_NEWZEALAND_JOURNEY} />
                </div>
                <DrivingTogether {...DRIVING_TOGETHER} />
                <EmployeeLoves region={AUSTRALIA_NEWZEALAND_KEY} />
                <Stories {...STORIES_PROPS} />
                <Testimonial items={AUSTRALIA_NEWZEALAND_TESTIMONIALS} interval={8000} />
            </Container>
        </>
    );
};
export default Oceania;
