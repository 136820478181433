import { FC } from "react";
import { Row, Col } from "react-bootstrap";

import TimetoApply1 from "../../../../assets/images/americas/time_to_apply_1.webp";
import TimetoApply2 from "../../../../assets/images/americas/time_to_apply_2.webp";
import TimetoApply3 from "../../../../assets/images/americas/time_to_apply_3.webp";
import { AnchorButton } from "../../Common/Anchor/Button";
import Heading from "../../Common/Heading";
import SimpleImage from "../../Media/SimpleImage";
import { FormattedMessage } from "react-intl";
interface Props {
    url?: string;
    href_jp?: string;
}
const TimetoApply: FC<Props> = ({ url, href_jp }) => {
    return (
        <section>
            {
                url && (
                    <div className="time-to-apply">
                        <Heading type="section-heading">
                            <FormattedMessage id="page.common.program.time_to_apply" />
                        </Heading>
                        <p className="my-3"><FormattedMessage id="page.common.program.are_you_ready" /></p>
                        <AnchorButton variant={"secondary"} external={true} ewhite={true} href={url} href_jp={href_jp}>
                        <FormattedMessage id="page.common.program.lets_go" />
                        </AnchorButton>
                    </div>
                )
            } 
            <Row className="footer-banner no-gutters">
                <Col><SimpleImage src={TimetoApply1} /></Col>
                <Col><SimpleImage src={TimetoApply2} /></Col>
                <Col><SimpleImage src={TimetoApply3} /></Col>
            </Row>
        </section>
    );
};
export default TimetoApply;
