import { FC } from "react";
import { Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import SimpleImage from "../../Media/SimpleImage";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { EmployeeLoveMobileItem } from "./_interface";
interface Props {
    items: EmployeeLoveMobileItem[];
}

const EmployeeLovesMobile: FC<Props> = ({ items }) => {

    return (
        <Splide
            options={{
                arrows: false,
                pagination: true,
                width: "100%",
                type: "loop",
                perPage: 1,
                perMove: 1,
                rewind: false,
                autoplay: true,
                pauseOnHover: false,
                resetProgress: false,
                gap: "15px",
                interval: 5000,
                classes: {
                    pagination: "container custom-pagination",
                },
            }}
        >
            {
                items && items.length > 0 && items.map(image => {
                    return (
                        <SplideSlide key={image.id}>
                            <Row>
                                {image.mobile_items.map((item, index) => {
                                    return (
                                        <Col lg={4} md={6} xs={12} key={index}>

                                            <div className="employee-card">
                                                <SimpleImage src={item.src} />
                                                <div className="card-title">
                                                    <FormattedMessage id={item.title} />
                                                </div>
                                                <p className="card-description mb-0">
                                                    <FormattedMessage id={item.description} />
                                                </p>
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>

                        </SplideSlide>
                    )
                })
            }
        </Splide>
    );
};
export default EmployeeLovesMobile;
