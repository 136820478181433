import { FC } from "react";
import { Row, Col, Container } from "react-bootstrap";

import AchieveoneImage from "../../../assets/images/aw1.png";
import AchievetwoImage from "../../../assets/images/aw2.png";
import AchievethreeImage from "../../../assets/images/aw3.png";
import AchievefourImage from "../../../assets/images/aw4.png";
import AchievefiveImage from "../../../assets/images/aw5.png";
import AchievesixImage from "../../../assets/images/aw6.png";
import SimpleImage from "../../Shared/Media/SimpleImage";

const awards = [
  {
    id: 1,
    src: AchieveoneImage,
  },
  {
    id: 2,
    src: AchievetwoImage,
  },
  {
    id: 3,
    src: AchievethreeImage,
  },
  {
    id: 4,
    src: AchievefourImage,
  },
  {
    id: 5,
    src: AchievefiveImage,
  },
  {
    id: 6,
    src: AchievesixImage,
  },
];

export const Achievement: FC = () => {
  return (
    <div className="achivement-area">
      <Container>
        <Row className="justify-content-center">
          {awards.map((key, index) => {
            return (
              <Col
                key={index}
                lg={2}
                xs={4}
                className="d-flex align-items-center justify-content-center"
              >
                <SimpleImage src={key.src} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};
