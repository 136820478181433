import { FC } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { Container, Row, Col } from "react-bootstrap";
import CardHeader from "../Card/CardHeader";
import TestimonialsDesktop from "./_desktop";
import TestimonialsMobile from "./_mobile";
 

interface Props {
  title: string;
  href?: string;
  anchorText?: string;
}
const Testimonials: FC<Props> = (props) => {
  return (
    <div className="meetpeople-area">
      <Container>
        <CardHeader {...props}></CardHeader>
        <Row>
          <Col lg={12}>
            {isMobile && !isTablet ? (
              <TestimonialsMobile  />
            ) : (
              <TestimonialsDesktop  />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Testimonials;
