import { FC } from "react";
import NewsCard from "../Shared/Common/Card/NewsCard";

import Innovation1 from "../../assets/images/innovation_1.webp";
import ClimateChange from "../../assets/images/climate_change.webp";
import Sustainability from "../../assets/images/km_sustainability.webp";

const news_card_props = {
  headerItems: {
    title: "page.common.more_from_nissan",
    href: "https://www.nissan-global.com/EN/",
    href_jp: "https://www.nissan-global.com/JP/",
    anchorText: "page.common.see_more",
    variant: "primary-outline",
    external: true,
    mobileView: true
  },
  items: [
    {
      id: 1,
      name: "page.common.electrification",
      description: "page.innovation.description_1",
      src: Innovation1,
      href: "https://www.nissan-global.com/EN/INNOVATION/TECHNOLOGY/ELECTRIFICATION/ ",
      href_jp: "https://www.nissan-global.com/JP/INNOVATION/TECHNOLOGY/ELECTRIFICATION/ "
    },
    {
      id: 2,
      name: "page.common.climate_change",
      description: "page.know_more.description_2",
      src: ClimateChange,
      href: "https://www.nissan-global.com/EN/SUSTAINABILITY/ENVIRONMENT/GREENPROGRAM/CLIMATE/",
      href_jp: "https://www.nissan-global.com/JP/SUSTAINABILITY/ENVIRONMENT/GREENPROGRAM/CLIMATE/"
    },
    {
      id: 3,
      name: "page.common.sustainability",
      description: "page.know_more.description_3",
      src: Sustainability,
      href: "https://www.nissan-global.com/EN/SUSTAINABILITY/",
      href_jp: "https://www.nissan-global.com/JP/SUSTAINABILITY/"
    },
  ],
};
const Card: FC = () => {
  return (
    <div className="innovation-area">
      <NewsCard {...news_card_props} />
    </div>
  );
};
export default Card;
