import { FC } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DiversityImage from "../../../assets/images/diversity.webp";
import { AnchorButton } from "../../Shared/Common/Anchor/Button";
import Heading from "../../Shared/Common/Heading";
import SimpleImage from "../../Shared/Media/SimpleImage";
export const Diversity: FC = () => {
  return (
    <>
      <div className="diversity-area">
        <Container>
          <Heading type="section-heading">
            <FormattedMessage id="page.home.diversity_equity_inclusion" />
          </Heading>
          <Row>
            <Col lg={6} className="diversity-img">
              <SimpleImage src={DiversityImage} />
            </Col>
            <Col lg={6} className="diversity-cont">
              <Heading type="section-sub-heading">
                <FormattedMessage id="page.home.diversity.heading" />
              </Heading>
              <p>
              <FormattedMessage id="page.home.diversity.description" />
              </p>
              <AnchorButton
                href="https://www.nissan-global.com/EN/SUSTAINABILITY/SOCIAL/DIVERSITY/"
                href_jp="https://www.nissan-global.com/JP/SUSTAINABILITY/SOCIAL/DIVERSITY/"
                variant={"primary-outline"}
                external={true}
              >
                <FormattedMessage id="page.common.learn_more" />
              </AnchorButton>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
