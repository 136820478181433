import discover_engineering from "../../../../assets/images/americas/discover_engineering.webp";
import Engineering_Research from "../../../../assets/images/americas/engineering_research.webp";
import { WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";
import { EUROPE_PROGRAM } from "./_programs";
const spainSlugs = [
    'spain-barcelona-r&d',
]
const ukSlugs = [
    'cranfield',
    'paddington-london',
]

const UK_EUROPE_PROGRAMS = EUROPE_PROGRAM.filter(x => ukSlugs.indexOf(x.slug) >= 0);
const SPAIN_EUROPE_PROGRAMS = EUROPE_PROGRAM.filter(x => spainSlugs.indexOf(x.slug) >= 0);

const UK_EUROPE_LOCATIONS = {
    id: 1,
    name: 'UK',
    slug: 'uk',
    items: UK_EUROPE_PROGRAMS
}

const SPAIN_EUROPE_LOCATIONS = {
    id: 2,
    name: 'Spain',
    slug: 'spain',
    items: SPAIN_EUROPE_PROGRAMS
}


export const EUROPE_ENGINEERING_RESEARCH = {
    id: 'engineering-research',
    card_src: discover_engineering,
    card_title: "page.discover.engineering_research",
    card_description: "page.discover.engineering_research_description",
    banner_src: Engineering_Research,
    banner_title: "page.discover.engineering_research",
    banner_description: "Pioneer the Future",
    slug: 'engineering-research',
    href: '/europe/engineering-research',
    name: "page.discover.engineering_research",
    description: "Discover innovation and emerging mobility tech. Collaborate with brilliant minds, creating groundbreaking solutions from electric vehicles to autonomy, revolutionizing the automotive landscape.",
    locations: [
        SPAIN_EUROPE_LOCATIONS,
        UK_EUROPE_LOCATIONS,

    ],
    work_day_link: `${WORKDAY_EN_PATH}locationCountry=a04ea128f43a42e59b1e6a19e8f0b374&locationCountry=49ab063f422741e2aef271de00efeac8&locationCountry=038b0482bfea403abb61c9bcc3d7eb60&locationCountry=0afb2fa656da42e8bfb6d47bd24a26fa&locationCountry=54c5b6971ffb4bf0b116fe7651ec789a&locationCountry=dcc5b7608d8644b3a93716604e78e995&locationCountry=29247e57dbaf46fb855b224e03170bc7&locationCountry=bd34c524a6a04ae6915f5d96fa086199&locationCountry=9db257f5937e4421b2fac64eec6832f8&locationCountry=9696868b09c64d52a62ee13b052383cc&locationCountry=131d5ac7e3ee4d7b962bdc96e498e412&locationCountry=8cd04a563fd94da7b06857a79faaf815&locationCountry=a4051ef996ac40778d4c79e3f2dedfd2&locationCountry=1c026f3b1b8640d8bdfcb95466663e4d&locationCountry=8a0328effd25491fb8e6a08801f08e94&locationCountry=d07f8ca8625e4345b98a91d0558b872a&locationCountry=2e8c5034deb045d49315417c347472ca&locationCountry=6a800a4736884df5826858d435650f45&locationCountry=c2e3bac5bbbb47b29dfc6e8b56a1586e`
}