import { FC } from "react";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import SimpleImage from "../../Shared/Media/SimpleImage";
import ArrowImage from "../../../assets/images/explore_arrow.svg";
import { Anchor } from "../../Shared/Common/Anchor";
interface Props {
  href: string;
  src: string;
  src_detail: string;
  title: string;
  description: string;
  isDetailPage?: boolean
}
const CorporateAreaCard: FC<Props> = ({ href, title, description, src, src_detail, isDetailPage }) => {
  return (
    <Card className={`${!isDetailPage ? "corporate-page-card" : ""}`}>
      <span className={`${isDetailPage ? "bg-img" : ""}`}>
        <SimpleImage src={isDetailPage ? src_detail : src} />
      </span>
      <div className="card-details">
        {isDetailPage && (
          <>
            <p>
              <FormattedMessage id={description} />
            </p>
          </>
        )}
      </div>
      {isDetailPage && (
      <Anchor href={href} external={true}>
        <div className="card-footer">
          <div className="card-footer-details">
            <span><FormattedMessage id="page.common.apply" /></span>
            <SimpleImage src={ArrowImage} />
          </div>
        </div>
      </Anchor>
        )}
      <div className="card-title">
        <h2>
          <FormattedMessage id={title} />
        </h2>
        {!isDetailPage && (
          <SimpleImage src={ArrowImage} />
        )}
      </div>
    </Card>
  );
};
export default CorporateAreaCard;
