import { FC } from "react";
import { Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import SimpleImage from "../../Media/SimpleImage";
import { EmployeeLoveItem } from "./_interface";
interface Props {
    items: EmployeeLoveItem[]
}

const EmployeeLovesDesktop: FC<Props> = ({ items }) => {
    return (
        <Row>
            {
                items && items.length > 0 && items.map(image => {
                    return (
                        <Col key={image.id} lg={4}>
                            <div className="employee-card">
                                <SimpleImage src={image.src} />
                                <div className="card-title">
                                    <FormattedMessage id={image.title} />
                                </div>
                                <p className="card-description mb-0"><FormattedMessage id={image.description} /></p>
                            </div>
                        </Col>
                    )
                })
            }
        </Row>
    );
};
export default EmployeeLovesDesktop;
