import { AUSTRALIA_NEWZEALAND_PAGE, WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";
import casting_plant from "../../../../assets/images/oceania/casting_plant.webp";
import casting_plant_banner from "../../../../assets/images/oceania/casting_plant_banner.webp";
import { AUSTRALIA_NEWZEALAND_PROGRAM } from "./_programs";
const ANZ_LOCATIONS = {
    id: 1,
    name: 'Australia',
    slug: 'dandenong-victoria',
    items: AUSTRALIA_NEWZEALAND_PROGRAM.filter(x => ["dandenong-victoria"].indexOf(x.slug) >= 0)
}
export const CASTING_PLANT = {
    id: 'casting_plant',
    card_src: casting_plant,
    card_title: "page.oceania.casting_plant",
    card_description: "page.oceania.casting_plant_description",
    banner_src: casting_plant_banner,
    banner_title: "page.oceania.casting_plant",
    banner_description: "Australia's world leading manufacturing plant",
    slug: 'casting-plant',
    href: `${AUSTRALIA_NEWZEALAND_PAGE}/casting-plant`,
    name: "page.oceania.casting_plant",
    description: "Nissan Casting Australia manufactures and distributes High Pressure Die Castings (HPDC). These are specialist metal parts designed for use in various Nissan Alliance vehicles distributed to 12 manufacturing plants around the Nissan world with the Kangaroo stamp proudly prominent. We are very proud to be the only on-going and leading edge car manufacturer in Australia.  We produce many of the parts for our EV cars and our precision engineering has resulted in many global awards.   Engineering, procuement and project management opportunities are on-going and open up pathways for Global manufacturing careers.",
    categories: [{
        id: 1,
        name: 'Manufacturing Excellence',
        slug: 'manufacturing_excellence',
        description: "The casting plant has operated for 40 years adapting and growing with every new generation of technology. Our Casting plant is recognised for its world leading enginerring solutions and quality. We take great pride in every part manufactured at our South Dandenong Casting Plant.  Our focus on continuous improvement of manufacturing processes and systems to increase safety and improve quality has delivered Global awards and recognition.",
        locations: [ANZ_LOCATIONS]
    },{
        id: 2,
        name: 'Starting Out',
        slug: 'starting_out',
        description: "You may currently be looking for an Engineering Internship or recently graduated and seeking that first step in your career, then our Casting Plant has a rich history of developing the best and brightest.",
        locations: [ANZ_LOCATIONS]
    },{
        id: 3,
        name: 'Specialist Areas',
        slug: 'specialist_areas',
        description: "We have numerous opportunities for experienced Manufacturing / Process Engineers, Maintenance / Automation Engineers, Quality Engineers, Design Engineers, Tooling Engineers and Safety Engineer.",
        locations: [ANZ_LOCATIONS]
    },{
        id: 4,
        name: 'Career pathways',
        slug: 'career_pathways',
        description: "As part of Nissan's Global Manufacturing (Monozukuri) Function there are many opportunities to engage with colleagues from around the world and be part of leading edge projects and development.",
        locations: [ANZ_LOCATIONS]
    }],
    work_day_link: `https://www.livehire.com/careers/nissan/jobs`
}