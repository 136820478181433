import { FC } from "react";
import { Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import SimpleImage from "../../Media/SimpleImage";
import Heading from "../../Common/Heading";
import { AnchorButton } from "../../Common/Anchor/Button";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";


interface Props {
    heading: string;
    title: string;
    description: string;
    href: string;
    href_jp: string;
    disableLink?: boolean;
    src: string[];
    detailed_description: string;
}

const DrivingTogether: FC<Props> = ({ heading, title, description, href, href_jp, src, detailed_description, disableLink }) => {
    return (
        <section className="driving-together">
            <Row className="align-items-center mb-3">
                <Col lg={5}>
                    <Heading type="detailed-sub-heading">
                        <FormattedMessage id={heading} />
                    </Heading>
                    <Heading type="section-heading">
                        <FormattedMessage id={title} />
                    </Heading>
                </Col>
                <Col lg={7}>
                    <Row className="align-items-center">
                        <Col lg={!disableLink ? 9 : 12}>
                            <p>
                                <FormattedMessage id={description} />
                            </p>
                        </Col>
                        {
                            !disableLink && (
                                <Col lg={3} className="d-flex justify-content-md-start justify-content-lg-end">
                                    <AnchorButton
                                        href={href}
                                        href_jp={href_jp}
                                        variant={"primary-outline"}
                                        external={true}
                                    >
                                        <FormattedMessage id="page.common.explore" />
                                    </AnchorButton>
                                </Col>
                            )
                        }

                    </Row>

                </Col>
            </Row>

            <Splide
                options={{
                    arrows: false,
                    pagination: false,
                    controls: false,
                    width: "100%",
                    height: "100%",
                    perPage: 3,
                    type: 'loop',
                    drag: 'free',
                    focus: 'center',
                    autoScroll: {
                        speed: 0.5,
                    },
                }}
                extensions={{ AutoScroll }}
            >
                {
                    src.map((img, index) => {
                        return (
                            <SplideSlide key={index}>
                                <SimpleImage src={img} />
                            </SplideSlide>
                        )
                    })
                }
            </Splide>
            <Row className="mt-4">
                <p className="m-0">
                    <FormattedMessage id={detailed_description} values={{
                          br: <br />,
                        b: (chunks) => <strong  >{chunks}</strong>
                    }} />
                </p>
            </Row>
        </section>
    );
};
export default DrivingTogether;
