import discover_engineering from "../../../../assets/images/asean/discover_engineering.webp";
import Engineering_Research from "../../../../assets/images/asean/engineering_research_banner.webp";
import { WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";
import { ASEAN_PROGRAMS } from "./_programs";


const thailand_rd_slugs = [
    "samut-prakan-tha-5"
];

const THAILAND_RD_PROGRAMS = ASEAN_PROGRAMS.filter(x => thailand_rd_slugs.indexOf(x.slug) >= 0);

const THAILAND_RD_LOCATIONS = {
    id: 1,
    name: 'Thailand',
    slug: 'thailand',
    items: THAILAND_RD_PROGRAMS
}

export const ASEAN_ENGINEERING_RESEARCH = {
    id: 'engineering-research',
    card_src: discover_engineering,
    card_title: "page.asea.program.research_and_development",
    card_description: "page.discover.engineering_research_description",
    banner_src: Engineering_Research,
    banner_title: "page.asea.program.research_and_development",
    banner_description: "Pioneer the Future",
    slug: 'research-and-development',
    href: '/asean/research-and-development',
    name: "page.asea.program.research_and_development",
    description: "Research & Development function is at the forefront of automotive innovation. We are dedicated to pushing the boundaries of technology through systematic research, experimentation, and continuous innovation. Join us in creating the future of mobility, where we design high-value, high-quality vehicle models, enhance existing features, and stay ahead in the dynamic landscape of the automotive industry. Be part of a team that's driving innovation to enrich people's live.",
    locations: [
        THAILAND_RD_LOCATIONS
    ],
    work_day_link: `${WORKDAY_EN_PATH}locationCountry=b31234dbcdda4da9ba8fa073c5944e36&locationCountry=e56f1daf83e04bacae794ba5c5593560&locationCountry=80938777cac5440fab50d729f9634969&locationCountry=873d0f604e3b458c990cb4d83a5c0f14`
}