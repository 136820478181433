import { FC, useState } from "react";
import { Container } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { isDesktop, isTablet } from "react-device-detect";

import { Banner } from "../../../../interfaces/Banner";
import { RegionalItems, RegionalItemsJapan, RegionalItemType } from "../../../../interfaces/Static";
import { Anchor } from "../../Common/Anchor";
import { Typeahead } from "react-bootstrap-typeahead";
import FloatingButton from "../FloatingButton";

const HomeBanner: FC<Banner> = ({ banners }) => {
  const intl = useIntl();
  const { locale } = intl;
  const [region, setRegion] = useState("");
  const onRegionChange = (selected: Array<Object | string>) => {
    if (selected && selected.length > 0) {
      const item = selected[0] as RegionalItemType;
      if (item && item.key) {
        setRegion(item.key);
      }
    }
  };
  const type_your_country = intl.formatMessage({
    id: "page.common.type_your_country",
  });
  return (
    <section className="banner">
      {banners &&
        banners.length > 0 &&
        banners.map((item, index) => {
          return (
            <div key={index}>
              <video playsInline muted={true} loop={true} autoPlay={true}>
                <source src={item.src} type="video/mp4" />
              </video>

              <Container>
                <div className="caption">
                  <h1>
                    <FormattedMessage id="page.home.banner.title" />
                  </h1>
                  <p>
                    <FormattedMessage id="page.home.banner.description" />
                  </p>

                  {(isDesktop || isTablet) && (
                    <div className="input-field">
                      <Typeahead
                        id="banner-dropdown"
                        onChange={onRegionChange}
                        options={ locale === "en" ? RegionalItems : RegionalItemsJapan}
                        inputProps={{
                          className: "banner-multi-select",
                        }}
                        placeholder={type_your_country}
                      />
                      {!region ? (
                        <button
                          disabled={!region}
                          className="btn btn-secondary"
                        >
                          <FormattedMessage id="page.common.select" />
                        </button>
                      ) : (
                        <Anchor href={region} external={true}>
                          <button
                            disabled={!region}
                            className="btn btn-secondary"
                          >
                            <FormattedMessage id="page.common.select" />
                          </button>
                        </Anchor>
                      )}
                    </div>
                  )}
                </div>
              </Container>
            </div>
          );
        })}

      <FloatingButton />
    </section>
  );
};

export default HomeBanner;
