import { FC } from "react";
import MeetourpeopleAreaBanner from "../Shared/Banner/Meetourpeople";
import Testimonials from "../Shared/Common/Testimonials";
import { LifeatNissan } from "./LifeatNissan";
import Nissanfamily from "./Nissanfamily";
import NissanPeoples from "./NissanPeoples";
import PeopleVideos from "./Peoplevideos";
import { MeetOurLeader } from "./MeetOurLeader";
const headerItems = {
  title: "page.common.testimonial",
};
const MeetOurPeople: FC = () => {
  return (
    <>
      <MeetourpeopleAreaBanner banners={[]} />
      <NissanPeoples />
      <Nissanfamily />
      <PeopleVideos />
      <Testimonials {...headerItems} />
      <MeetOurLeader />
      <LifeatNissan />
    </>
  );
};

export default MeetOurPeople;
