import { FC } from 'react';
import { LocationProps } from '../../../../interfaces/RegionalPage';
import RegionalLocation from '../Card/RegionalLocation';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Col, Row } from 'react-bootstrap';

interface Props {
    location: LocationProps
}

const SliderTabPane: FC<Props> = ({ location }) => {

    const Locations = (location: LocationProps) => {
        const resultsRender = [];

        for (var i = 0; i < location.items.length; i += 3) {
            resultsRender.push(
                <SplideSlide key={i}>
                    <Row>
                        {
                            location.items.slice(i, i + 3)
                                .map(item => (
                                    <Col lg={4} md={6} sm={12} key={item.id}>
                                        <RegionalLocation id={item.id} location={item} />
                                    </Col>
                                ))
                        }
                    </Row>
                </SplideSlide>
            );
        }

        return (
            <Splide
                options={{
                    arrows: false,
                    pagination: true,
                    width: "100%",
                    height: "100%",
                    perPage: 1,
                    perMove: 1,
                    type: 'loop',
                    gap: 20,
                    rewind: false,
                    autoplay: true,
                    pauseOnHover: true,
                    resetProgress: false,
                    interval: 5000,
                }}
                aria-labelledby={location.slug}
            >
                {resultsRender}
            </Splide>
        );
    };

    return (
        <>
            {Locations(location)}
        </>
    );
}

export default SliderTabPane;