import { Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { AnchorButton } from "../../Shared/Common/Anchor/Button";
import SimpleImage from "../../Shared/Media/SimpleImage";
import TrainingDevelopmentImage from "../../../assets/images/nml/training_development.webp"

const TrainingDevelopment = () => {
    return (
        <div className="training-development">
            <h2>
                <FormattedMessage id="page.common.training_and_development" />
            </h2>
            <Row className="align-center">
                <Col className="col-12 col-md-6 section-content">
                        <p>
                            <FormattedMessage id="page.nml.traninig_development_description" />
                        </p>
                        <AnchorButton
                            href={
                                "https://www.nissanmotor.jobs/japan/MC/life-at-nissan/tad.html"
                            }
                            href_jp={
                                "https://www.nissanmotor.jobs/japan/MC/life-at-nissan/tad.html"
                            }
                            variant={"secondary"}
                            external={true}
                            ewhite={true}
                        >
                            <FormattedMessage id="page.common.view_more" />
                        </AnchorButton>
                </Col>
                <Col className="col-12 col-md-6">
                    <SimpleImage src={TrainingDevelopmentImage} />
                </Col>
            </Row>
        </div>

    )
}

export default TrainingDevelopment;