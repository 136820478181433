import programs_corporate_roles from "../../../../assets/images/africa/programs_corporate_roles.webp";
import programs_corporate_roles_banner from "../../../../assets/images/africa/programs_corporate_roles_banner.webp";
import { WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";
import { AFRICA_PROGRAMS } from "./_programs";

const South_AFRICA_LOCATIONS = {
    id: 1,
    name: 'South Africa',
    slug: 'south-africa',
    items: AFRICA_PROGRAMS.filter(x => ["rosslyn"].indexOf(x.slug) >= 0)
}

const EGYPT_LOCATIONS = {
    id: 1,
    name: 'Egypt',
    slug: 'egypt',
    items: AFRICA_PROGRAMS.filter(x => ["giza"].indexOf(x.slug) >= 0)
}

export const AFRICA_CORPORATE_ROLES_PAGES = {
    id: 'corporate_roles',
    card_src: programs_corporate_roles,
    card_title: "page.discover.africa.corporate_roles",
    card_description: "page.discover.africa.corporate_description",
    banner_src: programs_corporate_roles_banner,
    banner_title: "page.discover.africa.corporate_roles",
    banner_description: "Drive the Narrative",
    slug: 'corporate_roles',
    href: '/africa/corporate_roles',
    name: "page.discover.africa.corporate_roles",
    description: "Life at Nissan Africa encompasses everything that inspires both the quality of our vehicles and the quality of our people. Company-wide, you'll find fast learners and self-starting engines as well as people who are quick to embrace change and take an idea into overdrive. At Nissan, minds race. Imagination gets a green light. Each opportunity is the fuel that drives an idea from inspiration to real-life.",
    locations: [
        South_AFRICA_LOCATIONS,
        EGYPT_LOCATIONS
    ],
    work_day_link: `${WORKDAY_EN_PATH}locationCountry=d865e83093ad42319653b08e61f7db49&locationCountry=4db3c26940b944919b83612cc1193eed`
}