import { FC } from "react";
import SimpleImage from "../../Media/SimpleImage";
import { Anchor } from "../../Common/Anchor";
import { FormattedMessage } from "react-intl";

interface Props {
    src: string;
    title: string;
    href: string;
    href_jp?: string;
    external?: boolean;
}
const StoriesCard: FC<Props> = ({ src, title, href, href_jp, external }) => {
    return (

        <div className="stories-card">
            <Anchor href={href} href_jp={href_jp} external={true}>
                <SimpleImage src={src} />
                <div className="card-title">
                    <FormattedMessage id={title} />
                </div>
            </Anchor>
        </div>
    );
};
export default StoriesCard;