import Home from "../Components/Home";
import { Helmet } from "react-helmet-async";

function HomeContainer() {
  return (
    <>
      <Helmet>
        <title>NISSAN MOTOR CORPORATION - GLOBAL CAREER WEBSITE</title>
      </Helmet>
      <main className="home-container">
        <Home />
      </main>
    </>
  );
}

export default HomeContainer;
