import RecruitmentScam from "../Components/RecruitmentScam";
import { Helmet } from "react-helmet-async";
function RecruitmentScamContainer() {
  return (
    <>
      <Helmet>
        <title>
          NISSAN MOTOR CORPORATION - GLOBAL CAREER WEBSITE - RECRUITMENT & HIRING SCAM
        </title>
      </Helmet>
      <main className="about-container">
        <RecruitmentScam />
      </main>
    </>
  );
}

export default RecruitmentScamContainer;
