import dubai_nmef from '../../../../assets/images/middle_east/dubai_nmef.webp';
import jeddah_nksa from '../../../../assets/images/middle_east/jeddah_nksa.webp';

export const MIDDLE_EAST_PROGRAMS = [{
    id: 1,
    name: 'Dubai (NMEF)',
    slug: 'dubai_nmef',
    description: 'Nissan Middle East, a subsidiary of Nissan Motor Corporation, is a leading automotive brand in the region. Guided by the brand’s global purpose of driving innovation to enrich people’s lives, Nissan offers a comprehensive line-up of SUV and sedan models in the region that cater to diverse customer needs. <br /> <br /> Nissan’s presence in the Middle East dates to 1956 when the first Nissan Patrol was imported to Kuwait. The brand then became the first Japanese automotive manufacturer to establish a regional headquarters in Dubai in 1994. Building on a heritage of nearly 70 years in the Gulf and wider Middle East, Nissan Middle East employs over 220 people from diverse backgrounds and cultures working across Nissan Middle East, INFINITI Middle East, and Nissan AMIEO (Africa, Middle East, India, Europe and Oceania). <br /> <br /> Whether customers make the right choices with Market Intelligence, developing cutting-edge innovation as part of our R&D team, or skills training with quality products at the Nissan Academy - Nissan Middle East offers a variety of opportunities for passionate and dedicated talent to be a part of transforming how we live and drive.',
    src: dubai_nmef,
    href: '/'
}, {
    id: 2,
    name: 'Jeddah (NKSA)',
    slug: 'jeddah_nksa',
    description: 'Nissan has a strong presence in the Saudi market with a large network (19 facilities) covering 60% of the total population and many more locations planned within the coming years (FY24/FY25) to reach 75% coverage.<br /> Nissan is deeply committed to the Middle East Market as a key growth market. Nissan Saudi Arabia (NKSA) was established in 2015 as the first Japanese auto brand to establish a fully-fledged presence on the ground in the Kingdom. Today Nissan has a strong presence throughout the region, with 19 models on sale across 10 countries.',
    src: jeddah_nksa,
    href: '/'
}]