import CareerAreaBanner from "../Shared/Banner/CareersArea";
import { Container, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Heading from "../Shared/Common/Heading";
import CardSection from "../CareersArea/CorporateArea/_card_sections";

const CareersAreaDetail = () => {
  return (
    <>
      <CareerAreaBanner banners={[]} />
      <div className="corporate-carrear-area" id="corporate-carrer-area">
         <CardSection isDetailPage={true} />
      </div>
    </>
  );
};
export default CareersAreaDetail;
