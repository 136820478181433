import { SUB_PAGES } from "../../../../interfaces/RegionalPage";
import { AMERICAS_EARLY_HIRES } from "./_early_hires";
import { AMERICAS_ENGINEERING_RESEARCH } from "./_engineering_research";
import { AMERICAS_EXPERIENCED_HIRES } from "./_experienced_hires";
import { AMERICAS_MANUFACTURING_PAGES } from "./_manufacturing";

export const AMERICAS_SUB_PAGES: SUB_PAGES[] = [
    AMERICAS_MANUFACTURING_PAGES,
    AMERICAS_EXPERIENCED_HIRES,
    AMERICAS_EARLY_HIRES,
    AMERICAS_ENGINEERING_RESEARCH
]