import { FC } from "react";
import { Container } from "react-bootstrap";

import ImageTile from "../Shared/Banner/RegionalBanner/ImageTile";
import DiscoverJourney from "../Shared/RegionalPages/DiscoverJourney";
import DrivingTogether from "../Shared/RegionalPages/DrivingTogether";
import Stories from "../Shared/RegionalPages/Stories";

import { MIDDLE_EAST_KEY } from "../../utils/_active_regions";
import { MIDDLEEAST_STORIES } from "../../Config/Static/Stories/middle-east-stories";
import { ME_DRIVING_TOGETHER, MIDDLE_EAST_JOURNEY, MIDDLE_EAST_TESTIMONIALS } from "./_static";

import banner_1 from "../../assets/images/middle_east/banner_1.webp";
import banner_2 from "../../assets/images/middle_east/banner_2.webp";
import banner_3 from "../../assets/images/middle_east/banner_3.webp";
import banner_4 from "../../assets/images/middle_east/banner_4.webp";
import banner_5 from "../../assets/images/middle_east/banner_5.webp";
import banner_6 from "../../assets/images/middle_east/banner_6.webp";
import Testimonial from "../Shared/RegionalPages/Testimonial";

const images = [
    banner_1,
    banner_2,
    banner_3,
    banner_4,
    banner_5,
    banner_6
];

const IMAGETILE_PROPS = {
    images,
    region: MIDDLE_EAST_KEY,
    title: "page.regional.middle_east.banner.title",
    description: "page.regional.middle_east.banner.description"
}

const STORIES_PROPS = {
    title: "Stories from Middle East",
    items: MIDDLEEAST_STORIES,
    href: "https://me.nissanmotornews.com/en/channels/news?selectedtabid=news-releases",
    href_jp: "https://me.nissanmotornews.com/en/channels/news?selectedtabid=news-releases"
}

const MiddleEast: FC = () => {
    return (
        <>
            <ImageTile {...IMAGETILE_PROPS} />
            <Container>
                <div id="discover-journey">
                    <DiscoverJourney  {...MIDDLE_EAST_JOURNEY} />
                </div>
                <DrivingTogether {...ME_DRIVING_TOGETHER} />
                <Stories {...STORIES_PROPS} />
                <Testimonial items={MIDDLE_EAST_TESTIMONIALS} />
            </Container>
        </>
    );
};
export default MiddleEast;
