import amsterdam_netherlands from '../../../../assets/images/ameios/amsterdam-netherlands.webp';
import cantabria_spain from '../../../../assets/images/ameios/cantabria-spain.webp';
import avila_spain from '../../../../assets/images/ameios/avila-spain.webp';
import el_prat_de_llobregat_spain from '../../../../assets/images/ameios/el-prat-de-llobregat-spain.webp';
import sunderland_uk from '../../../../assets/images/ameios/sunderland-uk.webp';
import lutterworth_uk from '../../../../assets/images/ameios/lutterworth-uk.webp';
import sweden_stockholm from '../../../../assets/images/ameios/sweden-stockholm.webp';
import finland_espoo from '../../../../assets/images/ameios/finland-espoo.webp';
import denmark_copenhagen from '../../../../assets/images/ameios/denmark-copenhagen.webp';
import norway_hvalstad from '../../../../assets/images/ameios/norway-hvalstad.webp';
import france_montigny_le_bretonneux from '../../../../assets/images/ameios/france-montigny-le-bretonneux.webp';
import france_montigny_le_bretonneux_2 from '../../../../assets/images/ameios/france-montigny-le-bretonneux-2.webp';
import italy_capena from '../../../../assets/images/ameios/italy-capena.webp';
import hungary_budapest from '../../../../assets/images/ameios/hungary-budapest.webp';
import spain_barcelona from '../../../../assets/images/ameios/spain-barcelona.webp';
import cranfield from '../../../../assets/images/ameios/cranfield.webp';
import paddington_london from '../../../../assets/images/ameios/paddington-london.webp';
import maple_cross_london_uk from '../../../../assets/images/ameios/maple-cross-london-uk.webp';
import ukraine_kiev from '../../../../assets/images/ameios/ukraine-kiev.webp';
import wesseling from '../../../../assets/images/ameios/wesseling.webp';
import istanbul from '../../../../assets/images/ameios/istanbul.webp';

export const EUROPE_PROGRAM = [{
    id: 1,
    name: 'Amsterdam',
    slug: 'amsterdam-netherlands',
    description: 'Carrying out operations for approximately 40 years, NMPC(Nissan Motors Parts Center) is one of the 3 Global Parts Logistic hubs at Nissan which directly or indirectly serves all our European customers. Integrated in a global organization, NMPC ensures the supply of original spare parts mainly in Europe and other regions across the globe. The Amsterdam facility includes a warehouse of 90,000 square meters with capacity of 35 inbound trucks and 35 outbound trucks per day and an office space of 6500 square meters. The total site covers approximately 200,000 square meters. The Aftersales Supply & Distribution (S&D) is basically composed of 4 domains: Warehouse Operations & Transport Inventory, Planning & Control, Quality Assurance, AS Logistics and OEM projects.',
    src: amsterdam_netherlands,
    href: '/'
},
{
    id: 2,
    name: 'Avila',
    slug: 'avila-spain',
    description: 'The Nissan Avila plant manufactures components and parts for the mass production of Renault-Nissan-Mitsubishi Alliance vehicles. It has state-of-the-art technology in the stamping, welding, assembly, and packaging processes for the product development of metal parts and in injection, painting and packaging processes for plastic products. Thanks to the qualification and preparation of its teams, Nissan Avila accompanies its customers throughout the industrialization process.',
    src: avila_spain,
    href: '/'
},
{
    id: 3,
    name: 'Barcelona',
    slug: 'spain-barcelona',
    description: "Nissan Iberia, S.A. (NIBSA) is an RBU in Spain. NIBSA currently has about 157 employees and it has a network of 89 dealers and 19 authorized workshops. Its key functions are sales, after-sales, marketing, dealership network development, business planning & strategy, communication, human resources, finance, IT develop and the business strategy.",
    src: spain_barcelona,
    href: '/'
},
{
    id: 4,
    name: 'Barcelona',
    slug: 'spain-barcelona-r&d',
    description:`Nissan’s centre of excellence for the research and development (R&D) of vehicles manufactured across Nissan’s production plants.
    <br/> Currently, NTCE Barcelona, is evolving from the development of world recognized quality combustion engines towards the exciting new electrified models like the disruptive e-Power technology and the BEVs future line-up.` ,
    src: spain_barcelona,
    href: '/'
},
{
    id: 5,
    name: 'Budapest',
    slug: 'hungary-budapest',
    description: "Nissan Regional Business Services (NRBS) is a diverse and international team that actively contributes towards the development, growth, and efficiency of the business across the AMIEO (Africa, Middle East, India, Europe & Oceania) region. Around 250 employees work here, supporting three main functions: IT, Finance and HR.",
    src: hungary_budapest,
    href: '/'
},
{
    id: 6,
    name: 'Budapest',
    slug: 'budapest-hungary',
    description: "Nissan Regional Business Services Center located in Budapest is part of Nissan AMIEO (Africa Middle East India Europe & Oceania ) organization. NRBS provides support to Accounting; ISIT and HR services for Nissan companies in the AMIEO region.",
    src: hungary_budapest,
    href: '/'
},
{
    id: 7,
    name: 'Cantabria',
    slug: 'cantabria-spain',
    description: `With more than 100 years of experience, Nissan Cantabria casting and machining plant is one of the most important in Europe and manufactures parts for Nissan and Renault Alliance vehicles in Europe, mainly brake discs and steering knuckles.
    <br/> With more than 50 million parts produced, it currently manufactures brake discs and steering knuckles for Nissan's Juke, Qashqai, Micra, e-NV200, LEAF and Navara models, as well as Renault's Kadjar, Megane and Captur models, as well as for other customers and industrial sectors (agriculture, industrial machinery, civil works...), and currently employs more than 500 people. Its state-of-the-art facilities are equipped with the world's most state-of-the-art production systems.`,
    src: cantabria_spain,
    href: '/'
},
{
    id: 8,
    name: 'Capena',
    slug: 'italy-capena',
    description: "Nissan Italia began operating in 1988, dealing with the promotion, distribution and sale of vehicles, spare parts, accessories and services connected with the Nissan brand throughout Italy, through the Authorised Workshops and Dealership Network. The General Headquarters, the Training Centre and the Spare Parts Distribution Centre for Italy are in Capena and Fiano Romano, close to Rome. Capena is a small town located in the Lazio region of Italy. It is situated about 25 kilometers north of Rome.",
    src: italy_capena,
    href: '/'
},
{
    id: 9,
    name: 'Copenhagen',
    slug: 'denmark-copenhagen',
    description: "Nissan Denmark is a part of Nissan Nordic Europe organization, which is an import and marketing company operating for Nissan in Finland, Sweden, Norway and Denmark with approx. 180 employees. At the Danish office there are approx. 15 employees.",
    src: denmark_copenhagen,
    href: '/'
},
{
    id: 10,
    name: 'Cranfield',
    slug: 'cranfield',
    description: "Nissan Technical Centre Europe (NTCE) is based in Cranfield and is the UK site of Nissan's Centre of Excellence for design and development of vehicles manufactured in the European plants. Design & Development, Purchasing, Production Engineering & QA functions are all integrated on this site.",
    src: cranfield,
    href: '/'
},
{
    id: 11,
    name: 'El Prat de Llobregat',
    slug: 'el-prat-de-llobregat-spain',
    description: 'Spain Regional Distribution Center is part of the AMIEO network. Its headquarter is located in Amsterdam (NMPC, BV). S-RDC is Master Depot for service parts of vehicles produced in Spain and parts of all vehicles from exclusive global suppliers in Europe. It distributes parts and accessories to Nissan Dealers in Spain and Portugal (NIBSA) and Southern France (Nissan France) and other warehouses (NMPC, NITA, Volvo Trucks, Renault and Daimler). S-RDC performs the packaging operations for non vendor pack parts.',
    src: el_prat_de_llobregat_spain,
    href: '/'
},
{
    id: 12,
    name: 'Espoo',
    slug: 'finland-espoo',
    description: "Nissan Nordic Europe is an import and marketing company operating for Nissan in Finland, Sweden, Norway and Denmark. The company employs around 180 professionals representing over 20 different nationalities. The dealer network of Nissan Nordic Europe consists of approx.192 dealers and agents. The Headquarters are in Espoo, Finland. In addition to Nissan Nordic Europe HQ, the Finnish Country Organization is located in the same office.",
    src: finland_espoo,
    href: '/'
},
{
    id: 13,
    name: 'Hvalstad',
    slug: 'norway-hvalstad',
    description: "Nissan Norway is a part of Nissan Nordic Europe organization, which is an import and marketing company operating for Nissan in Finland, Sweden, Norway and Denmark with approx. 180 employees. At the Norwegian office there are approx. 20 employees.",
    src: norway_hvalstad,
    href: '/'
},
{
    id: 14,
    name: 'Kiev',
    slug: 'ukraine-kiev',
    description: "Nissan Motor Ukraine began operating in 2005, dealing with the promotion, distribution, and sale of vehicles, spare parts, and accessories through the official dealers network.<br/>Around 30 employees work in marketing, sales, aftersales, dealer network development, and various support functions. The retailer network comprises more than 30 partners in the entire country.",
    src: ukraine_kiev,
    href: '/'
},
{
    id: 15,
    name: 'Lutterworth',
    slug: 'lutterworth-uk',
    description: "Nissan's parts centers are usually very busy places. Some 550 employees work in them every day, dealing with over 600.000 references of which 150.000 on stock and sending thousands of parts across all Europe and worldwide.",
    src: lutterworth_uk,
    href: '/'
},
{
    id: 16,
    name: 'Maple Cross, London',
    slug: 'maple-cross-london-uk',
    description: "NMGB is the Sales & Marketing Headquarters for Nissan in the UK. This entity ensures that the Nissan brand and product portfolio are successfully communicated in order to maintain the strong presence in the UK marketplace. To do this, they ensure that they achieve excellence throughout sales operations & distribution in the UK, and deliver on Nissan's customer promises. Key functions are Aftersales, Marketing, Network Development & Customer Quality and Sales. NMGB has just around 200 employees and is located in Maple Cross, Hertfordshire.",
    src: maple_cross_london_uk,
    href: '/'
},
{
    id: 17,
    name: 'Montigny-le-Bretonneux (NAE)',
    slug: 'france-montigny-le-bretonneux',
    description: "NAE or Nissan Automotive Europe S.A.S is the Headquarters of AMIE (Africa, Middle East, India and Europe) region of Nissan's sales and manufacturing operations. Montigny-le-Bretonneux is located in the township of Montigny-le-Bretonneux part of the district of Versailles.",
    src: france_montigny_le_bretonneux,
    href: '/'
},
{
    id: 18,
    name: 'Montigny-le-Bretonneux (NWEST)',
    slug: 'france-montigny-le-bretonneux-west',
    description: "Nissan Northwest is a wholly owned subsidiary of Nissan Europe that manages the distribution and promotion of Nissan products and services in France. The wide range of products that Nissan offers its French customers goes from city cars to crossovers, to electric and utility vehicles, without forgetting sports cars like the Nissan GT-R supercar.",
    src: france_montigny_le_bretonneux_2,
    href: '/'
},
{
    id: 19,
    name: 'Paddington, London (NDE)',
    slug: 'paddington-london',
    description: "Nissan Design Europe (NDE) is based in Paddington and is the Nissan design studio for Europe, being based in London for the last 20 years and responsible for the design of  models produced in NMUK, such as the Qashqai and Juke. The studio also contributes to Nissan global portfolio of products. Within the studio we are a team of high talented professionals, our areas of expertise are Design (Interior & Exterior), Colour & Materials, UX/UI, Digital & Clay Modelling, PQ, DPM and Operations.",
    src: paddington_london,
    href: '/'
},
{
    id: 20,
    name: 'Paddington, London (NCX)',
    slug: 'paddington-london-ncx',
    description: "Nissan’s Customer Experience organization (NCX) is driving the company’s evolution to the next phase of customer experience: CE 2.0. In CE 2.0, Nissan is evolving to a singular focus on delivering integrated journeys that meet customer needs. It is not just about the Nissan website, or the mobile app, or even the dealership – it is about creating one frictionless experience across online and offline channels to help customers achieve their goals.",
    src: paddington_london,
    href: '/'
},
{
    id: 21,
    name: 'Stockholm',
    slug: 'sweden-stockholm',
    description: "Nissan Sweden is part of the Nissan Nordic Europe organization with approx. 180 employees across the four Nordic countries. As customer behavior and expectations are evolving and the automotive industry is adapting to this and new technology development within electrification, connectivity and autonomous driving, Nissan is piloting a direct to customer to market business model in Sweden and is thus looking to expand the organization with new and exciting roles. At the Swedish office there are approx. 37 employees.",
    src: sweden_stockholm,
    href: '/'
},
{
    id: 22,
    name: 'Sunderland',
    slug: 'sunderland-uk',
    description: "The Sunderland Plant was built in 1984 and started production in 1986. Currently builds the Qashqai, Leaf and Juke Models. More than 6,000 of Nissan's workforce are based here, across a 362,000m2 side of world-leading manufacturing excellence.",
    src: sunderland_uk,
    href: '/'
},
{
    id: 23,
    name: 'Wesseling',
    slug: 'wesseling',
    description: "Nissan Center Europe GmbH, based in Wesseling, Germany is responsible for the marketing and sales of the Nissan model range in Germany. Around 210+ employees work in the areas of marketing, sales, aftersales and dealer network development, as well as in various support functions. The retailer network comprises more than 380 partners in the entire region.",
    src: wesseling,
    href: '/'
},
{
    id: 21,
    name: 'Istanbul',
    slug: 'istanbul',
    description: "With leadership in its DNA, Nissan continues to lead the automotive sector in Turkey since 1993. Aiming to provide maximum benefit to its customers with its wide dealer network, sales and after-sales support and always having a high-quality image in the eyes of Turkish consumers, Nissan brand Turkey distributorship was officially established on 17 December 1993.",
    src: istanbul,
    href: '/'
},
];