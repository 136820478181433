import { FC } from "react";
import FooterTop from "./_top";
import FooterBottom from "./_bottom";
import BacktotopImage from "../../../assets/images/backtotop.svg";
import SimpleImage from "../../Shared/Media/SimpleImage";

const Footer: FC = () => {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <footer>
      <FooterTop />
      <div className="separation">
        <div className="backto-top" onClick={goToTop}>
          <SimpleImage src={BacktotopImage} className="img-fluid" />
        </div>
      </div>
      <FooterBottom />
    </footer>
  );
};

export default Footer;
