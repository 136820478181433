import { AUSTRALIA_NEWZEALAND_PAGE, WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";
import { AUSTRALIA_NEWZEALAND_PROGRAM } from "./_programs";

import motor_business from "../../../../assets/images/oceania/motor_business.webp";
import motor_business_banner from "../../../../assets/images/oceania/motor_business_banner.webp";


const ANZ_LOCATIONS = {
    id: 1,
    name: 'Australia & New Zealand',
    slug: 'melbourne-victoria-nma',
    items: AUSTRALIA_NEWZEALAND_PROGRAM.filter(x => ["melbourne-victoria-nma"].indexOf(x.slug) >= 0)
}

export const MOTOR_BUSINESS = {
    id: 'motor-business',
    card_src: motor_business,
    card_title: "page.oceania.motor_business",
    card_description: "page.oceania.motor_business_description",
    banner_src: motor_business_banner,
    banner_title: "page.oceania.motor_business",
    banner_description: "Prepare to Explore",
    slug: 'motor-business',
    href: `${AUSTRALIA_NEWZEALAND_PAGE}/motor-business`,
    name: "page.oceania.motor_business",
    description: "Our Sales and Marketing Teams bring our award winning range of vehicles to our customers though our extensive Dealer Network.  We have product marketing specialists working with our Regional and Global colleagues to plan years out the range and ensuring we can optimise the Nissan Brand in our highly competitive markets.  Our Sales and Aftersales teams work to ensure our Dealers have the tools they need to provide an outstanding Customer Experience and our local Engineering team have led innovation with after market development of the Patrol and Navara Warrior in partnership with Premcar.  Your future could see you excelling in any of these areas as you develop across our core business of bringing the Excitement  of Innovation to everyone.",
    categories: [{
        id: 1,
        name: 'Established Presence',
        slug: 'established_presence',
        description: "Nissan Motor Co. (Australia) Pty Ltd established its Melbourne Headquarters in 1972. For 20 years, Nissan assembled a variety of vehicles locally but became a full importer of vehicles when local car assembly stopped in 1992. Nissan Australia & NZ remains a Sales and Marketing based organisation with distribution of its vehicle range all over the country to its various franchise partners (authorised Nissan dealers). <br /><br /> “As the only Australian automotive OEM to continue to manufacture in Australia through our casting plant and the third largest OEM employer, Nissan has enormous strength and heritage in the Australian market. We are excited by our future success as we prepare for the introduction of four new models and a total of 11 upcoming product events. We have a clear roadmap to an exciting future and will continue to focus on ensuring that our award-winning Nissan vehicles are perfectly suited to Australian customers.” Andrew Humberstone, Vice President and Managing Director, Nissan Oceania",
        locations: [
            ANZ_LOCATIONS
        ]
    }, {
        id: 2,
        name: 'Where We Operate',
        slug: 'where_we_operate',
        description: "We have strong  local Sales teams based in Perth, Brisbane, Sydney, Melbourne and Auckland supporting our Dealers to deliver a great Customer Experience. <br /><br />Nissan New Zealand Ltd is one of the largest selling car brands in New Zealand and operates as a regional business unit (RBU) of Nissan Australia. It distributes and supports similar models to the Australian market and operates across both the north and south island of New Zealand.",
        locations: [
            ANZ_LOCATIONS
        ]
    }, {
        id: 3,
        name: 'Head Office',
        slug: 'head_office',
        description: "All are supported by a cross-functional team in our Melbourne Head Office where your career can start in Sales, Marketing, Aftersales, Dealer Network or  Corporate Functions (Finance, IT, HR, Corporate Communications and Legal). We have developed strong ties to our regional colleagues across the Middle East and Europe and you could find yourself working on projects that have impact beyond our shores.",
        locations: [
            ANZ_LOCATIONS
        ]
    }],
    work_day_link: `https://www.livehire.com/careers/nissan/jobs`
}