import Experience_Hire from "../../../../assets/images/asean/experiences_hire_banner.webp";
import experienced_hires from "../../../../assets/images/asean/experiences_hire.webp";

import { ASEAN_PROGRAMS } from "./_programs";
import { WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";

const thailand_slugs = [
    "samut-prakan-tha"
];

const THAILAND_PROGRAMS = ASEAN_PROGRAMS.filter(x => thailand_slugs.indexOf(x.slug) >= 0);

const THAILAND_LOCATIONS = {
    id: 1,
    name: 'Thailand',
    slug: 'thailand',
    items: THAILAND_PROGRAMS
}


const THAILAND_ENGINEERING_LOCATIONS = {
    id: 1,
    name: 'Thailand',
    slug: 'thailand',
    items: ASEAN_PROGRAMS.filter(x => ["samut-prakan-tha-3"].indexOf(x.slug) >= 0)
}

const PHILIPPINES_ENGINEERING_LOCATIONS = {
    id: 1,
    name: 'Philippines',
    slug: 'philippines',
    items: ASEAN_PROGRAMS.filter(x => ["manila-phi-3"].indexOf(x.slug) >= 0)
}


const thailand_ms_slugs = [
    "bangkok-tha",
    "samut-prakan-tha-4"
];

const THAILAND_MS_PROGRAMS = ASEAN_PROGRAMS.filter(x => thailand_ms_slugs.indexOf(x.slug) >= 0);

const THAILAND_MS_LOCATIONS = {
    id: 1,
    name: 'Thailand',
    slug: 'thailand',
    items: THAILAND_MS_PROGRAMS
}

const philipines_ms_slugs = [
    "manila-phi-4"
];

const PHILIPPINES_MS_PROGRAMS = ASEAN_PROGRAMS.filter(x => philipines_ms_slugs.indexOf(x.slug) >= 0);

const PHILIPINES_MS_LOCATIONS = {
    id: 1,
    name: 'Philippines',
    slug: 'philippines',
    items: PHILIPPINES_MS_PROGRAMS
}

const thailand_be_slugs = [
    "bangkok-tha-3"
];

const THAILAND_BE_PROGRAMS = ASEAN_PROGRAMS.filter(x => thailand_be_slugs.indexOf(x.slug) >= 0);

const THAILAND_BE_LOCATIONS = {
    id: 1,
    name: 'Thailand',
    slug: 'thailand',
    items: THAILAND_BE_PROGRAMS
}
const philipines_be_slugs = [
    "manila-phi-4"
];
const PHILIPPINES_BE_PROGRAMS = ASEAN_PROGRAMS.filter(x => philipines_be_slugs.indexOf(x.slug) >= 0);

const PHILIPINES_BE_LOCATIONS = {
    id: 1,
    name: 'Philippines',
    slug: 'philippines',
    items: PHILIPPINES_BE_PROGRAMS
}

const indonesia_be_slugs = [
    "jakarta-idn"
];
const INDONESIA_BE_PROGRAMS = ASEAN_PROGRAMS.filter(x => indonesia_be_slugs.indexOf(x.slug) >= 0);

const INDONESIA_BE_LOCATIONS = {
    id: 1,
    name: 'Indonesia',
    slug: 'ondonesia',
    items: INDONESIA_BE_PROGRAMS
}

export const ASEAN_EXPERIENCED_HIRES = {
    id: 'experienced-hires',
    card_src: experienced_hires,
    card_title: "page.discover.experienced_hires",
    card_description: "page.discover.experienced_hires_description",
    banner_src: Experience_Hire,
    banner_title: "page.discover.experienced_hires",
    banner_description: "Drive the Narrative",
    slug: 'experienced-hires',
    href: '/asean/experienced-hires',
    name: "page.discover.experienced_hires",
    description: "Play a vital role across the organization in all areas of the company. Connect and inspire new colleagues, leverage your experience, strategic thinking and expand your areas of expertise.",
    categories: [{
        id: 1,
        name: 'Manufacturing & SCM',
        slug: '',
        description: "Step onto the production floor where teamwork, precision and craftsmanship meet. Contribute to the creation of your favorite vehicles through advanced manufacturing processes, automation, and quality control, ensuring every detail exceeds expectations.",
        locations: [
            THAILAND_LOCATIONS
        ]
    }, {
        id: 2,
        name: 'Engineering',
        slug: '',
        description: "Engineering function is the driving force behind innovation in automotive technologies. From designing to developing and innovating, we are at the forefront of shaping the future of transportation, working on cutting-edge projects to develop high quality of passenger and commercial car with driver-assist technologies, improve fuel efficiency, enhance safety features, and optimize production processes. Be part of a journey that creates a driving experience that is comfortable, enjoyable, and safe.",
        locations: [
            THAILAND_ENGINEERING_LOCATIONS,
            PHILIPPINES_ENGINEERING_LOCATIONS
        ]
    }, {
        id: 3,
        name: 'Marketing & Sales',
        slug: '',
        description: "Marketing & Sales function is responsible for creating strategies to create a positive experience, promote and sell Nissan vehicles and services.  We transcend traditional approaches by deeply understanding customer needs through in-depth market research. We architect marketing campaigns that not only promote and sell Nissan vehicles but also elevate brand visibility and redefine the entire driving experience, extending even into post-sale engagement.",
        locations: [
            THAILAND_MS_LOCATIONS,
            PHILIPINES_MS_LOCATIONS
        ]
    }, {
        id: 4,
        name: 'Business Enablers',
        slug: '',
        description: "Business Enablers go beyond traditional support functions. They encompass dynamic departments like Human Resources, Finance & Accounting, IS/IT, Legal, Communications, Purchasing, and Administration. These pillars of strength are the backbone, ensuring seamless operations and providing the vital infrastructure for Nissan's core business activities. Join us in empowering excellence and driving the success of Nissan's vision.",
        locations: [
            THAILAND_BE_LOCATIONS,
            PHILIPINES_BE_LOCATIONS,
            INDONESIA_BE_LOCATIONS
        ]
    }],
    work_day_link: `${WORKDAY_EN_PATH}locationCountry=b31234dbcdda4da9ba8fa073c5944e36&locationCountry=e56f1daf83e04bacae794ba5c5593560&locationCountry=80938777cac5440fab50d729f9634969&locationCountry=873d0f604e3b458c990cb4d83a5c0f14`
}