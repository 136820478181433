import { FC } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import { TESTIMONIAL_ITEMS } from "./_items";
import TestimonialsCard from "./_card";

const TestimonialsDesktop: FC = () => {
  return (
    <Splide
      options={{
        arrows: false,
        pagination: true,
        width: "100%",
        type: "loop",
        perPage: 3,
        perMove: 3,
        breakpoints: {
          1194: {
            perPage: 2,
            perMove: 2,
          },
          767: {
            perPage: 1,
            perMove: 1,
          },
        },
        gap: "15px",
        rewind: false,
        autoplay: false,
        pauseOnHover: false,
        resetProgress: false,
        interval: 5000,
      }}
    >
      {TESTIMONIAL_ITEMS.map((item, index) => {
        return (
          <SplideSlide key={index}>
            <TestimonialsCard {...item} />
          </SplideSlide>
        );
      })}
    </Splide>
  );
};

export default TestimonialsDesktop;
