import { FC } from "react";

import { SUB_PAGES } from "../../../../../interfaces/RegionalPage";
import { FormattedMessage } from "react-intl";
import SimpleImage from "../../../Media/SimpleImage";
import { Anchor } from "../../Anchor";
import ArrowImage from "../../../../../assets/images/explore_arrow.svg";

interface Props {
    journey: SUB_PAGES,
    isMobile?: boolean
}

const JourneyCard: FC<Props> = ({ journey, isMobile }) => {

    return (
        <div className={`journey-card ${isMobile ? `is-mobile` : ''}`}>
            <Anchor href={journey.href} href_jp={journey.href_jp} external={journey.external}>
                <SimpleImage src={journey.card_src} />
                <div className="card-title">
                    <FormattedMessage id={journey.card_title} />
                    <SimpleImage src={ArrowImage} />
                </div>
                <p className="card-description mb-0"><FormattedMessage id={journey.card_description} /></p>
            </Anchor>
        </div>
    );
};
export default JourneyCard;
