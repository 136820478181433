import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { NML_SEARCHES } from "../Config/Static/NmlJobSearch";
import NmlJobSearch from "../Components/Nml/job_search";

function NmlJobSearchContainer() {
    const navigate = useNavigate();
    const { pageId } = useParams();

    useEffect(() => {
        if (pageId) {
            const isPresent = NML_SEARCHES.find(x => x.key === pageId);
            if (!isPresent)
                navigate("/404-found")
        }
    }, [pageId, navigate])
    return (
        <>
            <Helmet>
                <title>
                    NISSAN MOTOR CORPORATION - GLOBAL CAREER WEBSITE - JAPAN
                </title>
            </Helmet>
            <main className="americas-container nml-container">
                {pageId && <NmlJobSearch pageId={pageId} />}
            </main>
        </>
    );
}

export default NmlJobSearchContainer;
