import { AMERICAS_SUB_PAGES } from "../../Config/Static/ProgramDetail/Americas";
import { AMERICAS_STORIES } from "../../Config/Static/Stories/americas-stories";

import DEI1 from "../../assets/images/americas/dei_1.webp"
import DEI2 from "../../assets/images/americas/dei_2.webp"
import DEI3 from "../../assets/images/americas/dei_3.webp"
import DEI4 from "../../assets/images/americas/dei_4.webp"
import DEI5 from "../../assets/images/americas/dei_5.webp"
import DEI6 from "../../assets/images/americas/dei_6.webp"
import DEI7 from "../../assets/images/americas/dei_7.webp"
import DEI8 from "../../assets/images/americas/dei_8.webp"


import TestimonialsFelixMadrid from "../../assets/images/americas/testimonials-felix-madrid.webp";
import TestimonialsFranciscoNegron from "../../assets/images/americas/testimonials-francisco-negron.webp";
import TestimonialsPetraTester from "../../assets/images/americas/testimonials-petra-tester.webp";


export const AMERICAS_DRIVING_TOGETHER = {
    heading: 'page.common.driving_together',
    title: 'page.common.driving_together_title',
    description: 'page.common.driving_together_description',
    href: 'https://www.nissanusa.com/responsibility/diversity.html/',
    href_jp: 'https://www.nissanusa.com/responsibility/diversity.html/',
    src: [DEI1, DEI2, DEI3, DEI4, DEI5, DEI6, DEI7, DEI8],
    detailed_description: 'page.americas.driving_together_descirption_bottom'
}

export const AMERICAS_TESTIMONIALS = [{
    id: 1,
    userName: 'Felix Madrid',
    userDesignation: 'page.testimonial.americas_designation_1',
    description: "page.testimonial.americas_description_1",
    src: TestimonialsFelixMadrid,
}, {
    id: 2,
    userName: 'Francisco Negron',
    userDesignation: 'page.testimonial.americas_designation_2',
    description: "page.testimonial.americas_description_2",
    src: TestimonialsFranciscoNegron,
}, {
    id: 3,
    userName: 'Petra Tester',
    userDesignation: 'page.testimonial.americas_designation_3',
    description: 'page.testimonial.americas_description_3',
    src: TestimonialsPetraTester,
}];

export const AMERICAS_JOURNEY = {
    heading: "page.discover.your_journey",
    title: "page.discover.your_journey.title",
    description: "page.discover.your_journey.description",
    items: AMERICAS_SUB_PAGES
}

export const AMERICAS_STORY = {
    title: "page.stories.americas_title",
    items: AMERICAS_STORIES,
    href: "https://usa.nissanstories.com/en-US/",
    href_jp: "https://usa.nissanstories.com/en-US/",
}