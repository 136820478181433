import giza from "../../../../assets/images/africa/giza.webp";
import rosslyn from "../../../../assets/images/africa/rosslyn.webp";

export const AFRICA_PROGRAMS = [{
    id: 1,
    name: 'Giza',
    slug: 'giza',
    description: "Operating as a subsidiary of Nissan Motor Limited, Nissan Egypt was established in 2004 as a prominent automotive brand. With over 1100 employees and technicians, the Company strives to achieve the highest standards for its customers by providing safe, distinctive, and innovative products and services. Nissan is the first Japanese car manufacturer to establish a factory in Egypt, located in 6th of October Industrial City in Giza. It is the only global car manufacturer in the Egyptian automotive sector that owns 100% of its factory. Nissan Egypt assembles passenger cars, particularly Sunny and Sentra, with the Egyptian plant serving as the regional hub for exporting passenger cars to Africa. <br /> <br /> Nissan Egypt boasts a widespread network of 16 authorized dealers and 44 sales outlets across Egypt, including 35 showrooms and 25 service centers in various provinces. Leading the passenger car sector in the Egyptian market with its brands Sunny, Sentra, Juke, and Qashqai, Nissan's primary goal is to provide the best experience for its customers.",
    src: giza,
    href: '/'
}, {
    id: 2,
    name: 'Rosslyn',
    slug: 'rosslyn',
    description: 'Nissan South Africa is part of a globally competitive automotive company that is growing its brand here and in Sub Saharan Africa markets. We sell innovative products and build quality cars at our manufacturing hubs in South Africa and Nigeria.',
    src: rosslyn,
    href: '/'
}]