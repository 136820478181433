import DEI1 from "../../assets/images/ameios/dei_1.webp";
import DEI2 from "../../assets/images/ameios/dei_2.webp";
import DEI3 from "../../assets/images/ameios/dei_3.webp";
import DEI4 from "../../assets/images/ameios/dei_4.webp";
import DEI5 from "../../assets/images/ameios/dei_5.webp";
import DEI6 from "../../assets/images/ameios/dei_6.webp";
import DEI7 from "../../assets/images/ameios/dei_7.webp";

import TestimonialManuelAlejandro from "../../assets/images/ameios/testimonial_manuel_alejandro.webp";
import TestimonialKrisztinaLeiner from "../../assets/images/ameios/testimonial-krisztina-leiner.webp";

export const EUROPE_DRIVING_TOGETHER = {
    heading: 'page.common.driving_together',
    title: 'page.common.driving_together_title',
    description: 'page.europe.driving_together_description',
    href: 'https://europe.nissanstories.com/en/releases/fostering-a-safe-space-for-everyone-nissan-amieo-africa-middle-east-india-europe-oceania-shares-its-first-diversity-equity-and-inclusion-dei-report',
    href_jp: 'https://europe.nissanstories.com/en/releases/fostering-a-safe-space-for-everyone-nissan-amieo-africa-middle-east-india-europe-oceania-shares-its-first-diversity-equity-and-inclusion-dei-report',
    src: [DEI1, DEI2, DEI3, DEI4, DEI5, DEI6, DEI7],
    detailed_description: 'page.europe.driving_together_descirption_bottom'
}

export const EUROPE_TESTIMONIALS = [
    {
        id: 1,
        userName: 'Manuel Alejandro',
        userDesignation: 'page.testimonial.europe_designation_2',
        description: 'page.testimonial.europe_description_2',
        src: TestimonialManuelAlejandro,
    }, {
        id: 2,
        userName: 'Krisztina Leiner',
        userDesignation: 'page.testimonial.europe_designation_3',
        description: 'page.testimonial.europe_description_3',
        src: TestimonialKrisztinaLeiner
    }
];