
import samut_prakan_tha from '../../../../assets/images/asean/samut_prakan_tha.webp';
import bangkok_tha from '../../../../assets/images/asean/bangkok_tha.webp';
import manila_phi from '../../../../assets/images/asean/manila_phi.webp';
import jakarta_idn from '../../../../assets/images/asean/jakarta_idn.webp';
import samut_prakan_tha_2 from '../../../../assets/images/asean/samut_prakan_tha_2.webp';
import samut_prakan_tha_3 from '../../../../assets/images/asean/samut_prakan_tha_3.webp';

export const ASEAN_PROGRAMS = [{
    id: 1,
    name: 'Bangkok, THA',
    slug: 'bangkok-tha',
    description: 'Nissan Motor (Thailand) Co., Ltd. (NMT) is the head office of Nissan in Thailand and is the center for manufacturing, domestic sales and export to all over the world. <br /> <br /> Nissan Motor Asia Pacific Co., Ltd. (NMAP) is our primary Research and Development center which conducts research and development business as well as being a center for strategic planning for the Region.',
    src: bangkok_tha,
    href: '/'
}, {
    id: 2,
    name: 'Bangkok, THA',
    slug: 'bangkok-tha-2',
    description: 'Nissan Motor (Thailand) Co., Ltd. (NMT) is the head office of Nissan in Thailand and is the center for manufacturing, domestic sales and export to all over the world.',
    src: bangkok_tha,
    href: '/'
}, {
    id: 3,
    name: 'Bangkok, THA',
    slug: 'bangkok-tha-3',
    description: 'Nissan Motor (Thailand) Co., Ltd. (NMT) is the head office of Nissan in Thailand and is the center for manufacturing, domestic sales and export to all over the world. <br /> <br />Nissan Motor Asia Pacific Co., Ltd. (NMAP) is our primary Research and Development center which conducts research and development business as well as being a center for strategic planning for the Region. <br /> <br /> Nissan Leasing (Thailand) Co., Ltd. (NLTH) provides Auto-Loan and Insurance with a wide range of options to respond to every need of customers.',
    src: bangkok_tha,
    href: '/'
}, {
    id: 4,
    name: 'Jakarta, IDN',
    slug: 'jakarta-idn',
    description: 'PT. Nissan Motor Indonesia (NMI), the head office of Nissan in Indonesia, is located at Pondok Indah Office Tower, which is located in the southern part of the city of Jakarta, a well-known business area.',
    src: jakarta_idn,
    href: '/'
}, {
    id: 5,
    name: 'Manila, PHI',
    slug: 'manila-phi',
    description: 'NISSAN PHILIPPINES, INC. (NPI), is located in Bonifacio Global City (BGC), a bustling district in Taguig, Metro Manila, Philippines. It is a modern city that is home to a variety of multinational corporations, high-end residential areas, and commercial establishments. BGC is known for its well-planned streets, modern architecture, and vibrant lifestyle.',
    src: manila_phi,
    href: '/'
}, {
    id: 6,
    name: 'Manila, PHI',
    slug: 'manila-phi-2',
    description: 'Nissan Motor (Thailand) Co., Ltd. (NMT) is the head office of Nissan in Thailand and is the center for manufacturing, domestic sales and export to all over the world. <br /><br />  Nissan Powertrain (Thailand) Co., Ltd. (NPT) is the manufacturing and Sales unit of engines for Nissan’s factories, both domestic and export to other countries in the region. <br /><br /> SNN Tools & Dies Co., Ltd. (SNN) focuses on manufacturing and sales of dies and fixtures for machines that are used in manufacturing components for automobile factories both domestic and export to over 16 countries.',
    src: manila_phi,
    href: '/'
}, {
    id: 7,
    name: 'Manila, PHI',
    slug: 'manila-phi-3',
    description: "Nissan Philippines, Inc. (NPI), is located in Bonifacio Global City (BGC), a bustling district in Taguig, Metro Manila, Philippines. NPI is Nissan's head office in the Philippines and is the center for sales and distribution in the country.",
    src: manila_phi,
    href: '/'
}, {
    id: 8,
    name: 'Samut Prakan, THA',
    slug: 'samut-prakan-tha',
    description: 'Nissan Motor (Thailand) Co., Ltd. (NMT) is the head office of Nissan in Thailand and is the center for manufacturing, domestic sales and export to all over the world. <br /><br /> Nissan Powertrain (Thailand) Co., Ltd. (NPT) is the manufacturing and Sales unit of engines for Nissan’s factories, both domestic and export to other countries in the region. <br /><br /> SNN Tools & Dies Co., Ltd. (SNN) focuses on manufacturing and sales of dies and fixtures for machines that are used in manufacturing components for automobile factories both domestic and export to over 16 countries.',
    src: samut_prakan_tha,
    href: '/'
}, {
    id: 9,
    name: 'Samut Prakan, THA',
    slug: 'samut-prakan-tha-2',
    description: 'Nissan Motor (Thailand) Co., Ltd. (NMT) is the head office of Nissan in Thailand and is the center for manufacturing, domestic sales and export to all over the world. <br /><br /> Nissan Powertrain (Thailand) Co., Ltd. (NPT) is the manufacturing and Sales unit of engines for Nissan’s factories, both domestic and export to other countries in the region. <br /><br /> SNN Tools & Dies Co., Ltd. (SNN) focuses on manufacturing and sales of dies and fixtures for machines that are used in manufacturing components for automobile factories both domestic and export to over 16 countries.',
    src: samut_prakan_tha_2,
    href: '/'
}, {
    id: 10,
    name: 'Samut Prakan, THA',
    slug: 'samut-prakan-tha-3',
    description: 'Nissan Motor (Thailand) Co., Ltd. (NMT) is the head office of Nissan in Thailand and is the center for manufacturing, domestic sales and export to all over the world.',
    src: samut_prakan_tha_3,
    href: '/'
}, {
    id: 11,
    name: 'Samut Prakan, THA',
    slug: 'samut-prakan-tha-4',
    description: "Nissan Motor (Thailand) Co., Ltd. (NMT) is Nissan's head office in Thailand and is the center for manufacturing, domestic sales and export to many countries all over the world. <br /> <br /> Nissan Motor Asia Pacific Co., Ltd. (NMAP) is our primary Research and Development center which conducts research and development business as well as being a center for strategic planning for the Region.",
    src: samut_prakan_tha_3,
    href: '/'
}, {
    id: 12,
    name: 'Manila, PHI',
    slug: 'manila-phi-4',
    description: "Nissan Philippines, Inc. (NPI), is located in Bonifacio Global City (BGC), a bustling district in Taguig, Metro Manila, Philippines. It is the head office of Nissan in the Philippines, and the center for sales and distribution in the country.",
    src: manila_phi,
    href: '/'
}, {
    id: 12,
    name: 'Samut Prakan, THA',
    slug: 'samut-prakan-tha-5',
    description: "Nissan Motor Asia Pacific Co., Ltd. (NMAP) is our primary Research and Development center which conducts research and development business and is the center for strategic planning for the Region.",
    src: samut_prakan_tha_2,
    href: '/'
}]