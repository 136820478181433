import { FC } from "react";
import { Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import SimpleImage from "../../Shared/Media/SimpleImage";

import Story1 from "../../../assets/images/story-1.webp";
import Story2 from "../../../assets/images/story-2.webp";
import Story3 from "../../../assets/images/story-3.webp";
import Story4 from "../../../assets/images/story-4.webp";
import Story5 from "../../../assets/images/story-5.webp";
import Story6 from "../../../assets/images/story-6.webp";

import { Anchor } from "../../Shared/Common/Anchor";

const mobile_items = [
  {
    id: 1,
    items: [
      {
        id: 5,
        name: "page.common.stories_5",
        src: Story5,
        href: "https://www.nissan-global.com/EN/STORIES/RELEASES/sunny-races-ahead/",
        href_jp:
          "https://www.nissan-global.com/JP/STORIES/RELEASES/sunny-races-ahead/",
      },
      {
        id: 6,
        name: "page.common.stories_6",
        src: Story6,
        href: "https://www.nissan-global.com/EN/STORIES/RELEASES/driven-by-connection-designed-with-inspiration/",
        href_jp: "https://www.nissan-global.com/EN/STORIES/RELEASES/driven-by-connection-designed-with-inspiration/",
      }
    ],
  },

  {
    id: 2,
    items: [

      {
        id: 4,
        name: "page.common.stories_4",
        src: Story4,
        href: "https://www.nissan-global.com/EN/STORIES/RELEASES/formula-e-is-more-than-a-motorsport/",
        href_jp:
          "https://www.nissan-global.com/JP/STORIES/RELEASES/formula-e-is-more-than-a-motorsport/",
      },
      {
        id: 1,
        name: "page.common.stories_1",
        src: Story1,
        href: "https://europe.nissanstories.com/en/releases/spring-into-this-season-with-nissans-e-4orce-technology",
        href_jp: "https://europe.nissanstories.com/en/releases/spring-into-this-season-with-nissans-e-4orce-technology",
      }
    ],
  },
  {
    id: 3,
    items: [

      {
        id: 2,
        name: "page.common.stories_2",
        src: Story2,
        href: "https://usa.nissanstories.com/en-US/releases/an-empowering-journey-begins",
        href_jp: "https://usa.nissanstories.com/en-US/releases/an-empowering-journey-begins",
      },
      {
        id: 3,
        name: "page.common.stories_3",
        src: Story3,
        href: "https://global.nissanstories.com/en/releases/nissan-yokohama-lab",
        href_jp:
          "https://global.nissanstories.com/ja-JP/releases/nissan-yokohama-lab",
      },
    ],
  },
];
const StoriesMobile: FC = () => {
  return (
    <Splide
      options={{
        arrows: false,
        pagination: true,
        width: "100%",
        type: "loop",
        perPage: 1,
        perMove: 1,
        rewind: false,
        autoplay: true,
        pauseOnHover: false,
        resetProgress: false,
        gap: "15px",
        interval: 5000,
        classes: {
          pagination: "container custom-pagination",
        },
      }}
    >
      {mobile_items.map((mobile_item, index) => {
        return (
          <SplideSlide key={index}>
            <Row className="p-3">
              {mobile_item.items.map((item, index) => {
                return (
                  <Col lg={4} md={6} xs={12} key={index}>
                    <Anchor
                      href={item.href}
                      href_jp={item.href_jp}
                      external={true}
                    >
                      <div className="card">
                        <SimpleImage src={item.src} className="grid-img" />
                        <h6>
                          <FormattedMessage id={item.name} />
                        </h6>
                      </div>
                    </Anchor>
                  </Col>
                );
              })}
            </Row>
          </SplideSlide>
        );
      })}
    </Splide>
  );
};
export default StoriesMobile;
