/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext } from "react";
import { Accordion } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Context } from "../../../Config/LocalizationWrapper";
import { Anchor } from "../../Shared/Common/Anchor";
import { BASENAME_PAGE, AMERICAS_PAGE, EUROPE_PAGE } from "../../../interfaces/Static/routing_paths";
import { ACTIVE_REGIONS } from "../../../utils/_active_regions";

interface Props {
    onSelect: (e: any) => void;
}

const languages = [{
    id: 1,
    name: 'EN',
    value: 'en'
}, {
    id: 2,
    name: 'JP',
    value: 'ja'
}];

const RegionLanguage: FC<Props> = ({ onSelect }) => {
    const location = useLocation()
    const context = useContext(Context);
    return (
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>Region & Language</Accordion.Header>
                <Accordion.Body>
                    <div className="language-menu">
                        <div className={`lmenu-list active ${location.pathname === '/americas' ? 'd-none' : ''}`}>
                            <span>
                                <a href="#">Global</a>
                            </span>
                            {
                                languages.map(language => {
                                    return (
                                        <a
                                            key={language.id}
                                            href="#"
                                            onClick={() => onSelect(language.value)}
                                            className={`${context && context.locale === language.value
                                                ? "active"
                                                : ""
                                                }`}
                                        >
                                            {language.name}
                                        </a>
                                    )
                                })
                            }

                        </div>
                        <div className="lmenu-list">
                            <div style={{ marginTop: '1rem' }}>
                                {
                                    ACTIVE_REGIONS.map(region => {
                                        return (
                                            <p key={region.id}>
                                                <Anchor href={region.value} external={true}>
                                                    {region.name}
                                                </Anchor>
                                            </p>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};
export default RegionLanguage;
