import early_careers from "../../../../assets/images/middle_east/early_careers.webp";
import early_careers_banner from "../../../../assets/images/middle_east/early_careers_banner.webp";
import { MIDDLE_EAST_PAGE, WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";


import { MIDDLE_EAST_PROGRAMS } from "./_programs";

const UAE_LOCATIONS = {
    id: 1,
    name: 'United Arab Emirates',
    slug: 'uae',
    items: MIDDLE_EAST_PROGRAMS.filter(x => ["dubai_nmef"].indexOf(x.slug) >= 0)
}
const SAUDI_ARABIA_LOCATIONS = {
    id: 1,
    name: 'Saudi Arabia',
    slug: 'saudi_arabia',
    items: MIDDLE_EAST_PROGRAMS.filter(x => ["jeddah_nksa"].indexOf(x.slug) >= 0)
}

export const MIDDLE_EAST_EARLY_CAREERS = {
    id: 'early-careers',
    card_src: early_careers,
    card_title: "page.discover.early_careers",
    card_description: "page.discover.early_careers_description",
    banner_src: early_careers_banner,
    banner_title: "page.discover.early_careers",
    banner_description: "Embark on a Thrilling Journey",
    slug: 'early-careers',
    href: `${MIDDLE_EAST_PAGE}/early-careers`,
    name: "page.discover.early_careers",
    description: "Play a vital role across the organization in all areas of the company. Connect and inspire new colleagues, leverage your experience, strategic thinking and expand your areas of expertise.",

    categories: [{
        id: 1,
        name: 'Graduate Program',
        slug: 'graduate_program',
        description: "At Nissan, we have a long-established graduate scheme across our Africa Middle East India Europe & Oceania (AMIEO) region. During our 18 months or 2 year program, we will provide the breadth and depth of knowledge you need to thrive and develop into a true professional in your area of expertise in one of the world’s leading automotive brands. In our scheme, you get real responsibility from the start with years of dedicated professional training and support to set you up for success. <br /><br />  Graduate life is invigorating and exciting at Nissan. Each location has its own character and routine, driven by the shared goal of ongoing innovation and improvement. You will find yourself part of a team working together to shape the future of automotive mobility. <br /> <br /> Nissan will help develop your leadership, teamwork, assertiveness, presentation, financial and problem-solving skills to enable you to become a leader of the future.",
        locations: [
            UAE_LOCATIONS
        ]
    }, {
        id: 2,
        name: 'Trainee',
        slug: '',
        description: "As a Trainee you have an Ongoing University or College Degree. Nissan is providing opportunity for you to continuously learn and develop your professional knowledge in a real work and international, multicultural environment.",
        locations: [
            UAE_LOCATIONS
        ]
    }, {
        id: 3,
        name: 'Internship',
        slug: '',
        description: "Some locations at Nissan offer a 3-12 months Internship program where you will work on real projects that could range from Marketing Communication to Dealer Management to Corporate Planning.",
        locations: [
            UAE_LOCATIONS,
            SAUDI_ARABIA_LOCATIONS
        ]
    }, {
        id: 4,
        name: 'National talent',
        slug: '',
        description: "NMEF & NKSA offer a 6-12 months Internship program where you will work on real projects that could range from Marketing Communication to Dealer Management to Corporate Planning.",
        locations: [
            UAE_LOCATIONS,
            SAUDI_ARABIA_LOCATIONS
        ]
    }],
    work_day_link: `${WORKDAY_EN_PATH}locationCountry=50423b5190ad49bb89e94cd58dfaad69&locationCountry=7b4fa1f369bd4604ba3692682fcbe345`
}