import { FC, useState } from "react";
import { LocationProps } from "../../../interfaces/RegionalPage";
import PaneArea from "../../Shared/Common/PillsTab/_pane_area";


interface Props {
    locations: LocationProps[]
}

const CategoryItems: FC<Props> = ({ locations }) => {

    return (
        <>
            {
                locations.length > 0 && locations.map((location) => {
                    return (
                        <PaneArea key={location.id} location={location} />
                    )
                })
            }
        </>
    );
};
export default CategoryItems;
