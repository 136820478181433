import { FC } from "react";
import { NavLink } from "react-router-dom";
import { useIntl } from "react-intl";

export const Anchor: FC<{
  href: string;
  href_jp?: string;
  children: React.ReactNode;
  external?: boolean | false;
}> = ({ href, href_jp, children, external }) => {
  const intl = useIntl();
  const { locale } = intl;
  const hyperlink = (locale === "ja" && href_jp) ? href_jp : href;
  return external ? (
    <a href={hyperlink} aria-label={hyperlink} target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : (
    <NavLink
      to={href}
      className={({ isActive }) => (isActive ? "active" : "inactive")}
    >
      {children}
    </NavLink>
  );
};
