import programs_early_careers from "../../../../assets/images/africa/programs_early_careers.webp";
import programs_early_careers_banner from "../../../../assets/images/africa/programs_early_careers_banner.webp";
import { WORKDAY_EN_PATH } from "../../../../interfaces/Static/routing_paths";
import { AFRICA_PROGRAMS } from "./_programs";

const South_AFRICA_LOCATIONS = {
    id: 1,
    name: 'South Africa',
    slug: 'south-africa',
    items: AFRICA_PROGRAMS.filter(x => ["rosslyn"].indexOf(x.slug) >= 0)
}

const EGYPT_LOCATIONS = {
    id: 1,
    name: 'Egypt',
    slug: 'egypt',
    items: AFRICA_PROGRAMS.filter(x => ["giza"].indexOf(x.slug) >= 0)
}

export const AFRICA_EARLY_CAREERS_PAGES = {
    id: 'early_careers',
    card_src: programs_early_careers,
    card_title: "page.discover.early_careers",
    card_description: "page.discover.africa.early_careers_description",
    banner_src: programs_early_careers_banner,
    banner_title: "page.discover.early_career_program",
    banner_description: "Embark on Thrilling Journey",
    slug: 'early_careers',
    href: '/africa/early_careers',
    name: "page.discover.early_careers",
    description: "Immerse yourself in the world of mobility. Expand the boundaries of possibility and chart your own path at Nissan through our internship opportunities, graduate program, campus connection events, and entry level positions.",
    categories: [{
        id: 1,
        name: 'Graduate Program',
        slug: '',
        description: "At Nissan we have a long-established graduate scheme across our Africa Middle East India Europe & Oceania (AMIEO) region. During our 2-year program we will provide the breadth and depth of knowledge you need to thrive and develop into a true professional in your area of expertise in one of the world’s leading automotive brands. In our scheme you get real responsibility from the start with years of dedicated professional training and support to set you up for success. Graduate life is invigorating and exciting at Nissan. <br /> <br /> Each location has its own character and routine, driven by the shared goal of ongoing innovation and improvement. You will find yourself part of a team working together to shape the future of automotive mobility. Nissan will help develop your leadership, teamwork, assertiveness, presentation, financial and problem-solving skills to enable you to become a leader of the future.",
        locations: [
            South_AFRICA_LOCATIONS,
            EGYPT_LOCATIONS
        ]
    }, {
        id: 2,
        name: 'Summer Internships',
        slug: '',
        description: "Are you looking for a challenging and rewarding opportunity to gain hands-on experience in the automotive industry? Do you want to work with a global leader in innovation and sustainability? If yes, then Nissan’s internship program is for you! <br /> Nissan’s internship program is designed to create a pipeline of talented and diverse candidates for our future workforce. As an intern, you will have the chance to work on real projects that contribute to our business goals and make an impact on the world. You will also learn from our experienced mentors, network with our senior leaders, and participate in various training and development activities. <br /> By joining Nissan’s internship program, you will not only gain valuable skills and knowledge, but also increase your loyalty and commitment to our company. You will become part of our Nissan family and culture and enjoy the benefits of working in a dynamic and supportive environment. <br /> If you are ready to take your career to the next level, apply now for Nissan’s internship program and discover the exciting opportunities that await you!",
        locations: [
            EGYPT_LOCATIONS
        ]
    }],
    work_day_link: `${WORKDAY_EN_PATH}locationCountry=d865e83093ad42319653b08e61f7db49&locationCountry=4db3c26940b944919b83612cc1193eed`
}