import { FC, useEffect, useState } from "react";
import parse from "html-react-parser";
import { Container } from 'react-bootstrap';
import { Tabs, Tab, TabScreen } from 'react-tabs-scrollable'

import { AFRICA_PAGE } from "../../../interfaces/Static/routing_paths";
import Heading from "../../Shared/Common/Heading";
import { Anchor } from "../../Shared/Common/Anchor";
import SimpleImage from "../../Shared/Media/SimpleImage";
import PillsTab from "../../Shared/Common/PillsTab";
import ExploreOtherArea from "../../Shared/RegionalPages/ExploreOtherArea";

import TimetoApply from "../../Shared/RegionalPages/TimetoApply";
import CategoryItems from "./_category_items";
import { AFRICA_KEY } from "../../../utils/_active_regions";
import { AFRICA_SUB_PAGE } from "../../../Config/Static/ProgramDetail/Africa";
import { FormattedMessage } from "react-intl";
interface Props {
    pageId: string;
}

const AfricaSubPage: FC<Props> = ({ pageId }) => {
    const pageData = AFRICA_SUB_PAGE.find(x => x.slug === pageId);
    const [key, setKey] = useState<any>(0);

    const onTabClick = (e: any, index: any) => {
        setKey(index)
    }
    useEffect(() => {
        setKey(0);
    }, [pageId]);

    if (!pageData)
        return null;

    return (
        <>
            <section className="banner americas inner">
                <SimpleImage src={pageData.banner_src} />
                <div className="container">
                    <div className="banner-caption">
                        <h1><FormattedMessage id={pageData.banner_title} /></h1>
                        <div className="sub-text">{pageData.banner_description}</div>
                    </div>
                </div>
            </section>
            <Container className="mb-3 pb-3">
                <div className="breadcrumb-container">
                    <Anchor href={AFRICA_PAGE}>
                        Home
                    </Anchor>
                    <Anchor href="#">
                        Programs: <FormattedMessage id={pageData.name} />
                    </Anchor>
                </div>
                <div className="research-area">
                    <Heading type="section-heading"><FormattedMessage id={pageData.name} /></Heading>
                    <p>{pageData.description}</p>
                </div>
                <div className="department-area">
                    {
                        pageData.categories && pageData.categories.length > 0 && (

                            <>
                                <Tabs activeTab={key} onTabClick={onTabClick} tabsContainerClassName="categories-tab">
                                    {
                                        pageData.categories.map((category, index) => {
                                            return <Tab key={index}>{category.name}</Tab>
                                        })
                                    }
                                </Tabs>
                                {
                                    pageData.categories.map((category, index) => {
                                        return (
                                            <TabScreen
                                                key={category.id}
                                                activeTab={key}
                                                index={index}
                                                className='tab-content'
                                            >
                                                <p>{parse(category.description)}</p>
                                                {
                                                    category.locations?.length > 0 && (
                                                        category.disableTab ? <CategoryItems locations={category.locations} /> : <PillsTab activeKey={pageId} slug={category.slug} locations={category.locations} />
                                                    )
                                                }
                                            </TabScreen>
                                        )
                                    })
                                }
                            </>

                        )
                    }

                    {
                        pageData.locations && pageData.locations.length > 0 && (
                            <PillsTab activeKey={pageId} slug={pageData.slug} locations={pageData.locations} />
                        )
                    }
                </div>
                <ExploreOtherArea pageId={pageId} region={AFRICA_KEY} />
            </Container>
            <TimetoApply url={pageData.work_day_link} />
        </>
    );
};
export default AfricaSubPage;
