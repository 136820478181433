import Africa from "../Components/Africa";
import { Helmet } from "react-helmet-async";
import { AFRICA_SUB_PAGE } from "../Config/Static/ProgramDetail/Africa";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AfricaSubPage from "../Components/Africa/id";

function AfricaContainer() {
    const navigate = useNavigate();
    const { pageId } = useParams();
    useEffect(() => {
        if (pageId) {
            const isPresent = AFRICA_SUB_PAGE.find(x => x.slug === pageId);
            if (!isPresent)
                navigate("/404-found")
        }
    }, [pageId, navigate])
    return (
        <>
            <Helmet>
                <title>
                    NISSAN MOTOR CORPORATION - GLOBAL CAREER WEBSITE - AFRICA
                </title>
            </Helmet>
            <main className="americas-container africas-container">
                {!pageId ? <Africa /> : <AfricaSubPage pageId={pageId} />}
            </main>
        </>
    );
}

export default AfricaContainer;
